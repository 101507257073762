<!-- no result  -->
<div class="container">
    <div class="no-result-section page-not-found">

        <h1 class="page-title">404</h1>
        <h2>OOPS! PAGE NOT FOUND</h2>
        <p class="text-center">This page doesn’t exist or was removed.

            <br />br> We suggest you back to home
        </p>
        <button class="btn btn-primary" type="button" routerLink="/">
            Return Home
        </button>
    </div>
</div>