<section>
    <div class="container">
        <div class="main-container add-info-padding">
            <div class="add-member-wrapper add-member-purchase">
                <div class="header-img-infinity">
                    <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity" />
                </div>
                <div class="footer-img-heart">
                    <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart" />
                </div>
                <div class="guestbook-msg-section">
                    <div class="guestbook-msg-switch">
                        <div class="guestbook-msg-subtitle">
                            <div class="guestbook-title">Guest Book</div>
                            <div class="guestbook-subtitle pt-4">Sign Guest Book for <span (click)="location.back()"><u
                                        style="cursor: pointer;">{{guestUser.name | titlecase}}</u></span></div>
                        </div>
                        <div class="guestbook-switch">
                            <div class="switch-label">Receive broadcast messages</div>
                            <div class="switch-main">
                                <label class="switch">
                                    <input type="checkbox" [checked]="broadCastStatus === 1 ? true : false"
                                        (change)="onChangeBoardcast($event)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="guestbook-add-sec">
                        <div class="guestbook-add-title">Condolence</div>
                        <div class="guestbook-add-msg"><a (click)="form.reset();openAddModal(addGuestMessage)">Add
                                Message</a></div>
                    </div>
                    <div class="guestbook-textarea-sec" *ngFor="let message of messageList">
                        <div class="textarea-msg">{{message.message}}</div>
                        <div class="guestbook-date-sec">
                            <div class="guestbook-date">{{message.updated_at | date}}</div>
                            <div class="guestbook-logo">
                                <a *ngIf="message.status===0" class="waiting-for-approval">Waiting For Approval</a>
                                <a (click)="openEditModal(addGuestMessage,message)"><img
                                        src="assets/images/icon-edit-color.svg" alt="edit" /></a>
                                <a (click)="confirmationPopup(message,'deleteMessage')"><img
                                        src="assets/images/icon-del.svg" alt="delete" /></a>
                            </div>
                        </div>
                    </div>
                    <!-- no result  -->
                    <div class="guestbook-video-wrapper">
                        <div class="video-wrapper">
                            <div class="guestbook-video-title-sec">
                                <div class="title">Memories</div>
                            </div>
                            <div class="subtitle">You may only upload a maximum of {{uploadLimit?.image_upload_allowed}}
                                photos <span *ngIf="uploadLimit?.video_upload_allowed!==0">and
                                    {{uploadLimit?.video_upload_allowed}} videos</span>.</div>
                        </div>
                        <div class="add-img-count">{{pictureCount}}/{{uploadLimit?.image_upload_allowed}} pictures added
                            <span class="picture-size-add">(jpeg, png, and jpg only - upto 10 mb)</span></div>
                        <div class="add-pic-sec-wrap">
                            <div class="add-pic-sec-wrap-row">
                                <div class="add-pic-sec-wrap-col"
                                    *ngIf="pictureCount < uploadLimit?.image_upload_allowed">
                                    <div class="add-pic-sec">
                                        <div class="add-pic-section add-pic-none">
                                            <img class="upload-img" src="assets/images/add-picture-logo.svg"
                                                alt="add-pic" />
                                            <div class="add-pic-title">Add Picture</div>
                                            <input type="file" id="logoUpload" class="custom-input-file"
                                                accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                                (change)="fileSelected($event,'image')" />
                                        </div>
                                    </div>
                                </div>
                                <div class="add-pic-sec-wrap-col" *ngFor="let picture of pictureArr;let i=index">
                                    <div class="add-pic-sec">
                                        <div class="add-pic-section border-solid">
                                            <img [src]="picture?.path"
                                                [alt]="picture?.path ===''?'eternallyme image':''" class="add-main-pic"
                                                *ngIf="picture?.path"
                                                (click)="staticModal.show();previewImage=picture?.path" />
                                            <div *ngIf="!picture?.path">
                                                <div *ngIf="!picture?.path" class="add-pic-title">Add Picture</div>
                                                <input type="file" id="logoUpload" class="custom-input-file"
                                                    accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                                    (change)="fileSelected($event,'image')" />
                                            </div>
                                        </div>
                                        <div class="add-pic-del" *ngIf="picture?.path!==''"
                                            (click)="confirmationMediaPopup(picture,0,i)">
                                            <img src="assets/images/icon-del-col.svg" alt="" />
                                        </div>
                                        <div class="add-pic-date">
                                            <div class="date">{{picture.updated_at | date}}</div>
                                            <div class="waiting-for-approval" *ngIf="picture?.status===0">Waiting For
                                                Approval</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="add-img-count mt-3">{{videoCount}}/{{uploadLimit?.video_upload_allowed}} videos
                            added <span class="picture-size-add">(MP4, WebM, MOV & AVI only - upto 20 mb)</span></div>
                        <div class="add-pic-sec-wrap">
                            <div class="add-pic-sec-wrap-row">
                                <div class="add-pic-sec-wrap-col"
                                    *ngIf="videoCount < uploadLimit?.video_upload_allowed">
                                    <div class="add-pic-sec">
                                        <div class="add-pic-section add-pic-none">
                                            <img class="upload-img" src="assets/images/icon-video.svg" alt="add-pic" />
                                            <div class="add-pic-title">Add Video</div>
                                            <input class="custom-input-file" type="file" id="formFile"
                                                placeholder="Add Video" (change)="fileSelected($event,'video')">
                                        </div>
                                    </div>
                                </div>
                                <div class="add-pic-sec-wrap-col" *ngFor="let video of videosArr;let i=index">
                                    <div class="add-pic-sec mb-1">
                                        <div class="add-pic-section border-solid" (click)="showDoc(video?.path)">
                                            <video (click)="showVideo(video?.path)" [src]="video.path + '#t=0.001'"
                                                height="100px" width="100px" *ngIf="video!==''"
                                                class="upload-video view-all-memory-img">
                                                <track label="English" kind="captions" srclang="en" src="" default>
                                            </video>
                                            <div *ngIf="video!==''" class="video-icon-sm">
                                                <img src="./../../../assets/images/video-img.svg"
                                                    alt="eternallyme video image" class="video-icon-img" />
                                            </div>
                                            <div *ngIf="!video.path">
                                                <img class="upload-img" src="assets/images/icon-video.svg"
                                                    alt="add-pic" />
                                                <div class="add-pic-title">Add Video</div>
                                                <input class="custom-input-file" type="file" id="formFile"
                                                    placeholder="Add Video" (change)="fileSelected($event,'video')">
                                            </div>
                                        </div>
                                        <div class="add-pic-del" *ngIf="video!==''">
                                            <img src="assets/images/icon-del-col.svg" alt=""
                                                (click)="confirmationMediaPopup(video,1,0)" />
                                        </div>
                                        <div class="add-pic-date">
                                            <div class="date">{{video.updated_at | date}}</div>
                                            <div class="waiting-for-approval" *ngIf="video?.status===0">Waiting For
                                                Approval</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Start delete message Modal -->
<ng-template #deleteMsgModal>
    <div class="modal-header">
        <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" (click)="modalRef.hide()" />
    </div>
    <div class="modal-body padding-33">
        <div class="modal-title">Delete Message?</div>
        <div class="modal-subtitle">Are you sure you want to delete this message?</div>
        <button type="submit" (click)="deleteMessage()" class="btn btn-primary-btn fill-button">Yes, Delete</button>
        <div>
            <a (click)="modalRef.hide()" class="cancel-modal-btn">Cancel</a>
        </div>
    </div>
</ng-template>


<!-- End delete message Modal -->

<!-- Start add message Modal -->
<ng-template #addGuestMessage>
    <div class="modal-header">
        <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid"
            (click)="form.reset();modalRef.hide()" />
    </div>
    <div class="modal-body">
        <div class="modal-titles modal-titles-left">{{editMessage ? 'Edit Message' : 'Add Message'}}</div>
        <div class="modal-subtitles modal-titles-left">Please add your message to sign Guest Book.</div>
        <form [formGroup]="form">
            <div class="textarea-message">
                <div class="form-floating form-floating-add pw-input-40"
                    [ngClass]="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched)?'invalid-input':''">
                    <textarea class="form-control" placeholder="Message" formControlName="message"
                        id="floatingTextarea"></textarea>
                    <label for="floatingTextarea">Message</label>
                    <div class="invalid-input-text-msg"
                        *ngIf="form.get('message').invalid && (form.get('message').dirty || form.get('message').touched)">
                        <div *ngIf="form.get('message').errors.required">Message is required</div>
                    </div>
                </div>
                <div *ngIf="!editMessage" class="add-msg-submit-msg">Your message will be submitted & will be displayed
                    on the memorial page once the owner approves your message.</div>
                <div *ngIf="editMessage" class="add-msg-submit-msg">
                    Your message will be re-submitted & will be shown on the memorial page once the owner approves your
                    message</div>
            </div>
            <button type="submit" [disabled]="form.invalid" [disabled]="form.invalid" (click)="submitMessage()"
                class="btn btn-primary-btn fill-button">{{editMessage ? 'Update' : 'Submit'}}</button>
        </form>
    </div>
</ng-template>

<!-- End add message Modal -->
<div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-sm image-preview-modal-dialog">
        <div class="modal-content image-preview-modal-content">
            <div class="modal-body">
                <div class="image-preview-wrapper">
                    <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                </div>
                <button type="button" class="btn btn-link image-preview-close-btn" (click)="staticModal.hide()"><img
                        src="assets/images/image-preview-close.svg" alt="close-btn" class="img-fluid" /></button>
            </div>
        </div>
    </div>
</div>

<!-- Start QR Memory Code Delivery Modal -->

<div class="modal fade" id="qrCodeDelivery" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog signin-modal">
        <div class="modal-content">
            <div class="signin-modal-content change-password-content">
                <div class="modal-header signin-modal-header">
                    <button type="button" class="btn-close ms-auto" aria-label="Close"></button>
                </div>
                <div class="modal-body signin-body change-password-body having-issues-body">
                    <div class="signin-section">
                        <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                                alt="header-infinity" class="modal-header-img" /></span>
                        <div class="having-issues-title mb-20">QR Memory Code Delivery</div>
                        <div class="signin-container">
                            <form class="login-container change-password-container">
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" type="radio" name="delivery"
                                                formControlName="delivery" id="codenow" value="codenow">
                                            <label class="form-check-label" for="codenow">Deliver QR Memory Code
                                                Now</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">Your physical QR Memory code will be delivered within 2
                                    days</div>
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" type="radio" name="delivery"
                                                formControlName="delivery" id="codelater" value="codelater">
                                            <label class="form-check-label" for="codelater">Deliver QR Memory Code
                                                Later</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">You'll get the QR Memory code and be able to create the
                                    memorial right away.
                                    The Physical QR Memory code will be delivered later after the manufacturing.</div>
                                <div class="delivery-code-sec">
                                    <div class="check-code-sec">
                                        <div class="radio-box-sec">
                                            <input class="form-check-input" type="radio" name="delivery"
                                                formControlName="delivery" id="codelater" value="codelater">
                                            <label class="form-check-label" for="codelater">Already Have The QR Memory
                                                Code</label>
                                        </div>
                                        <div class="input-memory-sec">
                                            <div class="form-floating margin-btm-0">
                                                <input type="text" class="form-control" id="name" placeholder="Name"
                                                    name="name" maxlength="30" autocomplete="off" />
                                                <label for="name">Enter QR Memory Code</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="delivery-code-text">Enter the QR Memory code if you already have one &
                                    create the memorial page.</div>
                                <div class="form-action">
                                    <button class="btn btn-primary" type="submit">Continue</button>
                                </div>
                            </form>
                            <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                                    class="modal-footer-img" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- End QR Memory Code Delivery Modal -->

<!-- Start Enter Email Modal -->

<div class="modal fade" id="enterEmailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="guest-signin-modal modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" data-bs-dismiss="modal"
                    aria-label="Close" />
            </div>
            <div class="modal-body">
                <div class="modal-title">Sign Guest Book</div>
                <div class="modal-subtitle">Enter your email address to proceed.</div>
                <form action="">
                    <div>
                        <div class="form-floating guest-signin-input form-floating-add">
                            <input type="email" class="form-control form-floating-margin" formControlName="email"
                                id="floatingInput" placeholder="mikewilliams@gmail.com" maxlength="50">
                            <label for="floatingInput">Email ID</label>
                            <div class="invalid-input-text-message">
                                <div>Email is required</div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary-btn">Next</button>
                </form>

            </div>
        </div>
    </div>
</div>

<!-- End QR Memory Code Delivery Modal -->

<!-- Start Add Personal Detail Modal -->

<div class="modal fade" id="addPersonalDetailModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="guest-signin-modal modal-dialog modal-dialog-centered add-personal-modal">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" data-bs-dismiss="modal"
                    aria-label="Close" />
            </div>
            <div class="modal-body">
                <div class="modal-title">Add Personal Details</div>
                <div class="modal-subtitle">Details are required to post your comment.</div>
                <form action="">
                    <div class="add-member-wrapper add-new-member-wrap add-personal-detail">
                        <div class="row search-results-form">
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control form-floating-margin" formControlName="name"
                                        placeholder="Jacksonville, AL, US" maxlength="50" />
                                    <label for="floatingInput">Full Name</label>
                                    <div class="invalid-input-text-message">
                                        <div>
                                            Full Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input type="text" appNumbersonly
                                        class="form-control form-floating-margin add-member-input contact-num-input"
                                        formControlName="contact_number" minlength="10" maxlength="10"
                                        id="floatingInput" placeholder="Jacksonville, AL, US" />
                                    <label for="floatingInput">Contact Number</label>
                                    <p class="std-code">+1</p>
                                    <div class="invalid-input-text-message">
                                        <div>
                                            Contact Number is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control form-floating-margin" formControlName="email"
                                        placeholder="mikewilliams@gmail.com" />
                                    <label for="floatingInput">Email ID</label>
                                    <div class="invalid-input-text-message">
                                        <div>
                                            Email is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input type="text" class="form-control form-floating-margin"
                                        formControlName="relation" placeholder="Jacksonville, AL, US" id="floatingInput"
                                        maxlength="50" />
                                    <label for="floatingInput">Relation</label>
                                    <div class="invalid-input-text-message">
                                        <div>
                                            Relation is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control form-floating-margin"
                                        formControlName="address" placeholder="Jacksonville, AL, US" maxlength="255" />
                                    <label>Address</label>
                                    <div class="invalid-input-text-message">
                                        <div>
                                            Address is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary-btn">Next</button>
                </form>

            </div>
        </div>
    </div>
</div>

<!-- End Add Personal Detail Modal -->
