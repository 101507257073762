<div class="signin-modal-content change-password-content upgrade-modal-content upgrade-modal-content2">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body signin-body change-password-body upgrade-plan2-body payment-success-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/upgrade-header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="signin-container">
                <div class="current-plan"><span>Current Plan: ${{cancelledSubs?.plan_type == 2 ? 24.99:14.99}}  annually</span></div>
                <ng-container>
                    <div class="row upgrade-plan-row">
                        <div class="col-12 col-md-8">
                            <div class="upgrade-text upgrade-text-mob">
                                <h2>Continue with Current Plan</h2>
                                <p class="upgrade-text-para">Your plan is valid till {{cancelledSubs?.subscription_expire | date}}</p>
                                <div class="plan-size plan-size-text">
                                    <h5 *ngIf="cancelledSubs?.plan_type == 2"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">Unlimited Picture & Video Memories
                                    </h5>
                                    <h5 *ngIf="cancelledSubs?.plan_type == 1"><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">250 Picture & 10 Video Memories
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-primary upgrade-btn" (click)="continuePlan()">Continue</button>
                </ng-container>
                <span class="signin-footer"><img src="assets/images/upgrade-footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>