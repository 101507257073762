<div class="modal-header">
    <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid"  (click)="modalRef.hide()" />
</div>
<div class="modal-body padding-33">
    <div class="modal-title">Downgrade your Plan?</div>
    <div class="modal-subtitle subtitle-width">If you choose to downgrade your plan, your account will be limited to a maximum of 250 picture memories and 10 video memories that you can upload.</div>
    <button type="submit" (click)="confirmDowngrade()" class="btn btn-primary-btn fill-button bg-red-btn">Yes, Downgrade</button>
    <div>
        <a (click)="modalRef.hide()" class="cancel-modal-btn">Cancel</a>
    </div>
</div>