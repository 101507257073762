<section class="co-owner-section itinerary-section">
    <div class="skip-btn-wrap">
        <button (click)="location.back()" class="btn link-btn btn-border cancel-blue">Cancel</button>
    </div>

    <div class="container add-member-container res-p-0">
        <form>
            <div class="main-container">
                <div class="add-member-wrapper wrapper-w-custom">
                    <div class="header-img-infinity">
                        <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity">
                    </div>
                    <div class="header-img-mobile ">
                        <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
                    </div>
                    <div class="footer-img-heart">
                        <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart">
                    </div>
                    <div class="footer-img-mobile ">
                        <img src="./../../../../assets/images/footer-heart-brown.svg" alt="footer heart image">
                    </div>
                    <div class="add-member-header itinerary-header">
                        <div>
                            <h1>Create Itinerary Card</h1>
                        </div>
                        <div class="page-number-text">
                        </div>
                    </div>
                    <div class="itinerary-inner-wrap">
                        <div class="container res-p-16">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="add-member-container">
                                        <div class="main-container main-container-purchase">
                                            <div class="">
                                                <div class="about-memorial-section purchase-memory-section">
                                                    <form [formGroup]="createIteneraryForm" (ngSubmit)="submitDetails()"
                                                        class="add-member-form">
                                                        <div class="add-member-main">
                                                            <div class="row co-owner-row">
                                                                <span class="itinerary-form-title">Final Interment
                                                                    Location for {{memberName}}</span>
                                                                <div class="col-md-12 ">
                                                                    <div class="form-floating add-member-floating">
                                                                        <input type="text"
                                                                            class="form-control add-member-input"
                                                                            id="floatingInput"
                                                                            placeholder="Name of place"
                                                                            (ngModelChange)="updateGuestInvitationText()"
                                                                            formControlName="nameOfPlace">
                                                                        <label for="floatingInput ">Name of
                                                                            place</label>
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="formControls.nameOfPlace.errors?.required && formControls.nameOfPlace.touched">
                                                                        <span class="invalid-input-text">Name of place
                                                                            is required</span>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-floating add-member-floating">
                                                                        <input type="text"
                                                                            formControlName="searchLocation"
                                                                            #searchLocation
                                                                            class="form-control add-member-input"
                                                                            id="floatingInput"
                                                                            (ngModelChange)="updateGuestInvitationText()"
                                                                            placeholder="Search location"
                                                                            (focus)="setupAutocomplete(searchElementRef, 'searchLocation', 'searchLatitude', 'searchLongitude')">
                                                                        <label for="floatingInput">Search
                                                                            Location</label>
                                                                        <img (click)="getCurrentLocation('searchLocation', 'searchLatitude', 'searchLongitude')"
                                                                            src="assets/images/location-logo.svg"
                                                                            alt="location logo" class="input-logo">
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="formControls.searchLocation.errors?.required && formControls.searchLocation.touched">
                                                                        <span class="invalid-input-text">Location is
                                                                            required</span>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
                                                                    <div
                                                                        class="form-floating form-floating-search birth-input">
                                                                        <input type="text" class="form-control"
                                                                            id="ceremonyDate"
                                                                            placeholder="Date of Ceremony"
                                                                            autocomplete="off"
                                                                            aria-label="Date of Ceremony" bsDatepicker
                                                                            readonly
                                                                            [(ngModel)]="searchObj.ceremonyDate"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            name="ceremonyDate"
                                                                            (ngModelChange)="updateGuestInvitationText()"
                                                                            (click)="addClassDatePicker('ceremonyDate bs-datepicker theme-green')"
                                                                            (bsValueChange)="onValueChange($event,'ceremonyDate')"
                                                                            [minDate]="today"
                                                                            [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                                                        <img src="assets/images/calendar-white-icon.svg"
                                                                            alt="location logo" class="input-logo">

                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="formControls.ceremonyDate.errors?.required && formControls.ceremonyDate.touched">
                                                                        <span class="invalid-input-text">Ceremony Date
                                                                            is
                                                                            required</span>
                                                                    </ng-container>

                                                                </div>
                                                                <div class="col-xl-6 col-md-6 col-sm-12 col-12">
                                                                    <div
                                                                        class="form-floating form-floating-search birth-input time-input">
                                                                        <input [ngxTimepicker]="toggleIconCeremony"
                                                                            formControlName="ceremonyTime" readonly
                                                                            placeholder="Ceremony Time">
                                                                        <ngx-material-timepicker
                                                                            [formControl]="ceremonyTimeControl"
                                                                            [showMeridian]="true" [hourStep]="1"
                                                                            [minuteStep]="15" [showSeconds]="false"
                                                                            [readonlyInput]="false" [mousewheel]="true"
                                                                            [arrowkeys]="true"
                                                                            (timeSet)="onTimeChangeCeremony($event)"
                                                                            #toggleIconCeremony>
                                                                        </ngx-material-timepicker>
                                                                        <img src="assets/images/time-logo.svg"
                                                                            alt="location logo" class="input-logo">
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="createIteneraryForm.get('ceremonyTime').errors?.required && createIteneraryForm.get('ceremonyTime').touched">
                                                                        <span class="invalid-input-text">Ceremony Time
                                                                            is required</span>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="guest-example-wrap">
                                                                        <div class="guest-example-header">
                                                                            <span
                                                                                class="itinerary-form-title">Invitation
                                                                                will be sent to the guests. See
                                                                                example:</span>
                                                                            <button (click)="openEditGuestInvitation()"
                                                                                type="button"
                                                                                class="btn btn-link edit-btn">Edit</button>

                                                                        </div>
                                                                        <div class="guest-example">
                                                                            <span>{{invitationText}}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row co-owner-row">
                                                                <span class="itinerary-form-title">Host a Gathering for
                                                                    Celebration of Life (Optional)</span>
                                                                <div class="col-md-12 ">
                                                                    <div class="form-floating add-member-floating">
                                                                        <input type="text"
                                                                            class="form-control add-member-input"
                                                                            id="floatingInput"
                                                                            placeholder="Name of place"
                                                                            (ngModelChange)="updateInvitationText()"
                                                                            formControlName="gatheringPlace">
                                                                        <label for="floatingInput ">Name of
                                                                            place</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-floating add-member-floating">
                                                                        <input type="text"
                                                                            formControlName="gatheringLocation"
                                                                            #gatheringLocation
                                                                            class="form-control add-member-input"
                                                                            id="floatingInput" placeholder="Address"
                                                                            (ngModelChange)="updateInvitationText()"
                                                                            (focus)="setupAutocomplete(addressElementRef, 'gatheringLocation', 'gatheringLatitude', 'gatheringLongitude')">
                                                                        <label for="floatingInput">Address</label>
                                                                        <img (click)="getCurrentLocation('gatheringLocation', 'gatheringLatitude', 'gatheringLongitude')"
                                                                            src="assets/images/location-logo.svg"
                                                                            alt="location logo" class="input-logo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-md-6 col-sm-12 col-12 ">
                                                                    <div
                                                                        class="form-floating form-floating-search birth-input">
                                                                        <input type="text" class="form-control"
                                                                            id="gatheringDate"
                                                                            placeholder="Date of Gathering"
                                                                            autocomplete="off"
                                                                            aria-label="Date of Gathering" bsDatepicker
                                                                            readonly
                                                                            [(ngModel)]="searchObj.gatheringDate"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            name="gatheringDate"
                                                                            (ngModelChange)="updateInvitationText()"
                                                                            (click)="addClassDatePicker('gatheringDate bs-datepicker theme-green')"
                                                                            (bsValueChange)="onValueChange($event,'gatheringDate')"
                                                                            [minDate]="today"
                                                                            [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                                                        <img src="assets/images/calendar-white-icon.svg"
                                                                            alt="location logo" class="input-logo">

                                                                    </div>

                                                                </div>
                                                                <div class="col-xl-6 col-md-6 col-sm-12 col-12">
                                                                    <div
                                                                        class="form-floating form-floating-search birth-input time-input">
                                                                        <input [ngxTimepicker]="toggleIconGathering"
                                                                            formControlName="gatheringTime" readonly
                                                                            placeholder="Gathering Time">
                                                                        <ngx-material-timepicker
                                                                            [formControl]="gatheringTimeControl"
                                                                            [showMeridian]="true" [hourStep]="1"
                                                                            [minuteStep]="15" [showSeconds]="false"
                                                                            [readonlyInput]="false" [mousewheel]="true"
                                                                            [arrowkeys]="true"
                                                                            (timeSet)="onTimeChangeGathering($event)"
                                                                            #toggleIconGathering>
                                                                        </ngx-material-timepicker>
                                                                        <img src="assets/images/time-logo.svg"
                                                                            alt="location logo" class="input-logo">
                                                                    </div>
                                                                    <ng-container
                                                                        *ngIf="createIteneraryForm.get('gatheringTime').errors?.required && createIteneraryForm.get('gatheringTime').touched">
                                                                        <span class="invalid-input-text">Gathering Time
                                                                            is required</span>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="guest-example-wrap">
                                                                        <div class="guest-example-header">
                                                                            <span
                                                                                class="itinerary-form-title">Invitation
                                                                                will be sent to the guests. See
                                                                                example:</span>
                                                                            <button type="button"
                                                                                class="btn btn-link edit-btn"
                                                                                (click)="openEditInvitation()">Edit</button>

                                                                        </div>
                                                                        <div class="guest-example">
                                                                            <span>{{ gatheringInvitationText }}</span>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="member-buttons text-center">
                                                            <button type="submit"
                                                                class="btn btn-primary extra-padding">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</section>
