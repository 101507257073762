import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../interfaces/response';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { LandingPageService } from '../../services/landing-page.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-subscription',
  templateUrl: './no-subscription.component.html',
  styleUrls: ['./no-subscription.component.scss']
})
export class NoSubscriptionComponent implements OnInit {
  userData: any;
  expireDate: any;
  cancelledSubs: any;
  memberId: any;

  constructor(public modalRef: BsModalRef, public toastr: ToastrService,public commonService: CommonService,
     public paymentService: PaymentService,public landingPageService: LandingPageService, public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData')).user;
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
      }
    });
    this.getSubscription();
    if (this.modalRef.content.memberId) {
      this.memberId = this.modalRef.content.memberId;
      // Now you can use this.memberId in your modal functions
    }
  }

  continuePlan(){
      this.paymentService.continueCurrentPlan(this.cancelledSubs?.plan_type, this.memberId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          localStorage.setItem('planType',this.cancelledSubs?.plan_type);
          const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.plan_type = this.cancelledSubs?.plan_type;
          this.commonService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
          this.getSubscription();
          this.modalRef.hide();
          this.toastr.success(res.message);
        }
      });
      
    }

    getSubscription() {
      this.landingPageService.getSubscriptionId(this.memberId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.cancelledSubs = res.data;
          localStorage.setItem('cancelledSubs',JSON.stringify(this.cancelledSubs.status));
          const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.subscription_status = res.data['subscription_status'];
          this.commonService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
        }
      });
    }
}
