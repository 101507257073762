import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { CommonService } from '../../services/common.service';
import { UtilityService } from '../../services/utility.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-export-sales-report',
  templateUrl: './export-sales-report.component.html',
  styleUrls: ['./export-sales-report.component.scss']
})
export class ExportSalesReportComponent implements OnInit {
  exportSales : FormGroup;
  tomorrow = new Date();
  params: any;
  filteredData: any;
  selectedExport='selected';
  id: any;
  memberId;
  constructor(
    public formBuilder: FormBuilder,
    public modalRef: BsModalRef,
    public toaster: ToastrService,
    public commonService : CommonService,
    public utilityService: UtilityService,
    public activateRoute: ActivatedRoute
    ) {
    this.tomorrow.setDate(this.tomorrow.getDate() + MagicNumbers.zero );
    this.exportSales = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      status: [''],
    });
    this.exportSales.get('start_date').valueChanges.subscribe(val => {
      this.exportSales.get('end_date').setValidators(Validators.required);
      this.exportSales.get('end_date').updateValueAndValidity();
    });
    this.commonService.searchData.subscribe((res)=>{
      this.filteredData = res;

    });
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  submitProfile(){
    if (this.exportSales.valid) {
      if(this.exportSales.value.start_date){
        this.params = 
        `&member_id=${this.memberId}&start_date=${this.getCalulateDate(this.exportSales.value.start_date)}&end_date=${this.getCalulateDate(this.exportSales.value.end_date)}`;
      }
      if (this.exportSales.value.status !== 'All') {
        this.params += `&file_type=1&member_id=${this.memberId}`;
      }
      this.utilityService.exportGuestList(this.params);
      this.toaster.success('Data exported successfully.');
      this.modalRef.hide();

    } else {
      this.exportSales.markAllAsTouched();
    }
  }

     /**
     * To download the qr memory code 
    */
     async downloadCode(value) {
      fetch(value)
        .then(response =>
          response.blob()
        )
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.download = value.replace(/^.*[\\\/]/, '');
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }

  getCalulateDate(date){
    const month = date.getMonth() + MagicNumbers.one;
    const formattedMonth = month < MagicNumbers.ten ? `0${month}` : month.toString();
    const day = date.getDate();
    const formattedDay = day < MagicNumbers.ten ? `0${day}` : day.toString();
    const year = date.getFullYear();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  selectStatus(){
    this.selectedExport = '';
  }
}
