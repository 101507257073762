import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-itinerary',
  templateUrl: './confirm-itinerary.component.html',
  styleUrls: ['./confirm-itinerary.component.scss']
})
export class ConfirmItineraryComponent {
  public confirmCallback: () => void;
  public cancelCallback: () => void;
  constructor(public modalRef: BsModalRef) {
    // Initialize the form builder
  }

  

  confirm(): void {
    if (this.confirmCallback) {
      this.confirmCallback();
    }
    this.modalRef.hide();
  }

  cancel(): void {
    if (this.cancelCallback) {
      this.cancelCallback();
    }
    this.modalRef.hide();
  }

  onClose() {
    this.modalRef.hide();
  }
}
