<div class="modal-header">
    <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid"  (click)="modalRef.hide()" />
</div>
<div class="modal-body padding-33">
    <div class="modal-title">Upgrade your Plan?</div>
    <div class="modal-subtitle subtitle-width">By upgrading your plan, you will able to upload unlimited picture and video memories without any restrictions.</div>
    <button type="submit" (click)="confirmUpgrade()" class="btn btn-primary-btn fill-button">Yes, Upgrade</button>
    <div>
        <a (click)="modalRef.hide()" class="cancel-modal-btn">Cancel</a>
    </div>
</div>