<!-- sign in start here -->
<!-- Button trigger modal -->
<!-- Modal -->
<div class="signin-modal-content">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>

    </div>

    <div class="modal-body signin-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title">Create Account</h1>
            <p class="signin-para">Please enter below details to purchase a memorial plan with <strong>EternallyMe</strong></p>
            <div class="signin-container">

                <form class="login-container" [formGroup]="signUpForm" (ngSubmit)="onSignUp()">
                    <div class="form-floating" [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)?'invalid-input':''">
                        <input type="name" class="form-control" id="fullName" placeholder="Full Name" formControlName="fullName" maxlength="50">
                        <label for="fullName">Full Name</label>
                    </div>
                    <ng-container *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched)">
                        <span class="invalid-input-text" *ngIf="fullName?.errors?.required">Full Name is
                            required</span>
                            <span class="invalid-input-text" *ngIf="fullName?.errors?.pattern">Full Name is
                                invalid</span>
                    </ng-container>
                    <div class="form-floating" [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                        <input type="email" class="form-control" id="emailId" formControlName="emailId" placeholder="name@example.com" >
                        <label for="emailId">Email ID</label>
                    </div>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</span>
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.email">Email is invalid</span>
                    </ng-container>
                    <div class="form-floating" [ngClass]="password?.invalid && (password?.dirty || password?.touched)?'invalid-input':''">
                        <input [type]="isShow?'text':'password'" class="form-control " id="password" placeholder="Password" formControlName="password" >
                        <label for="password">Create Password</label>
                        <span class="hide-eye" (click)="isShow=!isShow"><img
                                src="assets/images/{{isShow?'show':'hide'}}-password.svg" alt="hide-password"></span>
                    </div>
                    <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                        <span class="invalid-input-text" *ngIf="password?.errors?.required">Password is required</span>
                        <span class="invalid-input-text" *ngIf="password?.errors?.pattern">Please enter a password not less than 8 characters and not more than 20 characters, including atleast one lowercase letter, one uppercase letter, one special character and one number.</span>
                    </ng-container>

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                            [ngClass]="accept?.invalid && (accept?.dirty || accept?.touched)?'invalid-input':''" checked
                            formControlName="accept" />

                        <label class="form-check-label" for="flexCheckChecked">
                            <p class="create-ac-para">By signing up, I agree to the <a
                                    target="_blank" routerLink="/terms-condition">Terms & Conditions</a>
                                and <a target="_blank" routerLink="/privacy-policy">Privacy Policy</a> of
                                EternallyMe.
                            </p>
                        </label>
                    </div>

                    <span class="invalid-input-text" *ngIf="!accept?.value && isSubmitted">Please accept Terms & Conditions and Privacy Policy</span>
                    <div class="form-action">
                        <button class="btn btn-primary" type="submit">Sign
                            Up</button>
                    </div>
                    <p class="signup-para text-center">Already have an account? <a class="signup-link" (click)="openSignIn()">Sign
                            In</a></p>

                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>