<section>
    <div class="container home-container">
        <div class="row home-page-row">
            <div class="col-12 col-md-6 ">
                <div class="add-loveone">
                    <h1>
                        <span class="profile-img-wrap loveone-profile-wrap">
                            <img [src]="userData.user.profile_picture || 'assets/images/user-img.svg'" alt="profile img"
                                class="profile-img" />
                        </span>
                        Hi {{userData?.user?.name | titlecase}},
                    </h1>
                    <h3>Let’s add your loved one memorial here and share them with your friends and family.</h3>
                    <button *ngIf="showAddLovedOne" class="btn btn-primary btn-add-loveone"
                        routerLink="/add-loved-one">Add A Loved
                        One</button>
                    <div>
                        <button *ngIf="showMemoryCode" class="btn btn-primary btn-add-loveone"
                            (click)="openMemoryCodeRequired()">Enter Memory
                            Code</button>
                    </div>
                    <p>We don’t share data or content with anyone else.</p>
                </div>

            </div>
            <div class="col-12 col-md-6">
                <div class="home-img-group">
                    <img src="assets/images/home-image-group.png" alt="home-image-group" class="home-img img-fluid" />
                    <img src="./../../../../assets/images/guide-arrow-right.svg" alt="guide-arrow-right"
                        class="guide-arrow-right" />
                    <div class="figure-caption">
                        <p class="text-center">create your loved one memorials</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
