<section>
    <div class="container add-member-container res-p-0">
        <form [formGroup]="aboutMemorialForm" (ngSubmit)="onSubmit(action)">
            <div class="main-container">
                <div class="add-member-wrapper wrapper-w-custom">
                    <div class="header-img-infinity">
                        <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity">
                    </div>
                    <div class="header-img-mobile ">
                        <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
                    </div>
                    <div class="footer-img-heart">
                        <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart">
                    </div>
                    <div class="footer-img-mobile ">
                        <img src="./../../../../assets/images/footer-heart-brown2.svg" alt="footer heart image">
                    </div>
                    <div class="page-btn-wrap" #scrollContainer style="overflow-x: auto; white-space: nowrap;">
                        <div [routerLink]="memberId !== undefined ? ['/update-info', memberId] : '/update-info'"
                            class="add-detail active"  #aboutMemorials>
                            <span class="add-detail-number">1</span>
                            <div class="add-detail-text ">Loved One’s Details</div>
                        </div>
                        <div class="add-detail active">
                            <span class="add-detail-number">2</span>
                            <div class="add-detail-text ">About Memorial</div>
                        </div>
                        <div [routerLink]="updateHighlightCareer == false ? null : ['/about-careers-hobbies', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightCareer == true}">
                            <span class="add-detail-number">3</span>
                            <div class="add-detail-text ">Hobbies & Career</div>
                        </div>
                        <div [routerLink]="updateHighlightFamily == false ? null : ['/about-family-memories', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightFamily == true}">
                            <span class="add-detail-number">4</span>
                            <div class="add-detail-text ">About Family</div>
                        </div>
                        <div [routerLink]="updateHighlightMemories == false ? null : ['/add-pictures-videos', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightMemories == true}">
                            <span class="add-detail-number">5</span>
                            <div class="add-detail-text ">Add Memories</div>
                        </div>
                    </div>
                    <div class="add-member-header">
                        <div>
                            <h1>{{updateHighlightMemories?"Edit Memorial details":"Add Memorial details"}}</h1>
                            <p class="add-member-head-width">This information will be the published on the memorial.</p>
                        </div>
                        <div class="page-number-text">
                        </div>
                    </div>
                    <div class="about-memorial-section">
                        <div class="sub-heading">
                            <h5>About memorial</h5>
                            <p>Word Limit: 300 Words</p>
                        </div>
                        <editor [apiKey]="tinyMceKey" #tinymce (onKeyUp)="editorChanges($event)"
                            formControlName="aboutMemorial" id="editor custom-editor" [init]="{
                            height: 500,
                            menubar: false,
                            max_chars: 300,
                            branding:false,
                            statusbar:false,
                            placeholder: 'Type here...',
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                          }"></editor>
                        <p [innerHTML]="aboutMemorialForm.value.aboutMemorial" [hidden]="true" id="memorial"></p>
                        <ng-container
                            *ngIf="(isSubmitted && aboutMemorial?.invalid) && (aboutMemorial?.dirty || aboutMemorial?.touched)">
                            <span class="invalid-input-text mt-0" *ngIf="aboutMemorial?.errors?.required">About Memorial
                                is
                                required</span>

                        </ng-container>
                        <span class="invalid-input-text mt-0" *ngIf="showMaxlength">About Memorial
                            should not be greater than 300 words</span>

                    </div>
                </div>
            </div>
            <div class="member-buttons text-center">
                <button type="button" class="btn btn-outline-primary extra-padding" (click)="goBack()">Back</button>
                <button type="submit" name="saveNext" class="btn btn-primary extra-padding"
                    (click)="action='saveNext'">Save & Next</button>
                <button
                    *ngIf="updateHighlightMemories && aboutMemorialForm.value.aboutMemorial !== 'undefined' && aboutMemorialForm.value.aboutMemorial !== ''"
                    type="submit" name="saveFinish" class="btn btn-primary extra-padding"
                    (click)="action='saveFinish'">Save & Finish</button>
            </div>
        </form>
    </div>
</section>
