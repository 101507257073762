import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../common/services/common.service';
import { MemoryCodeRequiredComponent } from '../../public/component/memory-code-required/memory-code-required.component';
import { LandingPageService } from '../../common/services/landing-page.service';
import { IResponse } from '../../common/interfaces/response';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MemberService } from '../../common/services/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MagicNumbers } from '../../common/component/app-magic-numbers';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit ,OnDestroy {
  public userData;
  public destroy$ = new Subject();
  public className = 'signin-modal';
  showAddLovedOne = false;
  cssClass2 = 'max-width-607 modal-dialog-centered';
  cancelledSubs: any;
  showMemoryCode = false;
  memberList;


  constructor(public commonService: CommonService, public toastr: ToastrService, public router: Router, public memberService: MemberService,
    public landingPageService: LandingPageService, private readonly modalService: BsModalService,
    public modelref: BsModalRef, public loaderService: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getMemberList();
    setTimeout(() => {
      this.checkAddMemberAndLovedOne();
    }, MagicNumbers.fourThousand);
    localStorage.removeItem('addMember');
    const x = JSON.parse(localStorage.getItem('userData')).user?.serial_number;
      if(x){
      } else if(localStorage.getItem('memory_code')){
    } else {
    const memory = JSON.parse(localStorage.getItem('memoryStatus'));
  const userDataInfo = JSON.parse(localStorage.getItem('userData')).user;
  if(!userDataInfo?.stripe_card_id && !userDataInfo?.subscription_status && userDataInfo.account_type == '1'){
    this.router.navigate(['/upgrade-plans']);
  }else{
    this.router.navigate(['/home']);
  }
  }

    // To get the user data
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
      }
    });
  }

  getMemberList() {
    this.loaderService.show();
    this.memberService.memberList().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.memberList = res.data;
        if(this.memberList.length!=0){
          this.router.navigate(['/members-list']);
        } else{
        }
        this.loaderService.hide();
      }
    });
  }

  openMemoryCodeRequired() {
    this.modelref = this.modalService.show(MemoryCodeRequiredComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.className });
    this.modelref.content.onClose.subscribe((result) => {
      if (result.close) {

        this.landingPageService.checkMemoryCode(result.memoryCodeForm).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.checkAddMemberAndLovedOne();
          const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.serial_number = result.memoryCodeForm;
          this.commonService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
          this.showAddLovedOne = true;
          this.toastr.success(res.message);
        }
      });
      }
    });
  }
  checkAddMemberAndLovedOne(){
    this.memberService.checkAddMemberLovedOne(false).subscribe((res: any) => {
      if (res && !res.errors) {
        if(res.data.status == true && res.data.serial_number){
          this.showAddLovedOne = true;
        } else{
          this.showAddLovedOne = false;
        }

        if(res.data.status == true && !res.data.serial_number){
          this.showMemoryCode = true;
        } else{
          this.showMemoryCode = false;
        }
      }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
