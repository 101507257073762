<div class="modal-header">
    <img src="assets/images/icons-close.svg" alt="close-btn" (click)="modalRef.hide()" class="img-fluid" />
</div>
<div class="modal-body">
 <div class="select-modal-sec">
        <div class="modal-titles">Select Guests
        <span *ngIf="totalAllCount">
            ({{totalCount || 0}}/{{totalAllCount}})
        </span> 
    </div>
        <div class="modal-subtitles">Broadcast message will be sent to selected guests.</div>
        <div class="modal-search-select">
            <div class="input-group search-input-group">
                <input type="text" class="form-control form-control-search" [(ngModel)]="searchText" (input)="onInput($event)" placeholder="Search by name" aria-label="Enter name"
                    aria-describedby="basic-addon2" name="search" maxlength="30" />
                <button [disabled]="!searchText" (click)="search()" class="input-group-text search-input-logo no-radius-style" id="basic-addon2"><img
                        src="./assets/images/search-icon.svg" alt="search" /></button>
            </div>
        </div>
        <div class="modal-search-table">
            <div class="table-container table-select-detail">
                <div class="table-head">
                    <div class="table-row">
                        <div class="th">
                            <div class="form-check form-check-qr">
                                <input class="form-check-input" *ngIf="!hideCheckbox" (change)="selectAlls($event);selectAllValue($event)" [checked]="allSelected" type="checkbox" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault"></label>
                            </div>
                        </div>
                        <div class="th">Name
                            <img class="sort-icon-down" (click)="sort(sortStatusName,'name')" src="./assets/images/sort-icon-down.svg" alt="icon-sort" />
                            <img class="sort-icon-up" (click)="sort(sortStatusName,'name')" src="./assets/images/sort-icon-up.svg" alt="icon-sort" />
                        </div>
                        <div class="th">Email
                        </div>
                        <div class="th">Relationship
                            <img class="sort-icon-down" (click)="sort(sortStatusRelation,'relation')" src="./assets/images/sort-icon-down.svg" alt="icon-sort" />
                            <img class="sort-icon-up" (click)="sort(sortStatusRelation,'relation')" src="./assets/images/sort-icon-up.svg" alt="icon-sort" />
                        </div>
                    </div>
                </div>
                <div class="table-body">
                    <div class="table-row" *ngFor="let user of users;let i=index">          <!-- Repeat this sec for add table row -->
                        <div class="td">
                            <div class="form-check form-check-qr">
                                <input class="form-check-input" [(ngModel)]="user.checked" (change)="checkAllSelected(user);checkAll($event)"  type="checkbox" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault"></label>
                            </div>
                        </div>
                        <div class="td" title="{{user.name}}">{{user.name}}</div>
                        <div class="td" title="{{user.email}}">{{user.email}}</div>
                        <div class="td" title="{{user.relation}}">{{user.relation}}</div>
                    </div>

                    <div class="no-result-section w-100" *ngIf="!users?.length">
                        <div class="no-result-img">
                            <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                        </div>
                        <h2>No Results Found</h2>
                       </div>
                </div>
            </div>


        </div>
        <button type="button" class="btn btn-primary-btn fill-button select-btn-done" (click)="submit()">Done</button>
    </div>
</div>