<div class="modal-body body-top-margin">
    <div class="select-modal-sec share-obt">
        <div class="modal-titles">Confirm Submission</div>
        <div class="share-message mb-0">
            <div class="modal-body">
                <p>Please review your itinerary carefully. Once submitted, you will not be able to make any changes.
                    Are you sure you want to submit?</p>
            </div>
        </div>
        <div class="share-add-action">
            <button type="button" class="btn btn-primary btn-primary-update" (click)="confirm()">Yes, Submit</button>
            <button type="button" class="btn btn-no-border" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>
