<!-- forgot password start here -->
<!-- Button trigger modal -->
<!-- Modal -->
<div class="signin-modal-content">
	<div class="modal-body signin-body reset-pw-body">
		<span class="mx-auto header-infinity" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
				alt="header-infinity" /></span>
		<div class="signin-section">
			<h1 class="signin-title">Reset Password</h1>
			<p class="signin-para">Create a new password</p>
			<div class="signin-container">
				<form class="login-container" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
					<div class="form-floating pw-input reset-pw-input"
						[ngClass]="password?.invalid && (password?.dirty || password?.touched)?'error-pw':''">
						<input [type]="isShow?'text':'password'" class="form-control " id="password"
							placeholder="Password" formControlName="password">
						<label for="password">New Password</label>
						<span class="hide-eye" (click)="isShow=!isShow"><img
								src="assets/images/{{isShow?'show':'hide'}}-password.svg" alt="hide-password"></span>
					</div>
					<ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
						<span class="invalid-input-text" *ngIf="password?.errors?.required">New Password is
							required</span>
						<span class="invalid-input-text" *ngIf="password?.errors?.pattern">Please enter a password not less than 8 characters and not more than 20 characters, including atleast one lowercase letter, one uppercase letter, one special character and one number.</span>
					</ng-container>
					<div class="form-floating pw-input confirm-pw"
						[ngClass]="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)?'error-pw':''">
						<input [type]="showConfirm?'text':'password'" class="form-control" id="confirmPassword"
							placeholder="Password" formControlName="confirmPassword">
						<label for="confirmPassword">Confirm New Password</label>
						<span class="hide-eye" (click)="showConfirm=!showConfirm"><img
								src="assets/images/{{showConfirm?'show':'hide'}}-password.svg"
								alt="show-password"></span>
					</div>
					<ng-container
						*ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
						<span class="invalid-input-text" *ngIf="confirmPassword?.errors?.required">Confirm Password is
							required</span>
						<span class="invalid-input-text" *ngIf="password?.errors?.pattern">Please enter a password not less than 8 characters and not more than 20 characters, including atleast one lowercase letter, one uppercase letter, one special character and one number.</span>
					</ng-container>
					<span class="invalid-input-text"
						*ngIf="(confirmPassword?.valid || password?.valid) && confirmPassword?.value !== password?.value">New
						Password and
						Confirm Password must match
					</span>
					<div class="form-action">
						<button class="btn btn-primary" type="submit">Reset Password</button>
					</div>
					<p class="signup-para text-center">Back to <a class="signup-link"
							routerLink="/">Sign In</a></p>

				</form>
				<span class="footer-heart"><img src="assets/images/footer-heart.svg" alt="footer-heart"></span>

			</div>
		</div>
	</div>
</div>