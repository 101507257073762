<section class="result-section home-add-member-section res-pt-0">
    <div class="container">
        <div class="member-header-wrap">
            <div class="member-header-inner-wrap">
                <h2 class="section-title">
                    <span class="profile-img-wrap loveone-profile-wrap">
                        <img [src]="userData.user.profile_picture || 'assets/images/user-img.svg'" alt="profile img"
                            class="profile-img" />
                    </span>
                    Hi {{userData?.user?.name | titlecase}}
                </h2>
                <span class="sub-heading">Welcome to EternallyMe</span>
            </div>
            <button (click)="addNewMember()" class="btn btn-primary add-member-btn-outline">
                <span class="">+Add Member</span>
            </button>

        </div>

        <!-- table start here -->
        <div class="having-issues-sec">
            <div class="table-heading">Added Member</div>
        </div>
        <div class="table-row table-guest-book-sec" *ngFor="let member of memberList">
            <div class="td first-td"></div>
            <div class="td max-width-40">
                <div class="user-title">
                    <div class="user-img">
                        <img (click)="viewMemberInfo(member)" [src]="member?.profile_picture" alt="table-img">
                    </div>
                    <div class="img-caption">
                        <h1 (click)="viewMemberInfo(member)">{{member?.first_name}}
                            <ng-container *ngIf="member.middle_name!==null">
                                {{member?.middle_name}}</ng-container>

                            {{member?.last_name}}
                        </h1>
                        <p><span><img src="assets/images/map-pin.svg" alt="map-pin"></span>{{member?.city?.name}},
                            {{member?.state?.code}}, US</p>
                        <div class="td year-td year-start-flex" style="max-width: 100%;">
                            <h1 class="bg-text-guestbook">{{member.is_dob_private===1
                                ?'Unknown':(member.dob|date:'yyyy')}}-{{(member?.dod|date:'yyyy')}}
                            </h1>
                        </div>

                    </div>
                </div>
            </div>
            <div class="td year-td max-width-60">
                <div class="title-btn-sec">
                    <button class="btn btn-outline-primary add-member-btn" (click)="goToGallery(member.id)"
                        routerLink="/guestbook-gallery">Guest Book
                        <div *ngIf="member?.new_message == true" class="red-circle"></div>
                    </button>
                    <button class="btn btn-outline-primary add-member-btn"
                        (click)="viewMemberInfo(member)">Preview</button>
                    <button class="btn btn-primary add-member-btn-outline"><img src="assets/images/edit-white-icon.svg"
                            alt="edit-icon"><span class="" (click)="updateDetails(member.id)">Edit
                            Details</span></button>

                    <div class="dropdown profile-dropdown home-card-dropdown">
                        <a class="btn dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                            aria-expanded="false">

                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li *ngIf="member.member_subscription.plan_type == 1 && member.manage_payment == true"><a
                                    class="dropdown-item" (click)="openUpgradePlan(member.id);">Subscription Info</a>
                            </li>
                            <li *ngIf="member.member_subscription.plan_type == 2 && member.manage_payment == true"><a
                                    class="dropdown-item" (click)="openDowngradePlan(member.id);">Subscription Info</a>
                            </li>
                            <li><a class="dropdown-item" (click)="openMemoryCode(member.id);">Memory Code Info</a></li>
                            <li (click)="openManageCard(member.id);"><a class="dropdown-item">Manage Credit Card</a>
                            </li>
                            <li (click)="openManageRequests(member.id);"><a class="dropdown-item">Manage Requests</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="export-guest-sec">
                <div class="dropdown profile-dropdown prayer-icon">
                    <a>
                        <span (click)="export(member.id)" class="export-text">Export Guest List</span>
                        <img src="assets/images/export-dropdown.svg" alt="dropdown" class="export-dropdown">
                    </a>
                </div>
            </div>
            <div class="having-qr-code-sec">
                <div class="dropdown profile-dropdown prayer-icon">
                    <a class="having-qr-code-link">
                        <span (click)="openReturnModal(member.id, member.member_subscription.serial_number)"
                            class="export-text">Having Issues With QR Memory
                            Code?</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="add-loveone">
            <button *ngIf="showAddLovedOne" class="btn btn-primary btn-add-loveone" (click)="goToAddLovedOne()">Add A
                Loved
                One</button>
            <div>
                <button *ngIf="showMemoryCode" class="btn btn-primary btn-add-loveone"
                    (click)="openMemoryCodeRequired()">Enter Memory
                    Code</button>
            </div>
        </div>
    </div>
</section>
