import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { AddGuestbookService } from '../../common/services/add-guestbook.service';
import { UtilityService } from '../../common/services/utility.service';
import { IResponse } from '../../common/interfaces/response';

@Component({
  selector: 'app-add-guest-book',
  templateUrl: './add-guest-book.component.html',
  styleUrls: ['./add-guest-book.component.scss'],
})
export class AddGuestBookComponent implements OnInit {
  form: FormGroup;
  memberId: string;
  showCountryCode = false;
  requestedMember: any;
  messageHide = false;
  pictureId: any;
  showCommentInput: any;
  showSignInMember: any;
  public profilePic: string;

  constructor(
    public addGuestbookService: AddGuestbookService,
    private readonly formBuilder: FormBuilder,
    public router: Router,
    public toastr: ToastrService,
    public loader: NgxSpinnerService,
    public activatedRoute: ActivatedRoute,
    public utilityService: UtilityService
  ) {
    this.requestedMember = JSON.parse(localStorage.getItem('memberGuest'));
    this.form = this.formBuilder.group({
      profileImage: [''],
      name: ['', [Validators.required,Validators.maxLength(MagicNumbers.fifty),Validators.pattern(/^[a-zA-Z0-9 ]+$/)]],
      contact_number: ['', [Validators.required, Validators.minLength(MagicNumbers.ten),Validators.pattern(/^[0-9]+$/)]],
      address: ['', [Validators.required,Validators.maxLength(MagicNumbers.twoHundredFiftyFive)]],
      email: [this.requestedMember.email, Validators.required],
      relation: ['',[Validators.required,Validators.pattern(/^(?!\s+$)[a-zA-Z\s]+$/)]],
      message: [''],
      optOut: [0]
    });
    this.memberId = this.requestedMember.member_id;
  }

  ngOnInit(): void {
      // do nothing.
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams.message || queryParams.pictureId) {
          this.messageHide = JSON.parse(queryParams.message);
          this.pictureId = JSON.parse(queryParams.pictureId);
          this.showCommentInput = JSON.parse(queryParams.showSignInGuest);
          this.showSignInMember = JSON.parse(queryParams.showSignInMember);
        }
      }); 

  }

  /**
   * Api call to submit add Guest book
   */
  updateOptOut(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.form.get('optOut')?.setValue(input.checked ? 1 : 0);
  }
  submitForm() {
    this.loader.show();
    const payloads = {
      profile_picture: this.profilePic || '',
      name :this.form.value.name,
      contact_number :this.form.value.contact_number,
      address :this.form.value.address,
      email :this.form.value.email,
      relation :this.form.value.relation,
      message :this.form.value.message,
      member_id : this.memberId,
      optOut: this.form.value.optOut
    };
    this.addGuestbookService
      .submitForm(payloads)
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          if(!this.messageHide){
            this.toastr.success(res.message);
          }
          const memberGuest = {
            member_id: res.data.member_id,
            guestbook_id : res.data.id,
            guestbook_user_picture: res.data.profile_picture,
            guestbook_user_name: res.data.name,
            email : this.requestedMember.email,
            name : this.requestedMember.name
          };
          localStorage.setItem('memberGuest',JSON.stringify(memberGuest));
          localStorage.setItem('memberGuestLike',JSON.stringify(memberGuest));
          sessionStorage.setItem('memberGuestLike',JSON.stringify(memberGuest));
          localStorage.setItem('showCommentInput',JSON.stringify(true));
          
          if(this.messageHide){
          this.router.navigate(['/like-comment'],{
            queryParams:{
              id : this.memberId,
              pictureId : this.pictureId,
              showSignInGuest : this.showCommentInput,
              showSignInMember : this.showSignInMember,
              back : true 
            }
          });
          localStorage.setItem('showCommentInput',JSON.stringify(true));
          }else{
            this.router.navigate(['/add-guest-message']);
          }
        }
      },(error)=>{
        this.loader.hide();
      });
  }

  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success('Profile picture uploaded successfully.');
          this.profilePic = res.data.url;
          this.form.value.profileImage = this.profilePic;
        }
      });
    }
  }
}
