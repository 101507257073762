<!-- Search listing start here -->
<section class="search-listing-section">
    <div class="listing-header">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content advance-modal-content">
                <div class="modal-body text-center">
                    <h1 class="text-center modal-h1">Search your l<img src="../assets/images/heart-fill.svg"
                            alt="heart-fill" />ved one’s memorial</h1>
                    <button type="button" class="btn link-btn btn-border"
                        (click)="isPersonal=true;clearFilter();selectedState=false"
                        [ngClass]="isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">Personal
                        Details</button>
                    <button type="button" class="btn link-btn btn-border memory-code"
                        (click)="isPersonal=false;clearFilter()"
                        [ngClass]="!isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">QR Memory Code
                        Number</button>
                    <div class="modal-form">
                        <div class="container">
                            <form class="search-results-form">
                                <ng-container *ngIf="isPersonal">
                                    <div class="form-floating form-floating-search person-name-input">
                                        <input type="text" class="form-control right-margin-0" id="name"
                                            placeholder="Name" [(ngModel)]="searchObj.name" name="name" maxlength="30"
                                            autocomplete="off" />
                                        <label for="name">Person’s Name</label>
                                    </div>
                                    <div class="form-floating form-floating-search birth-input">
                                        <input type="text" class="form-control" id="birthDate" placeholder="Birth Date"
                                            autocomplete="off" aria-label="Birth Date" bsDatepicker readonly
                                            [(ngModel)]="searchObj.birthDate" name="birthDate"
                                            (click)="addClassDatePicker('birthDate bs-datepicker theme-green')"
                                            (bsValueChange)="onValueChange($event,'dob')" [maxDate]="today"
                                            [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                        <label for="birthDate">Birth Date</label>
                                    </div>
                                    <div class="form-floating form-floating-search death-input">
                                        <input type="text" class="form-control right-margin-0" id="deathDate"
                                            placeholder="Day of Passing" autocomplete="off" aria-label="Day of Passing"
                                            (click)="addClassDatePicker('deathDate bs-datepicker theme-green')"
                                            bsDatepicker readonly [(ngModel)]="searchObj.deathDate" name="deathDate"
                                            (bsValueChange)="onValueChange($event,'dod')" [minDate]="maxDate"
                                            [maxDate]="today"
                                            [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                        <label for="deathDate">Day of Passing</label>
                                    </div>
                                    <div class="form-floating state-input right-margin-0">
                                        <select class="form-select" id="floatingSelect" [(ngModel)]="searchObj.state"
                                            [style.color]="selectedState?'#222222':''" name="state"
                                            aria-label="Floating label select example" (change)="selectedState=true">
                                            <option [value]="state.id" *ngFor="let state of states"
                                                [hidden]="!state?.name">
                                                {{state.name}}

                                        </select>
                                        <label for="floatingSelect" [ngClass]="selectedState?'removeMar':''"
                                            class="state-label">State</label>
                                    </div>
                                </ng-container>

                                <div class="form-floating form-floating-search code-number-floating"
                                    *ngIf="!isPersonal">
                                    <input type="text" class="form-control code-number-input right-margin-0" id="name"
                                        placeholder="Name" [(ngModel)]="searchObj.memoryCodeNumber" maxlength="12"
                                        name="memoryCodeNumber" autocomplete="off" />
                                    <label for="name">QR Memory Code Number</label>
                                </div>
                                <div class="search-popup-input search-btn-input">
                                    <button type="button" class="btn btn-primary btn-padding search-btn"
                                        (click)="searchMemorial()">Search
                                        Memorial</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- Search result section start here -->
<section class="result-section">
    <div class="container">
        <div class="result-header" *ngIf="searchData?.total">
            <div class="row result-header-row">
                <div class="col-6 result-col">
                    <h4>{{searchData?.from}}-{{searchData?.to}} of {{searchData?.total}} Results</h4>
                </div>
                <!-- {{searchArr|json}} -->

                <div class="col-6 sort-btn-col">
                    <div class="sort-section">
                        <span class="sort-span">Sort By:</span>
                        <div class="form-floating sort-dropdown margin-btm-0">
                            <select class="form-select landing-form-select" id="floatingSelect" name="sortBy"
                                aria-label="Floating label select example" [(ngModel)]="sortBy" (change)="sortData()"
                                name="state">
                                <option selected value="atoz">Name A to Z</option>
                                <option value="ztoa">Name Z to A</option>
                                <option value="new">Newest First</option>
                                <option value="old">Oldest First</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 clear-btn-col">
                    <div class="search-reset-btn">
                        <button class="btn btn-outline-primary"
                            *ngFor="let search of searchArr">{{search.name?search.name:search.birthDate?(search.birthDate|date:'MM/dd/yyyy'):search.deathDate?(search.deathDate|date:'MM/dd/yyyy'):search.state?search.state:search.memoryCodeNumber}}
                            <img src="assets/images/search-close.svg" alt="search-close-logo"
                                (click)="removeFilter(search)">
                        </button>
                        <a (click)="clearFilter()" class="search-clear-all">Clear All</a>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-7 col-12" *ngIf="!searchData?.total && !membersList?.length && searchArr.length">
            <div class="search-reset-btn">
                <button class="btn btn-outline-primary"
                    *ngFor="let search of searchArr">{{search.name?search.name:search.birthDate?(search.birthDate|date:'MM/dd/yyyy'):search.deathDate?(search.deathDate|date:'MM/dd/yyyy'):search.state?search.state:search.memoryCodeNumber}}
                    <img src="assets/images/search-close.svg" alt="search-close-logo" (click)="removeFilter(search)">
                </button>

                <a (click)="clearFilter()" class="search-clear-all">Clear All</a>
            </div>
        </div>
        <!-- table start here -->
        <div class="table-row" *ngFor="let member of membersList" (click)="viewMemberInfo(member)">
            <div class="td"></div>
            <div class="td">
                <div class="user-title">
                    <div class="user-img">
                        <img [src]="member.profile_picture?member.profile_picture:'assets/images/table-img.png'"
                            alt="table-img">
                    </div>
                    <div class="img-caption">
                        <h1>{{member?.first_name}}
                            <ng-container *ngIf="member.middle_name!==null ">
                                {{member.middle_name}}</ng-container>

                            {{member?.last_name}}
                        </h1>
                        <p><span><img src="assets/images/map-pin.svg" alt="map-pin"></span> {{member?.city_name}},
                            {{member?.code}}, US</p>
                    </div>
                </div>
            </div>
            <div class="td year-td">
                <h1>{{member.is_dob_private===1 ?'Unknown':(member.dob|date:'yyyy')}}-{{(member?.dod|date:'yyyy')}}
                </h1>
            </div>
        </div>
        <!-- no result  -->
        <div class="no-result-section" *ngIf="!membersList.length">
            <div class="no-result-img">
                <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
            </div>
            <h2>No Results Found</h2>
        </div>

        <div class="pagination-section" *ngIf="membersList && membersList.length">
            <nav aria-label="Page navigation example ms-auto">
                <ul class="pagination" *ngIf="searchData.total>10">
                    <li class="page-item" *ngFor="let page of paginationArr;let isLast=last;let isFirst = first;">
                        <a class="page-link" *ngIf="page==='pre'" aria-label="Previous"
                            (click)="pageChanged(page)">
                            <span aria-hidden="true"><img src="assets/images/pagination-arrow-left.svg"
                                    alt="pagination-arrow"></span>
                        </a>
                        <a class="page-link" *ngIf="page!=='pre' && page!=='next'"
                            [ngClass]="page===currentPage?'pg-text-li':''"
                            (click)="pageChanged(page)">
                            {{page}}
                            <ng-container *ngIf="page===currentPage">
                                of <span class="color-text-pg">{{searchData?.pageCount}}
                                    Pages</span>
                            </ng-container>
                        </a>
                        <a *ngIf="page==='next'" class="page-link" aria-label="Next"
                            (click)="pageChanged(page)">
                            <span aria-hidden="true"><img src="assets/images/pagination-arrow-right.svg"
                                    alt="pagination-arrow"></span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

</section>