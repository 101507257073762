<div class="container add-member-container res-p-0">
    <div class="main-container">
        <div class="add-member-wrapper wrapper-w-custom">
            <div class="header-img-infinity">
                <img src="assets/images/add-pic-infinit-edit.svg" alt="add-pic-infinity">
            </div>
            <div class="header-img-mobile ">
                <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
            </div>
            <div class="footer-img-heart">
                <img src="assets/images/edit-pic-heart.svg" alt="add-pic-heart">
            </div>
            <div class="footer-img-mobile ">
                <img src="./../../../../assets/images/footer-heart-brown.svg" alt="footer heart image">
            </div>
            <!-- modal start here -->
            <div class="share-obituary-modal">
                <div class="modal-header">
                    <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" aria-label="Close"
                        (click)="modalRef.hide()" />
                </div>
                <div class="modal-body">
                    <div class="select-modal-sec share-obt">
                        <form>
                            <div class="modal-titles">Edit memories</div>
                            <div class="modal-subtitles">Update your Picture Memories</div>
                        </form>
                    </div>
                </div>
                <div class="about-memorial-section file-input-section res-pb-0">
                    <div class="add-pic add-pic-flax-justify res-mt-0">
                        <div class="row-heading">{{pictureCount}}/{{uploadLimit?.image_upload_allowed}} pictures added
                            <span class="picture-size-add">(jpeg, png, and jpg only - upto 10 mb)</span>
                        </div>
                    </div>

                    <div class="file-input-wrapper">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div [hidden]="pictureCount>=uploadLimit?.image_upload_allowed"
                                    class="custom-file-input file-bg-white">
                                    <span>Add Picture</span>
                                    <input class="form-control z-index" type="file" id="formFile"
                                        accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                        (change)="fileSelected($event,'image')" />
                                </div>
                            </div>
                            <mat-list (dndDrop)="onDropPicture($event, pictureArr)"
                                class="dndList flex-grow-1 d-flex row" [dndDropzone]="['apple', 'banana' ]"
                                dndEffectAllowed="move">


                                <mat-list-item class="dndPlaceholder bg-danger bg-gradient col-6 col-md-4 col-lg-3"
                                    dndPlaceholderRef>
                                </mat-list-item>
                                <mat-list-item
                                    *ngFor="let picture of pictureArr; let i = index; trackBy: trackByPicture"
                                    [dndDraggable]="picture" (dndStart)="onDragStartPicture(i)"
                                    (dndMoved)="onDraggedPicture(i, picture, pictureArr)"
                                    class=" col-6 col-md-4 col-lg-3" dndEffectAllowed="move">
                                    <div *ngIf="picture?.path !==''" class="custom-file-input file-bg-white"
                                        [ngClass]="picture?.path ? 'removeBgIcon' : ''"
                                        [style.border]="picture?.path!==''?'none':''">
                                        <img [src]="picture?.path" [alt]="picture?.path ===''?'eternallyme image':''"
                                            class="upload-pic"
                                            (click)="staticModal.show();previewImage=picture?.path" />
                                        <span *ngIf="picture?.path===''">Add Picture</span>
                                        <input *ngIf="picture?.path===''" class="form-control z-index" type="file"
                                            id="formFile"
                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG"
                                            (change)="fileSelected($event,'image')" />

                                        <button type="button" class="btn btn-link delete-img-btn"
                                            *ngIf="picture?.path!=='' && pictureArr.length!==1"><img
                                                src="./../../../assets/images/delete-round-new.svg"
                                                (click)="removeMemory=i" alt="delete-img"></button>

                                        <button type="button" class="btn btn-link delete-img-btn drag-img"
                                            *ngIf="picture?.path!=='' && pictureArr.length!==1"><img
                                                src="./../../../assets/images/drag-round-new.svg"
                                                (click)="removeMemory=i" alt="delete-img"></button>


                                        <div class="reomove-modal-wrap" *ngIf="removeMemory===i">
                                            <div class="reomove-modal-sec">
                                                <div class="reomove-title">Remove Memory?</div>
                                                <button type="submit"
                                                    class="btn btn-primary-btn fill-button reomove-btn"
                                                    (click)="deleteImage(i)">Yes, Remove</button>
                                                <div><a class="cancel-modal-btn" (click)="removeMemory=-1">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>

                        <ng-container>
                            <div class="row-test">
                                <p class="row-heading row-top-margin">
                                    {{videoCount}}/{{uploadLimit?.video_upload_allowed}} videos added <span
                                        class="picture-size-add">(MP4, WebM, MOV & AVI only - upto 20 mb)</span></p>
                                <div class="row video-wrapper">
                                    <div [hidden]="videoCount>=uploadLimit?.video_upload_allowed"
                                        class="col-12 col-md-12 col-lg-12 add-picture-col">
                                        <div class="custom-file-input custom-video file-bg-white">
                                            <span>Add Video</span>
                                            <input class="form-control z-index" type="file" id="formFile"
                                                (change)="fileSelected($event,'video')" />
                                        </div>
                                    </div>

                                    <mat-list (dndDrop)="onDropVideo($event, videosArr)"
                                        class="dndList flex-grow-1 d-flex row-test row video-gap"
                                        [dndDropzone]="['apple', 'banana' ]" dndEffectAllowed="move">

                                        <mat-list-item
                                            class="dndPlaceholder col-6 col-md-4 col-lg-3 add-picture-col bg-danger bg-gradient"
                                            dndPlaceholderRef>
                                        </mat-list-item>
                                        <mat-list-item
                                            *ngFor="let video of videosArr; let i = index; trackBy: trackByVideo"
                                            [dndDraggable]="video" (dndStart)="onDragStartVideo(i)"
                                            (dndMoved)="onDraggedVideo(i, video, videosArr)"
                                            class="col-6 col-md-4 col-lg-3" dndEffectAllowed="move">
                                            <div class=" add-picture-col" [hidden]="video===''">
                                                <div class="custom-file-input custom-file-input-enable add-video active"
                                                    *ngIf="video?.path">
                                                    <video (click)="showVideo(video?.path)"
                                                        [src]="video?.path + '#t=0.001'" height="100px" width="100px"
                                                        *ngIf="video?.path!==''"
                                                        class="upload-video view-all-memory-img">
                                                        <track label="English" kind="captions" srclang="en" src=""
                                                            default>
                                                    </video>
                                                    <div class="reomove-modal-wrap" *ngIf="removeMemoryVideo===i">
                                                        <div class="reomove-modal-sec">
                                                            <div class="reomove-title">Remove Memory?</div>
                                                            <button type="submit"
                                                                class="btn btn-primary-btn fill-button reomove-btn"
                                                                (click)="deleteVideo(i)">Yes, Remove</button>
                                                            <div><a class="cancel-modal-btn"
                                                                    (click)="removeMemoryVideo=-1">Cancel</a></div>
                                                        </div>
                                                    </div>
                                                    <button type="button"
                                                        class="btn btn-link delete-img-btn delete-round-img"
                                                        *ngIf="video?.path!==''"><img
                                                            src="./../../../assets/images/delete-round-new.svg"
                                                            (click)="removeMemoryVideo=i" alt="delete-img"></button>
                                                    <button type="button" class="btn btn-link drag-img"
                                                        *ngIf="video?.path!==''"><img
                                                            src="./../../../assets/images/drag-round-new.svg"
                                                            (click)="removeMemoryVideo=i" alt="drag-img"></button>
                                                    <div (click)="showDoc(video?.path)" class="video-icon"
                                                        *ngIf="video?.path!==''"><img
                                                            src="./../../../assets/images/video-img.svg"
                                                            alt="eternallyme video image" class="video-icon-img" />
                                                    </div>
                                                </div>
                                            </div>

                                        </mat-list-item>
                                    </mat-list>

                                </div>
                            </div>
                        </ng-container>
                        <p class="note" *ngIf="userData?.user?.get_plan?.length !==2 && dayCount < 15">Note: Pictures
                            will be editable for {{15-dayCount}} {{dayCount===14?'day':'days'}} after submitting the
                            form
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
            tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
            <div class="modal-dialog modal-sm image-preview-modal-dialog">
                <div class="modal-content image-preview-modal-content">
                    <div class="modal-body">
                        <div class="image-preview-wrapper">
                            <img [src]=" previewImage" alt="picture" class="image-preview img-fluid" />
                        </div>
                        <button type="button" class="btn btn-link image-preview-close-btn"
                            (click)="staticModal.hide()"><img src="assets/images/image-preview-close.svg"
                                alt="close-btn" class="img-fluid" /></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="share-add-action">
            <button type="submit" (click)="onNext()" class="btn btn-primary btn-primary-update">Update</button>
        </div>

    </div>
</div>
