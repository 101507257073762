import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { IResponse } from '../../interfaces/response';
import { ToastrService } from 'ngx-toastr';
import { LandingPageService } from '../../services/landing-page.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss']
})
export class CancelSubscriptionComponent implements OnInit {
  @Input() data: any;
  cancelledSubs: any;
  memberId;

  constructor(public modalRef: BsModalRef, public landingPageService: LandingPageService, public activatedRoute: ActivatedRoute,
  public commonService: CommonService, public toastr: ToastrService, public paymentService: PaymentService) { }

  ngOnInit(): void {

    if (this.modalRef.content.memberId) {
      this.memberId = this.modalRef.content.memberId;
    }

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
      }
    });
  }

  delete(){
  this.commonService.deleteConfirmation.next({status : true,value:this.data});
  }

  cancelSubscription(){
    this.paymentService.cancelSubPlan(this.memberId).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.modalRef.hide();
        this.toastr.success(res.message);
        this.getSubscription(this.memberId);
        window.location.reload();
      }
    });
  }

  getSubscription(id) {
    this.landingPageService.getSubscriptionId(id).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.cancelledSubs = res.data['status'];
        localStorage.setItem('cancelledSubs',JSON.stringify(this.cancelledSubs));
      }
    });
  }
}
