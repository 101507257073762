<div class="signin-modal-content change-password-content upgrade-modal-content upgrade-modal-content2">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body signin-body change-password-body upgrade-plan2-body payment-success-body">
        <div class="signin-section">
            <span class="modal-title mb-3" id="exampleModalLabel"><img src="assets/images/upgrade-header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="signin-container">

                <div class="cancel-subscription-modal-sec">
                    <div class="cancel-subscription-title">You don't have any active plan</div>
                    <div class="cancel-subscription-subtitle">Purchase a plan to re-activate your loved one's memorial page</div>
                    <div class="cancel-subscription-radio">
                        <div class="plan-size-raido-sec">
                            <div class="radio-box-sec">
                                <input class="form-check-input " type="radio"
                                id="inlineRadio3 " name="inlineRadio"
                                (click)="planType=1">
                           <label class="form-check-label " for="inlineRadio3 ">$14.99 Anually</label>
                            </div>
                        </div>
                        <div class="plan-size">
                            <h5><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">250 Picture & 10 Video Memories
                            </h5>
                        </div>
                    </div>
                    <div class="cancel-subscription-radio">
                        <div class="plan-size-raido-sec">
                            <div class="radio-box-sec">
                                <input class="form-check-input " type="radio"
                                id="inlineRadio4 " name="inlineRadio"
                                 (click)="planType=2">
                           <label class="form-check-label " for="inlineRadio4 ">$24.99 Anually</label>
                            </div>
                        </div>
                        <div class="plan-size">
                            <h5><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">Unlimited Picture & Video Memories
                            </h5>
                        </div>
                    </div>
                    <div class="cancel-subscription-btn">
                        <button (click)="continuePlan()" class="btn btn-primary upgrade-btn">Purchase Plan</button>
                        <button (click)="modalRef.hide()" type="button" class="btn btn-link upgrade-link-btn">Cancel</button>
                    </div>
                </div>
                <span class="signin-footer"><img src="assets/images/upgrade-footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>