import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public toastr: ToastrService, public router: Router) {

  }
  /**
   * To protect the private pages
   * 
  */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('userData')) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class CanAuthLoginGuard implements CanActivate {
  constructor(public toastr: ToastrService, public router: Router) {

  }
  /**
   * To protect the public  pages without login
   * 
  */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('userData')) {
      return true;
    } else {
      const userData = (localStorage.getItem('userData'));
      const data = JSON.parse(userData);
      if (data.user.member) {
        this.router.navigate(['/members-list']);
      } else if (!data.user.member) {
        this.router.navigate(['/home']);
      }
      return false;
    }
  }

}
