import { Component, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.scss']

})
export class ScanQrComponent {
  public scannerEnabled = true;
  public memoryCode;
  @ViewChild('scanner', { static: false }) scanner;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];



  /**
   * API Call for scan success handler
   * @param event is required
  */
  public scanSuccessHandler($event) {
    this.scannerEnabled = false;
    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    if (pattern.test($event)) {
      window.open($event, '_self');
    } else {
      this.memoryCode = $event;

    }
  }
}
