import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IResponse } from '../../interfaces/response';
import { AddGuestbookService } from '../../services/add-guestbook.service';
import { CommonService } from '../../services/common.service';
import { MemberService } from '../../services/member.service';
import { SearchService } from '../../services/search.service';
import { UtilityService } from '../../services/utility.service';
import { MagicNumbers } from '../app-magic-numbers';
import { ShareObituaryComponent } from '../../modal/share-obituary/share-obituary.component';
import { AddObituaryComponent } from '../../modal/add-obituary/add-obituary.component';
import { EditObituaryComponent } from '../../modal/edit-obituary/edit-obituary.component';
import { AddPictureVideosMemberComponent } from '../../modal/add-picture-videos-member/add-picture-videos-member.component';

@Component({
  selector: 'app-view-info',
  templateUrl: './view-info.component.html',
  styleUrls: ['./view-info.component.scss'],
})
export class ViewInfoComponent implements OnInit,OnDestroy {
  @ViewChild('signInGuest', { static: false }) signInGuest: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
  public mediaLists : any = [];
  public member;
  public viewInfoUrl: string;
  public viewInfoId: any;
  public searchObj = {};
  public previewImage: string;
  public frameImage;
  public memberList = [];
  public userData;
  public destroy$ = new Subject();
  public isSearch = false;
  public email: '';
  public className = 'signin-modal';
  public classNameObituaryadd = 'add-obituary-modal signin-modal';
  public classNameObituaryEdit = 'edit-obituary-modal signin-modal';
  public classNameEditMemory = 'edit-memory-modal';
  public classNameObituary = 'signin-modal signin-modal-height';
  public modalRef: BsModalRef;
  public guestbookMessageList: any = [];
  public memberGuest: any;
  public showSignInGuest: boolean;
  public memberId: any;
  public form: FormGroup;
  public removeMemory :any;
  public deleteMember:any;
  public deleteMembers:any;
  public removeMessage :any;
  public isLoggedIn = false;
  messageObituary: any;
  showMessage = false;
  showOb = false;
  getPicture: []=[];
  profilePic: string;
  constructor(
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public memberService: MemberService,
    public addGuestbookService: AddGuestbookService,
    public searchService: SearchService,
    public router: Router,
    public modalService: BsModalService,
    public sanitizer: DomSanitizer,
    private readonly fb: FormBuilder,
    public loaderService: NgxSpinnerService,
    public toastr: ToastrService,
    public utilityService: UtilityService
  ) {
    this.viewInfoUrl = '';
    this.previewImage = '';
    this.frameImage = '';

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.viewInfoId = params.id;
        if (params.search) {
          this.isSearch = true;
          this.searchObj = JSON.parse(params.search);
        }
        this.getViewInfo();
        this.getObituaryShare(this.viewInfoId);
      }
    });
   if(JSON.parse(localStorage.getItem('userData'))){
     if(JSON.parse(localStorage.getItem('userData'))?.members[0]?.id === JSON.parse(localStorage.getItem('member_id'))){
      this.showOb = true;
     } else{
      this.showOb = false;
     }
     this.isLoggedIn = true;
   } else{
    this.isLoggedIn = false;
   }
  }

  ngOnInit(): void {
    this.memberGuest = JSON.parse(localStorage.getItem('memberGuest'));
        // To get the user data
    this.commonService.userData
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.userData = res;
        }
      });
      this.form = this.fb.group({
        email: [this.userData?.user ? this.userData.user.email :null, [Validators.required, Validators.email]]
      });
  }

  shareMemberId(id){
    this.memberService.getObituaryId(id).subscribe((res: any) => {
      if (res) {
      }
    });

  }


  openObituary(memberId) {
    const initialState = {
      member: memberId
    };
     this.modalService.show(ShareObituaryComponent, { class: this.classNameObituary, initialState });
    }

  openAddObituary() {
    this.modalRef = this.modalService.show(AddObituaryComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.classNameObituaryadd });
    this.modalRef.content.onClose.subscribe((result) => {
      if (result.close) {
        this.memberService.postObituary(result.form).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success('Obituary added.');
            this.getObituaryShare(this.member.id);

          }
          this.openObituary(this.member);
        });
      }
    });
  }

  openAddPicturesPopup(){
    this.modalRef = this.modalService.show(AddPictureVideosMemberComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.classNameEditMemory });
    this.modalRef.content.onClose.subscribe((result) => {
      if (result.close) {
        this.memberService.postObituary(result.form).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success('Obituary added.');
            this.getObituaryShare(this.member.id);
          }
        });
      }
    });
  }

  openEditObituary() {
    const initialState ={ data: this.messageObituary };
    this.modalRef = this.modalService.show(EditObituaryComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false,initialState, class: this.classNameObituaryEdit });
    this.modalRef.content.onClose.subscribe((result) => {
      if (result.close) {
        if(result.delete !== 1){
        this.memberService.postObituary(result.form).subscribe((res: any) => {
          this.toastr.success('Obituary updated.');
          this.getObituaryShare(this.member.id);
        });
      } else{
        this.memberService.deleteObituary().subscribe((res: any) => {
          this.toastr.success('Obituary deleted.');
          this.getObituaryShare(this.member.id);
        });
      }
      }
    });
  }
  /**
   * API Call for View member information
   */
  getViewInfo() {
    this.member ={};
    this.loaderService.show();
    this.searchService
      .getViewInfo(this.viewInfoId)
      .subscribe((res: any) => {
        if (res && !res.errors) {
          this.member = res.data;
          this.getPicture = res.data.member_gallery.filter(res1=> res1.type===0);
          this.frameImage = this.sanitizer.bypassSecurityTrustStyle(
            'url(' +
              `../../../../assets/images/frame-${this.member?.frame_no}.svg` +
              ')'
          );
        }
        const memberData = {
          member_id : this.member.id
        };
        localStorage.setItem('memberGuest',JSON.stringify(memberData));
        localStorage.setItem('member_id',JSON.stringify(this.member.id));
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.showSignInGuest = this.member.id !== userData?.members[0]?.id;
        this.loaderService.hide();
        this.getMediaList('');
        this.getMessageList();
      },(error)=>{
      });
  }

  /**
   * API Call to get media list
   * type blank string means all picture and video
   */
  getMediaList(Type:any) {
    this.loaderService.show();
    this.mediaLists = [];
    const payloads = {
      member_id: this.member.id,
      type : Type,
      page : 1,
      source:''
    };
    this.memberService.getMediaList(payloads).subscribe((res: any) => {
      if (res.data && !res.errors) {
        this.mediaLists = res?.data?.data?.slice(MagicNumbers.zero,MagicNumbers.six) || [];
        this.removeMemory = -1;
        this.loaderService.hide();
      }
    },(error)=>{
      this.loaderService.show();
    });
    this.getObituaryShare(this.member.id);
  }

  /**
   * To navigate to view all memories
   */
  viewAllMemories() {
    this.router.navigate(
      [
        this.router.url.includes('view-information')
          ? 'view-all-memories'
          : '/view-memories',
      ],
      {
        queryParams: {
          memories: JSON.stringify(
            this.member.member_gallery
          ),
          videos: JSON.stringify(this.member.video),
          url: this.member.url,
          viewUrl : JSON.stringify(this.viewInfoId),
          showSignInGuest : !this.showSignInGuest,
          id: this.member.id,
          showSignInMember : this.showSignInGuest
        },
      }
    );
  }

  /**
   * To navigate to the form when edit
   */
  updateDetails(id) {
    localStorage.setItem('update', 'true');
    localStorage.setItem('addMember', JSON.stringify(this.memberList[0]));
    this.router.navigate(['/update-info/', id]);
  }

  viewItenerary(id) {
    this.router.navigate(['/view-itinerary/', id]);
  }

  createItenerary(id) {
    this.router.navigate(['/create-itinerary/', id]);
  }
  /**
   * To get the member list based on user id
   */
  // getMemberList() {
  //     this.memberService
  //       .memberList()
  //       .subscribe((res: IResponse) => {
  //         if (res && !res.errors) {
  //           this.memberList = [res.data];
  //           this.loaderService.show();
  //           this.getMediaList('');
  //           this.getMessageList();
  //         }
  //       },(error)=>{
  //         this.getMessageList();
  //         this.getMediaList('');
  //       });
  // }

  getObituaryShare(id) {
    this.memberService.getObituary(id).subscribe((res) => {
      this.messageObituary = res['data'];
      if(this.messageObituary){
        this.showMessage = true;
      } else{
        this.showMessage = false;
      }
    });
  }
      /**
     * show Icon red method
    */
  checkPendingReq(memberID){
    this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
      if (res && !res.errors) {
         this.commonService.guestRequestSharing.next(res.data);
      }
      });
  }

  /**
   * To get the message list based on user id
   */
  getMessageList() {
    this.loaderService.show();
    const payloads ={
      member_id: this.member.id,
      page : 1,
      source :''
    };
    this.memberService
    .getMessageList(payloads)
    .subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.guestbookMessageList = res.data.data.slice(MagicNumbers.zero,MagicNumbers.five)  || [];
        this.removeMessage = -1;
        this.loaderService.hide();
      }
    },(error)=>{
    this.loaderService.show();
    });
}
 /**
   * To get the see More  message list based on user id
   */
 seeMoreList(showSection) {
  const gallery ={
    active : showSection,
    locationUrl : `view`
  };
  localStorage.setItem('gallerySection',JSON.stringify(gallery));
  this.router.navigate(['/guestbook-gallery'],
  { queryParams: {
    showSignInGuest : !this.showSignInGuest,
    showSignInMember : this.showSignInGuest
  }
  });
}

  /**
   * To open the vide in new tab
   * @param doc is response of list
   */
  showDoc(doc: string) {
    window.open(doc, '_blank');
  }

  /**
     * To download the qr memory code
    */
  async downloadCode(value) {
    fetch(value)
      .then(response =>
        response.blob()
      )
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = value.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  /**
   * Method used for submit guest and route to signup guestbook
   */
  submitGuest() {
   localStorage.removeItem('memberGuestEmail');
    const payloads = {
      email: this.form.value.email,
      member_id: this.member.id,
      guestbook_id: null
    };

    this.addGuestbookService.signSubmitForm(payloads).subscribe((res: any) => {
      payloads['name'] = this.member?.name;
      payloads['member_id'] = this.member?.id;
      payloads['email'] = this.form.value.email;
      if(res.data!== ''){
        payloads['guestbook_id'] = res.data.id;
        payloads['guestbook_user_picture'] = res.data.profile_picture;
        payloads['guestbook_user_name'] = res.data.name;
      }
      localStorage.setItem('memberGuest', JSON.stringify(payloads));
      localStorage.setItem('memberGuestEmail', this.form.value.email);
      this.commonService.memberGuestEmail.next(this.form.value.email);
      if (res.data !== '') {
        this.modalRef.hide();
        this.router.navigate(['/add-guest-message']);
      } else {
        this.modalRef.hide();
        this.router.navigate(['/add-guestBook']);
      }
    });
  }

  /**
   * Opening the sign in for guestbook
   */
  openModal(signInGuest) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-dialog-centered guest-signin-modal',
    };
    this.modalRef = this.modalService.show(signInGuest, config);
  }

/**
* API Call to update the status
*/
   statusUpdated(value,ID){
     const payloads = {
       id: ID,
       status : value
     };
     this.addGuestbookService.updateGuestbookRequest(payloads).subscribe((res: any) => {
       if (res) {
         this.toastr.success(res.message);
         this.guestbookMessageList = [];
         this.getMessageList();
       }
     });
   }

/**
* API Call to update Member Information
*/
UpdateMember(picture,index){
  const payloads:any = {
    id: picture.id,
  };
    this.member.member_gallery.splice(index,1);
  this.addGuestbookService.updateMemberInformation(payloads).subscribe((res: any) => {
    if (res) {
      this.toastr.success(res.message);
      this.guestbookMessageList = [];
      this.getViewInfo();
      this.deleteMember = -1;
      this.deleteMembers=-1;
    }
  });
}

/**
* API Call to delete the status
*/
   deleteMedia(value){
  const payloads = {
    id: value.id,
    guestbook_id:value.guestbook_id
  };
  this.addGuestbookService.deleteMediaPicture(payloads).subscribe((res: any) => {
    if (res) {
      this.toastr.success(res.message);
      this.mediaLists = [];
      this.getMediaList('');
    }
  });
}

  ngOnDestroy() {
    if (this.isSearch) {
      this.commonService.searchData.next(this.searchObj);
    }
  }

  /**
   * Name Message Uppercase
  */
  toTitleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
        return word.charAt(0).toLocaleUpperCase() + word.slice(1);
    }).join(' ');
  }

  goLikeComment(picture:any) {
    this.router.navigate(['/like-comment'],
      {
        queryParams: {
          id: this.member.id,
          pictureId : picture.id,
          showSignInGuest : !this.showSignInGuest,
          showSignInMember : this.showSignInGuest,
        },
      });
  }
  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }
  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.loaderService.show();
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.profilePic = res.data.url;
          this.utilityService.updateMemberImage(this.member.id, this.profilePic).subscribe((res1: IResponse) => {
            if (res1 && !res1.errors) {
              this.toastr.success('Profile image updated successfully');
              this.getViewInfo();
            }
          });
        }
        this.loaderService.hide();
      });
    }
  }


}
