import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../common/services/common.service';
import { UtilityService } from '../../../common/services/utility.service';
import { IResponse } from '../../../common/interfaces/response';
import { LandingPageService } from '../../../common/services/landing-page.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']

})
export class FaqComponent implements OnInit {
  public faqData = [];
    constructor(public cmsService: LandingPageService,public commonService: CommonService,public utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.getContent();
  }

   /**
   * show Icon red method
  */
  checkPendingReq(memberID){
  this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
    if (res && !res.errors) {
       this.commonService.guestRequestSharing.next(res.data);
    }
    });
  } /**
   * API Call for gettting the about us content
  */
  getContent() {
     const params = `&limit=100`;
    this.cmsService.getFAQ(params).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.faqData = res.data.data;
      }
    });
  }
}
