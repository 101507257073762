<!-- sign in start here -->
<!-- Button trigger modal -->
<!-- Modal -->
<div class="signin-modal-content">
    <div class="modal-header signin-modal-header">

        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body signin-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title">QR Memory Code Required</h1>
            <p class="signin-para">QR Memory code is a special code that will be allocated to your loved one’s memorial
                webpage.</p>
            <div class="signin-container">
                <form class="login-container" [formGroup]="memoryCodeForm" (ngSubmit)="onContinue()">
                    <div class="form-floating"
                        [ngClass]="memoryCode?.invalid && (memoryCode?.dirty || memoryCode?.touched)?'error-input':''">
                        <input type="text" class="form-control" id="memoryCode" maxlength="12"
                            placeholder="name@example.com" formControlName="memoryCode">
                        <label for="memoryCode">QR Memory Code</label>
                    </div>
                    <ng-container *ngIf="memoryCode?.invalid && (memoryCode?.dirty || memoryCode?.touched)">
                        <span class="invalid-input-text" *ngIf="memoryCode?.errors?.required">QR Memory Code is
                            required</span>
                            <span class="invalid-input-text" *ngIf="memoryCode?.errors?.pattern">QR Memory Code is
                                invalid</span>
                    </ng-container>
                    <div class="form-action">
                        <button class="btn btn-primary" type="submit">Continue</button>
                    </div>
                </form>
                <span class="memory-code-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>