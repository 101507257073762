<section>
    <div class="container add-member-container res-p-0">
        <form [formGroup]="addMemberForm" (ngSubmit)="saveMemberData(action)">
            <div class="main-container">
                <div class="add-member-wrapper">
                    <div class="header-img-infinity">
                        <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity">
                    </div>
                    <div class="header-img-mobile">
                        <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
                    </div>
                    <div class="footer-img-heart">
                        <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart">
                    </div>
                    <div class="footer-img-mobile">
                        <img src="./../../../../assets/images/footer-heart-brown2.svg" alt="footer heart image">
                    </div>
                    <div class="page-btn-wrap">
                        <div class="add-detail active">
                            <span class="add-detail-number">1</span>
                            <div class="add-detail-text ">Loved One’s Details</div>
                        </div>
                        <div [routerLink]="updateHighlightMemorial == false ? null : ['/about-memorial', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightMemorial == true}">
                            <span class="add-detail-number">2</span>
                            <div class="add-detail-text ">About Memorial</div>
                        </div>
                        <div [routerLink]="updateHighlightCareer == false ? null : ['/about-careers-hobbies', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightCareer == true}">
                            <span class="add-detail-number">3</span>
                            <div class="add-detail-text ">Hobbies & Career</div>
                        </div>
                        <div [routerLink]="updateHighlightFamily == false ? null : ['/about-family-memories', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightFamily == true}">
                            <span class="add-detail-number">4</span>
                            <div class="add-detail-text ">About Family</div>
                        </div>
                        <div [routerLink]="updateHighlightMemories == false ? null : ['/add-pictures-videos', memberId]"
                            class="add-detail" [ngClass]="{ 'active': updateHighlightMemories == true}">
                            <span class="add-detail-number">5</span>
                            <div class="add-detail-text ">Add Memories</div>
                        </div>
                    </div>

                    <div class="add-member-header">
                        <div>
                            <h1>{{updateHighlightMemories?"Edit your loved one's details":"Add your loved one's
                                details"}}</h1>
                            <p>Tell us a few basic details about your loved one.</p>
                        </div>
                        <div class="page-number-text">
                        </div>
                    </div>
                    <div class="row main-article-row">
                        <div class="col-12 col-lg-3 col-md-12 photo-frame-col">
                            <div class="photo-frame">

                                <div id="carouselExampleCaptions" class="carousel slide carousel-fade"
                                    data-bs-ride="carousel" data-bs-touch="false" data-bs-interval="false">
                                    <div class="carousel-inner">
                                        <div class="carousel-item"
                                            [ngClass]="selectedFrame===1?'carousel-item active':'carousel-item'">
                                            <img src="assets/images/add-member-frame.svg"
                                                class="d-block w-100 frame-img" alt="add-member-frame">
                                            <div class="carousel-caption carousel-file-input
                                             d-md-block carousel-add-img-caption" appDragDrop
                                                (onFileDropped)="uploadProfilePic($event,'drag')">
                                                <img [src]="profilePic" class="d-block w-100" alt="add-member-frame"
                                                    *ngIf="profilePic">
                                                <input type="file" title="" name="profile" id="profile" value="1"
                                                    accept="image/png, image/jpeg, image/jpg" [disabled]="dayCount>15"
                                                    (change)="uploadProfilePic($event)">
                                                <h5>Drop your <br />picture here or<br /> <span>Select an
                                                        image</span><br /> to upload.</h5>
                                            </div>
                                        </div>
                                        <div class="carousel-item"
                                            [ngClass]="selectedFrame===2?'carousel-item active':'carousel-item'">
                                            <img src="assets/images/add-member-frame2.svg"
                                                class="d-block w-100 frame-img" alt="add-member-frame2">
                                            <div class="carousel-caption carousel-file-input
                                             d-md-block carousel-add-img-caption" appDragDrop
                                                (onFileDropped)="uploadProfilePic($event,'drag')">
                                                <img [src]="profilePic" class="d-block w-100" alt="add-member-frame"
                                                    *ngIf="profilePic">
                                                <input type="file" title="" name="profile" id="profile" value="2"
                                                    name="frameNo" accept="image/png, image/jpeg, image/jpg"
                                                    [disabled]="dayCount>15" (change)="uploadProfilePic($event)">
                                                <h5>Drop your <br />picture here or<br /> <span>Select an
                                                        image</span><br /> to upload.</h5>
                                            </div>
                                        </div>
                                        <div class="carousel-item"
                                            [ngClass]="selectedFrame===3?'carousel-item active':'carousel-item'">
                                            <img src="assets/images/add-member-frame3.svg"
                                                class="d-block w-100 frame-img" alt="add-member-frame3">
                                            <div class="carousel-caption carousel-file-input
                                             d-md-block carousel-add-img-caption" appDragDrop
                                                (onFileDropped)="uploadProfilePic($event,'drag')">
                                                <img [src]="profilePic" class="d-block w-100" alt="add-member-frame"
                                                    *ngIf="profilePic">
                                                <input type="file" title="" name="profile" id="profile" value="3"
                                                    name="frameNo" accept="image/png, image/jpeg, image/jpg"
                                                    (change)="uploadProfilePic($event)" [disabled]="dayCount>15">
                                                <h5>Drop your <br />picture here or<br /> <span>Select an
                                                        image</span><br /> to upload.</h5>
                                            </div>
                                        </div>
                                        <div class="carousel-item"
                                            [ngClass]="selectedFrame===4?'carousel-item active':'carousel-item'">
                                            <img src="assets/images/add-member-frame4.svg"
                                                class="d-block w-100 frame-img" alt="add-member-frame3">
                                            <div class="carousel-caption carousel-file-input
                                             d-md-block carousel-add-img-caption" appDragDrop
                                                (onFileDropped)="uploadProfilePic($event,'drag')">
                                                <img [src]="profilePic" class="d-block w-100" alt="add-member-frame"
                                                    *ngIf="profilePic">
                                                <input type="file" title="" name="profile" id="profile" value="4"
                                                    name="frameNo" accept="image/png, image/jpeg, image/jpg"
                                                    [disabled]="dayCount>15" (change)="uploadProfilePic($event)">
                                                <h5>Drop your <br />picture here or<br /> <span>Select an
                                                        image</span><br /> to upload.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container>

                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-9 col-md-12 add-member-form-col">
                            <div class="add-member-form">
                                <div class="row">
                                    <div class="col-12 col-md-4 col-sm-12 col-xs-12">
                                        <div class="form-floating margin-right-0 margin-right-add"
                                            [ngClass]="firstName?.invalid && (firstName?.dirty || firstName?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="firstName"
                                                placeholder="First Name" formControlName="firstName" maxlength="20">
                                            <label for="firstName">First Name</label>
                                        </div>
                                        <ng-container
                                            *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)">
                                            <span class="invalid-input-text" *ngIf="firstName?.errors?.required">First
                                                Name is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-md-4 col-sm-6">
                                        <div class="form-floating "
                                            [ngClass]="middleName?.invalid && (middleName?.dirty || middleName?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="middleName"
                                                placeholder="Middle Name" formControlName="middleName" maxlength="20">
                                            <label for="middleName">Middle Name</label>
                                        </div>
                                        <ng-container
                                            *ngIf="middleName?.invalid && (middleName?.dirty || middleName?.touched)">
                                            <span class="invalid-input-text" *ngIf="middleName?.errors?.required">Middle
                                                Name is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-md-4 col-sm-6">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="lastName?.invalid && (lastName?.dirty || lastName?.touched)?'invalid-input':''">
                                            <input type="text" class=" form-control add-member-input" id="lastName"
                                                placeholder="Last Name" formControlName="lastName" maxlength="20">
                                            <label for="lastName">Last Name</label>
                                        </div>
                                        <ng-container
                                            *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)">
                                            <span class="invalid-input-text" *ngIf="lastName?.errors?.required">Last
                                                Name is required</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="form-floating  form-control-birth"
                                            [ngClass]="birthDate?.invalid && (birthDate?.dirty || birthDate?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control" id="birthDate"
                                                placeholder="Birth Date" autocomplete="off" aria-label="Birth Date"
                                                formControlName="birthDate" bsDatepicker readonly
                                                (bsValueChange)="onValueChange($event,'dob')" [maxDate]="minDate"
                                                [bsConfig]="{showWeekNumbers: false}"
                                                (click)="addClass('edit-birthDate bs-datepicker theme-green')" />
                                            <label for="birthDate">Birth Date</label>
                                        </div>
                                        <ng-container
                                            *ngIf="birthDate?.invalid && (birthDate?.dirty || birthDate?.touched)">
                                            <span class="invalid-input-text" *ngIf="birthDate?.errors?.required">Birth
                                                Date is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="form-floating  margin-right-0 form-control-death"
                                            [ngClass]="deathDate?.invalid && (deathDate?.dirty || deathDate?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control " id="deathDate"
                                                placeholder="Day of Passing" autocomplete="off" aria-label="Death Date"
                                                formControlName="deathDate" name="deathDate" bsDatepicker readonly
                                                (bsValueChange)="onValueChange($event,'dod')" [maxDate]="deathDateMax"
                                                (click)="addClass('edit-deathDate bs-datepicker theme-green')"
                                                [bsConfig]="{showWeekNumbers: false}" />
                                            <label for="deathDate">Day of Passing</label>
                                        </div>
                                        <ng-container
                                            *ngIf="deathDate?.invalid && (deathDate?.dirty || deathDate?.touched)">
                                            <span class="invalid-input-text" *ngIf="deathDate?.errors?.required">Death
                                                Date is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-12 col-md-12 payment-checkbox-col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="true"
                                                id="flexCheckChecked" formControlName="birthDatePrivate" />
                                            <label class="form-check-label payment-checkbox-label"
                                                for="flexCheckChecked">
                                                Make birth date private. </label>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <h4>Hometown</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="signin-state ">
                                            <div class="form-floating  state-input "
                                                [ngClass]="state?.invalid && (state?.dirty || state?.touched)?'invalid-input':''">
                                                <select
                                                    class="form-select add-member-input add-member-state-input state-city-select"
                                                    id="state" name="state" aria-label="Floating label select example"
                                                    formControlName="state"
                                                    (change)="changeState($event,'');selectedState=true"
                                                    placeholder="State">
                                                    <option selected *ngFor="let state of states"
                                                        [hidden]="!state?.name" [value]="state.id">{{state.name}}
                                                    </option>
                                                </select>
                                                <label for="floatingSelect" class=""
                                                    [ngClass]="selectedState?'removeMar':'state-label'">State</label>
                                            </div>
                                            <ng-container *ngIf="state?.invalid && (state?.dirty || state?.touched)">
                                                <span class="invalid-input-text" *ngIf="state?.errors?.required">State
                                                    is required</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="signin-state ">
                                            <div class="form-floating  state-input margin-right-0"
                                                [ngClass]="city?.invalid && (city?.dirty || city?.touched)?'invalid-input':''">
                                                <select
                                                    class="form-select add-member-input add-member-state-input state-city-select"
                                                    id="city" name="city" aria-label="Floating label select example"
                                                    formControlName="city" placeholder="City"
                                                    (change)="selectedCity=true">
                                                    <option *ngFor="let city of cities" [value]="city.id"
                                                        [hidden]="!city?.name">
                                                        {{city.name}}</option>
                                                </select>
                                                <label for="city" class=""
                                                    [ngClass]="selectedCity?'removeMar':'state-label'">City</label>
                                            </div>
                                            <ng-container *ngIf="city?.invalid && (city?.dirty || city?.touched)">
                                                <span class="invalid-input-text" *ngIf="city?.errors?.required">City is
                                                    required</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <h4>Gender</h4>
                                        <div class="checkbox-input-member">
                                            <div class="form-check form-check-inline gender-form-check">
                                                <input class="form-check-input add-member-input" type="radio" id="male"
                                                    value="male" formControlName="gender">
                                                <label class="form-check-label" for="male">Male</label>
                                            </div>
                                            <div
                                                class="form-check form-check-inline gender-form-check female-radio-input">
                                                <input class="form-check-input add-member-input margin-right-0"
                                                    type="radio" formControlName="gender" id="female" value="female">
                                                <label class="form-check-label" for="female">Female</label>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="gender?.invalid && (gender?.dirty || gender?.touched)">
                                            <span class="invalid-input-text-20" *ngIf="gender?.errors?.required">Gender
                                                is
                                                required</span>
                                        </ng-container>
                                        <h4>Burial Information</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="cemetryName?.invalid && (cemetryName?.dirty || cemetryName?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="cemetryName"
                                                placeholder="Cemetery Name" formControlName="cemetryName"
                                                maxlength="30">
                                            <label for="cemetryName">Cemetery Name</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cemetryName?.invalid && (cemetryName?.dirty || cemetryName?.touched)">
                                            <span class="invalid-input-text"
                                                *ngIf="cemetryName?.errors?.required">Cemetery Name is required</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 col-xs-12">
                                        <div class="form-floating margin-right-0 margin-right-add"
                                            [ngClass]="plotNumber?.invalid && (plotNumber?.dirty || plotNumber?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="plotNumber"
                                                maxlength="5" placeholder="Plot Number" formControlName="plotNumber"
                                                appNumbersonly>
                                            <label for="plotNumber">Plot Number</label>
                                        </div>
                                        <ng-container
                                            *ngIf="plotNumber?.invalid && (plotNumber?.dirty || plotNumber?.touched)">
                                            <span class="invalid-input-text" *ngIf="plotNumber?.errors?.required">Plot
                                                Number is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-md-4 col-xs-6">
                                        <div class="form-floating "
                                            [ngClass]="rowNumber?.invalid && (rowNumber?.dirty || rowNumber?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="rowNumber"
                                                maxlength="5" placeholder="Row Number" formControlName="rowNumber"
                                                appNumbersonly>
                                            <label for="rowNumber">Row Number</label>
                                        </div>
                                        <ng-container
                                            *ngIf="rowNumber?.invalid && (rowNumber?.dirty || rowNumber?.touched)">
                                            <span class="invalid-input-text" *ngIf="rowNumber?.errors?.required">Row
                                                Number is required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-6 col-md-4 col-xs-6">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="section?.invalid && (section?.dirty || section?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input margin-right-0"
                                                id="section" placeholder="Section" formControlName="section"
                                                maxlength="10">
                                            <label for="section">Section</label>
                                        </div>
                                        <ng-container *ngIf="section?.invalid && (section?.dirty || section?.touched)">
                                            <span class="invalid-input-text" *ngIf="section?.errors?.required">Section
                                                is required</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="cemetryAddress?.invalid && (cemetryAddress?.dirty || cemetryAddress?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control margin-right-0" id="cemetryAddress"
                                                maxlength="50" placeholder="Cemetery Address"
                                                formControlName="cemetryAddress">
                                            <label for="cemetryAddress">Cemetery Address</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cemetryAddress?.invalid && (cemetryAddress?.dirty || cemetryAddress?.touched)">
                                            <span class="invalid-input-text"
                                                *ngIf="cemetryAddress?.errors?.required">Cemetry Address is
                                                required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-12 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control" id="zipCode" placeholder="Zip Code"
                                                maxlength="11" minlength="5" formControlName="zipCode" appNumbersonly>
                                            <label for="zipCode">Zip Code</label>
                                        </div>
                                        <ng-container *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)">
                                            <span class="invalid-input-text" *ngIf="zipCode?.errors?.required">Zip Code
                                                is required</span>
                                            <span class="invalid-input-text" *ngIf="zipCode?.errors?.minlength">Zip Code
                                                should not be less than 5 digits</span>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="signin-state ">
                                            <div class="form-floating state-input "
                                                [ngClass]="burialState?.invalid && (burialState?.dirty || burialState?.touched)?'invalid-input':''">
                                                <select class="form-select add-member-state-input state-city-select"
                                                    id="state" name="state" aria-label="Floating label select example"
                                                    placeholder="State" formControlName="burialState"
                                                    (change)="changeState($event,'burial');selectedBurialState=true"
                                                    formControlName="burialState">
                                                    <option selected *ngFor="let state of states" [value]="state.id"
                                                        [hidden]="!state.name">{{state.name}}</option>
                                                    <label for="burialState">State</label>
                                                </select>
                                                <label for="burialState" class=""
                                                    [ngClass]="selectedBurialState?'removeMarBurial':'burial-state-label'">State</label>
                                            </div>
                                            <ng-container
                                                *ngIf="burialState?.invalid && (burialState?.dirty || burialState?.touched)">
                                                <span class="invalid-input-text"
                                                    *ngIf="burialState?.errors?.required">State
                                                    is required</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="signin-state ">
                                            <div class="form-floating state-input margin-right-0"
                                                [ngClass]="burialCity?.invalid && (burialCity?.dirty || burialCity?.touched)?'invalid-input':''">
                                                <select class="form-select add-member-state-input state-city-select"
                                                    id="city" name="city" aria-label="Floating label select example"
                                                    formControlName="burialCity" placeholder="City"
                                                    (change)="selectedBurialCity=true">
                                                    <option *ngFor="let city of burialCities" [value]="city.id">
                                                        {{city?.name}}</option>
                                                    <label for="city">City</label>
                                                </select>
                                                <label for="city" class=""
                                                    [ngClass]="selectedBurialCity?'removeMarBurial':'burial-state-label'">City</label>
                                            </div>
                                            <ng-container
                                                *ngIf="burialCity?.invalid && (burialCity?.dirty || burialCity?.touched)">
                                                <span class="invalid-input-text"
                                                    *ngIf="burialCity?.errors?.required">City
                                                    is required</span>
                                            </ng-container>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="form-floating margin-right-0"
                                            [ngClass]="cemetryWebsite?.invalid && (cemetryWebsite?.dirty || cemetryWebsite?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control" id="cemetryWebsite"
                                                formControlName="cemetryWebsite" placeholder="Cemetery Website">
                                            <label for="cemetryWebsite">Cemetery Website</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cemetryWebsite?.invalid && (cemetryWebsite?.dirty || cemetryWebsite?.touched)">
                                            <span class="invalid-input-text"
                                                *ngIf="cemetryWebsite?.errors?.required">Cemetery Website is
                                                required</span>
                                            <span class="invalid-input-text"
                                                *ngIf="cemetryWebsite?.errors?.pattern">Cemetery
                                                Website is not valid
                                                url</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-12 col-md-12 payment-checkbox-col margin-bottom">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="true"
                                                id="flexCheckChecked" formControlName="burialInfoPrivate" />
                                            <label class="form-check-label payment-checkbox-label"
                                                for="flexCheckChecked">
                                                Make burial information private.</label>
                                        </div>
                                    </div>
                                </div>
                                <p class="note" *ngIf="!updateHighlightMemories">Note: Only date of birth and date of
                                    death will be
                                    editable after submitting the form.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="member-buttons text-center">
                <button type="button" class="btn btn-outline-primary extra-padding" (click)="goBack()">Back</button>
                <button type="submit" (click)="action='saveNext'" class="btn btn-primary extra-padding">Save &
                    Next</button>
                <button *ngIf="updateHighlightMemories" type="submit" class="btn btn-primary extra-padding">Save &
                    Finish</button>
            </div>
        </form>
    </div>
</section>
