export const MagicNumbers = {
    innerWidth: 767,
    oneHundred: 100,
    ten: 10,
    fifteen: 15,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
    thirty: 30,
    three: 3,
    sixteen: 16,
    thousand: 1000,
    fourThousand: 4000,
    twoHundred: 200,
    fourHundredOne: 401,
    fourHundredThree: 403,
    fifteenHundred: 1500,
    threeHundred: 300,
    thirtyFive: 35,
    MbSize: 20000000,
    MbImageSize:10000000,
    MbPrayerCardSize:5000000,
    two: 2,
    five: 5,
    twelve: 12,
    fifty: 50,
    four: 4,
    twoHundredFifty: 250,
    twoHundredFiftyFive: 255,
    one:1,
    six:6,
    zero:0,
    oneThousandTwentyFour:1024,
    oneNintyFive : 195,
    fiveHundred: 500,
    twoThousand: 2000,
    fourteenNineNine: 14.99,
    twentyFourNineNine: 24.99
};
