import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MagicNumbers } from '../../component/app-magic-numbers';

@Component({
  selector: 'app-edit-invitation',
  templateUrl: './edit-invitation.component.html',
  styleUrls: ['./edit-invitation.component.scss']
})
export class EditInvitationComponent implements OnInit {
  @Input() invitationText: string;
  @Output() invitationTextUpdated = new EventEmitter<string>();
  form: FormGroup;
  constructor(public modalRef: BsModalRef, private readonly formBuilder: FormBuilder) {
    // Initialize the form builder
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      message: [this.invitationText || '', [Validators.required, Validators.maxLength(MagicNumbers.fiveHundred)]]
    });
  }

  submitForm() {
    if (this.form.valid) {
      this.invitationTextUpdated.emit(this.form.get('message').value);
      this.modalRef.hide();
    }
  }

  onClose() {
    this.modalRef.hide();
  }

  onTextareaChange() {
    // Optional: handle any changes in the textarea
  }
}
