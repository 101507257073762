import { AfterViewInit, Component, ComponentRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationPopupComponent } from '../../common/component/confirmation-popup/confirmation-popup.component';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { IResponse } from '../../common/interfaces/response';
import { AddGuestbookService } from '../../common/services/add-guestbook.service';
import { CommonService } from '../../common/services/common.service';
import { UtilityService } from '../../common/services/utility.service';
import { Location } from '@angular/common';

function noBlankSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const hasBlankSpace = (control.value || '').trim().length === 0;
    return hasBlankSpace ? { 'blankSpace': true } : null;
  };
}
@Component({
  selector: 'app-add-guest-message',
  templateUrl: './add-guest-message.component.html',
  styleUrls: ['./add-guest-message.component.scss'],
})
export class AddGuestMessageComponent implements OnInit,AfterViewInit {
  deleteRequestMedia: any;
  index: any;
  broadCastStatus: any;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if(event){
      const viewId = localStorage.getItem('member_id');
      this.router.navigate(['/view-information/',viewId]);
    }
  }
  
  @ViewChild('addGuestMessage', { static: false }) addGuestMessage: any;
  @ViewChild('deleteMsgModal', { static: false }) deleteMsgModal: any;
  public videosArr = [];
  public guestUser: any;
  viewInfoUrl:any;
  public message: string;
  public messageList: any = [];
  public modalRef: BsModalRef;
  public modalRefComp : ComponentRef<ConfirmationPopupComponent>;
  public deleteRequest: any;
  public editMessage: boolean;
  public editMessageObj: any;
  public form: FormGroup;
  public pictureArr = [];
  public pictureCount = 0;
  public videoCount = 0;
  public userData:any;
  public destroy$ = new Subject();
  public mediaListPicture: any;
  public mediaType: number;
  public uploadLimit: any;
  public previewImage: string;
  public modalClass = 'modal-dialog modal-dialog-centered custom-modal';

  constructor(
    public addGuestbookService: AddGuestbookService,
    public modalService: BsModalService,
    private readonly fb: FormBuilder,
    public toastr: ToastrService,
    public utilityService: UtilityService,
    public commonService: CommonService,
    public loaderService: NgxSpinnerService,
    public router : Router,
    public location: Location
    
  ) {
    this.form = this.fb.group({
      message: ['', [Validators.required, noBlankSpaceValidator()]]
    });
    this.previewImage = ''; 
  }

  ngOnInit(): void {
    this.guestUser = JSON.parse(localStorage.getItem('memberGuest'));
        // To listen the user data
        this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res) {
            this.userData = res;
          }
        });
    this.getAllowMediaLimit();    
    this.getMediaList(0);
    this.getMediaList(1);
    this.getMessage();
    this.getBoardcastMessage();
  }

  ngAfterViewInit(){
    this.commonService.mediaApiCall.subscribe((res)=>{
      this.getAllowMediaLimit();
      this.getMediaList(0);
      this.getMediaList(1);
      this.getMessage();
    });
    this.commonService.deleteConfirmation.subscribe((res)=>{
      if(res.status){
        if(res.value==='deleteMessage'){
          this.deleteMessage();
        }else if(res.value===0){
          this.deleteImage(res.value);
        }else if(res.value===1){
          this.deleteVideo(res.value);
        }
        
      }
    });
  }

  /**
   * API Call to submit message
   */
  submitMessage() {
    const payloads = {
      message: this.form.value.message,
      guestbook_id: this.guestUser.guestbook_id,
      member_id: this.guestUser.member_id,
    };
    if(this.editMessage){
      delete payloads.guestbook_id;
      delete payloads.member_id;
      payloads['id'] = this.editMessageObj.id;
    }
    
    this.addGuestbookService
      .submitMessageForm(payloads,this.editMessage)
      .subscribe((res: any) => {
        if (res) {
          this.toastr.success(res.message);
          this.modalRef.hide();
          this.form.reset();
          this.getMessage();
        }
      });
  }

  /**
   * API Call to get message list
   */
  getMessage() {
    this.messageList = [];
    const payloads = {
      message: this.form.value.message,
      guestbook_id: this.guestUser.guestbook_id,
      member_id: this.guestUser.member_id,
    };
    this.addGuestbookService.getMessageList(payloads).subscribe((res: any) => {
      if (res) {
        this.messageList = res.data.data;
      }
    });
  }

  /**
   * Opening the add guestbook message
   */
  openAddModal(addGuestMessage, value?) {
    this.editMessage = false;
    this.form.value.message = '';
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: this.modalClass
    };
    this.modalRef = this.modalService.show(addGuestMessage, config);
    this.deleteRequest = value;
  }

    /**
   * Opening the confirmation guestbook message
   */
    confirmationPopup(value?,status?:any) {
      this.editMessage = false;
      this.form.value.message = '';
      const config = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: false,
        class: this.modalClass,
        initialState: { 
          data:status
        }
      };
      this.modalRef = this.modalService.show(ConfirmationPopupComponent, config);
      this.deleteRequest = value;
    }

      /**
   * Opening the add guestbook message
   */
  confirmationMediaPopup(value?,status?:any,index?:any) {
    this.index = null;
    this.editMessage = false;
    this.deleteRequestMedia = null;
    this.form.value.message = '';
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: this.modalClass,
      initialState: { 
        data:status
      }
    };
    this.modalRef = this.modalService.show(ConfirmationPopupComponent, config);
    this.index = index;
    this.deleteRequestMedia = value;
  }

/**
   * Opening the edit guestbook message
   */
  openEditModal(addGuestMessage, value?){
    this.form.value.message = '';
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: this.modalClass,
    };
    this.editMessageObj = value;
    this.form.patchValue({message:value.message});
    this.editMessage = true;
    this.modalRef = this.modalService.show(addGuestMessage, config);
  }

  /**
   * API Call to delete message
   */
  deleteMessage() {
    const payloads = {
      id: this.deleteRequest.id,
      guestbook_id: this.guestUser.guestbook_id,
    };
    this.addGuestbookService.deleteMessage(payloads).subscribe((res: any) => {
      if (res) {
        this.toastr.success(res.message);
        this.modalRef.hide();
        this.getMessage();
      }
    });
  }

    /**
   * To upload the image or video
  */
    fileSelected(event, type?) {
      this.mediaType = type ==='image' ? 0 : 1;
      const fd = new FormData();
      fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
      const uploadType = event.target.files[0].type.split('/')[0];
      if (uploadType === 'image' && this.pictureCount >= MagicNumbers.twoHundredFifty) {
        this.toastr.error('Sorry! Your limit reached');
      } else if (uploadType === 'video' && this.videoCount >= MagicNumbers.ten) {
        this.toastr.error('Sorry! Your limit reached');
      } else {
        if (uploadType !== type) {
          this.toastr.error(`Please upload ${type}`);
        } else if (event.target.files[0].size >= MagicNumbers.MbSize) {
          this.toastr.error(`Please upload less than 10 mb ${uploadType}`);
        } else {
          this.utilityService[uploadType === 'image' ? 'uploadImage' : 'uploadVideo'](fd, uploadType === 'image' ? 'other' : 'video').subscribe((res: IResponse) => {
            if (res && !res.errors) {
              this.addMediaUpload(res.data.url,this.mediaType);
              this.toastr.success(res.message);
              if (uploadType === 'image'){
                this.pictureCount = 0;
                this.pictureArr.forEach((e) => {
                  if (e !== '') {
                    this.pictureCount = this.pictureCount + 1;
                  }
                });
              } else {
                this.videoCount = 0;
                this.videosArr.forEach((e) => {
                  if (e !== '') {
                    this.videoCount = this.videoCount + 1;
                  }
                });
              }
            }
            event.target.value = '';
          });
        }
      }
    }
    /**
   * Method to call add media upload
  */
  addMediaUpload(media,Type?){
    this.loaderService.show();
    const payloads={
      guestbook_id: this.guestUser.guestbook_id,
      member_id: this.guestUser.member_id,
      path:media,
      type:Type
    };
    this.addGuestbookService.addGuestbookMedia(payloads).subscribe((res: any) => {
      if (res) {
        this.loaderService.show();
        this.getMediaList(Type);
      }
    });
  }

      /**
   * Method to call get media list
  */
  getMediaList(Type?){
    const payloads = {
      guestbook_id: this.guestUser.guestbook_id,
      member_id: this.guestUser.member_id,
      type:Type
    };
    this.addGuestbookService.getMediaList(payloads).subscribe((res: any) => {
      if(res.data) {
        if(Type===0){
          this.pictureArr = res.data.data ? res.data.data : [];
          this.pictureCount = res.data.data ? res.data.data.length :0;
        }else{
          this.videosArr = res.data.data ? res.data.data : [];
          this.videoCount = res.data.data ? res.data.data.length :0;
        }
        this.loaderService.hide();
      }
      this.loaderService.hide();
    });
  }

  /**
   * Method to call get media list
  */
  getAllowMediaLimit(){
    const payloads={
      member_id: this.guestUser.member_id,
    };
    this.addGuestbookService.getAllowMediaLimit(payloads).subscribe((res: any) => {
      if (res) {
        this.uploadLimit = res.data;
        this.loaderService.hide();
      }
      this.loaderService.hide();
    });
  }


  /**
   * To open the vide in new tab
   * @param doc is response of list
   */
  showDoc(doc: string) {
    window.open(doc, '_blank');
  }

  showVideo(video: string) {
    window.open(video, '_blank');
  }

      /**
   * To delete the image
  */
  deleteImage(type) {
    this.pictureArr.splice(this.index, 1,'');
    const payloads ={
      id : this.deleteRequestMedia.id,
      guestbook_id : this.deleteRequestMedia.guestbook_id 
    };
    this.addGuestbookService.deleteMediaPicture(payloads).subscribe((res: any) => {
      if (res) {
        this.getMediaList(type);
        this.modalRef.hide();
      }
    });
  }

  /**
   * To delete the video
  */
  deleteVideo(type) {
    this.videosArr.splice(this.index, 1,'');
    const payloads ={
      id : this.deleteRequestMedia.id,
      guestbook_id : this.deleteRequestMedia.guestbook_id 
    };
    this.addGuestbookService.deleteMediaPicture(payloads).subscribe((res: any) => {
      if (res) {
        this.getMediaList(type);
        this.modalRef.hide();
      }
    });
  }

   /**
   * Method to call change broadcast message status
  */
  onChangeBoardcast(event){
    const status1 = event.target.checked === true ? 1 : 0;
    const payloads ={
      member_id : this.guestUser.member_id,
      email : this.guestUser.email,
      status: status1
    };
    this.addGuestbookService.postBoardCast(payloads).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.getBoardcastMessage();
      }
    });
  }
   /**
   * Method to call get broadcast message
  */
  getBoardcastMessage() {
    const payloads = `member_id=${this.guestUser.member_id}&email=${this.guestUser.email}`;
    this.addGuestbookService.getBoardCast(payloads).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.broadCastStatus = res.data;
      };
    });
  }
}
