import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { IResponse } from '../../interfaces/response';
import { CommonService } from '../../services/common.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public states;
  public searchName;
  public searchObj = { name: undefined, birthDate: undefined, deathDate: undefined, type: undefined, state: undefined, memoryCodeNumber: undefined };
  public isPersonal = true;
  public minDate = new Date();
  public maxDate = new Date();
  public today = new Date();
  public selectedState = false;
  constructor(public utilityService: UtilityService, public commonStateService: CommonService, public modalRef: BsModalRef, public router: Router, public toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.getStates();
  }
  /**
   * API Call for states list
  */
  getStates() {
    this.states = [];
    this.selectedState = false;
    this.utilityService.getStates().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
        setTimeout(() => {
          this.searchObj.state = null;
          this.selectedState = false;
        }, MagicNumbers.oneHundred);
      }
    });
  }
  /**
   * To pass the search params to search component
   * @param type is required
  */
  goToSearch(type?) {
    this.modalRef.hide();
    localStorage.removeItem('memberGuestLike');
    sessionStorage.removeItem('memberGuestLike');
    sessionStorage.removeItem('showCommentInput');


    if (this.searchCondition()) {
      this.searchObjType(); 
      this.searchRoute(type);
    } else if (!type || !this.isPersonal) {
      this.toastr.error(`Please enter ${this.isPersonal ? 'name' : 'memory code number'}`);
    } else {
      this.searchRoute(type);
    }
  }

  /**
   * Route to search result page
   */
  searchRoute(type){
    this.commonStateService.searchData.next(this.searchData(type));
    this.router.navigate(['/search-results']);
  }

  /**
   * Pass value in searchObj on based of ispersonal
   */
   searchObjType(){
     if (this.isPersonal) {
       this.searchObj.type = 'personal_detail';
     } else {
       this.searchObj.type = 'memory_code';
     }
   }
  /**
  * Search Condition
  */
  searchCondition(){
   return (this.searchName || this.searchObj.name || this.searchObj.deathDate || this.searchObj.birthDate || this.searchObj.state || this.searchObj.memoryCodeNumber);
  }

  /**
   * share data on search data
   */

  searchData(type){
   return (type ? this.searchObj : { type: this.searchObj.type, [this.isPersonal ? 'name' : 'memoryCodeNumber']: this.searchName });
  }
  /**
    * On value change of date picker
    * @param e and @param type is required
  */
  onValueChange(e, type) {
    if (type === 'dob') {
      this.maxDate = e;
    } else {
      this.minDate = e;
    }
  }
  /**
     * To add the class for birth date picker
  */
  addClass(className) {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', className);
    }, 0);
  }
}
