import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {
 ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
