import { Component, OnInit } from '@angular/core';
import { IResponse } from '../../../common/interfaces/response';
import { CommonService } from '../../../common/services/common.service';
import { LandingPageService } from '../../../common/services/landing-page.service';
import { UtilityService } from '../../../common/services/utility.service';
@Component({
selector: 'app-contact-us',
templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
public contactUsData;
constructor(public cmsService: LandingPageService,public commonService: CommonService,public utilityService: UtilityService) {
}
ngOnInit(): void {
this.getContent();
window.scrollTo(0, 0);
}

/**
* API Call for gettting the about us content 
*/
getContent() {
this.cmsService.getContentBasedonPage('contact-us').subscribe((res: IResponse) => {
if (res && !res.errors) {
this.contactUsData = res.data;
}
});
}
}
