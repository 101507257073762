import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/interfaces/response';
import { AuthService } from '../../../common/services/auth.service';
import { CommonService } from '../../../common/services/common.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MemoryCodeRequiredComponent } from '../memory-code-required/memory-code-required.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { MagicNumbers } from '../../../common/component/app-magic-numbers';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  public signInForm: FormGroup;
  public isShow = false;
  constructor(public modalService: BsModalService, public modalRef: BsModalRef, public formBuilder: FormBuilder,
    public authService: AuthService, public toastr: ToastrService, public commonService: CommonService, public router: Router) {
    this.signInForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

  }


  forgotPassword() {
    this.modalRef.hide();
    this.modalService.show(ForgotPasswordComponent, { class: 'signin-modal' });
  }
  openSignUp() {
    this.modalRef.hide();
    this.modalService.show(SignUpComponent, { class: 'signin-modal' });
  }

  get emailId() { return this.signInForm.get('emailId'); }
  get password() { return this.signInForm.get('password'); }

  /**
   * API Call for sign in
  */
  onSignIn() {
    if (this.signInForm.valid) {
      const payload = {
        email: this.signInForm.value.emailId,
        password: this.signInForm.value.password
      };
      this.authService.onSignIn(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.modalRef.hide();
          localStorage.setItem('userData', JSON.stringify(res.data));
          if(res.data.user.account_type==1){
          const deliverType = res?.data?.user?.deliver_code_type;
          if(deliverType){
            if(deliverType === MagicNumbers.three){
            localStorage.setItem('memory_code','yes');
            }
          }
          this.commonService.userData.next(res.data);
          this.commonService.isLoggedIn.next(true);
          if (res.data.members[0]) {
            this.router.navigate(['/members-list']);
          } else if(res.data.is_member_pending!= true) {
            this.router.navigate(['/upgrade-plans']);
          } else{
           this.router.navigate(['/home']);
          }
        } else{
          this.commonService.userData.next(res.data);
          this.commonService.isLoggedIn.next(true);
          if(res.data.user.profile_picture){
            if(res.data.members){
              this.router.navigate(['/members-list']);
            } else{
              this.router.navigate(['/home']);
            }
          } else{
            this.router.navigate(['/add-co-owner-details']);
          }
        }
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.signInForm.markAllAsTouched();
    }
  }
}
