<section class="about-us-section">
    <div class="container">
        <h2 class="section-title">About Us</h2>
        <p class="about-text-para" [innerHTML]="aboutUsData.content">
        </p>
        <div class="image-section">
            <div class="row">
                <div class="col-6 col-md-4 about-img-col" *ngFor="let image of aboutUsData.images">
                    <span class="image-wrapper"><img [src]="image" alt="about-us-img" /></span>
                </div>
            </div>
        </div>
    </div>
</section>