import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddGuestbookService {
  apiUrl: any;

  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  /**
   * API Call for sign guestBook
   * @payloads means data having form values
   */
  signSubmitForm(payloads: any) {
    return this.http.get(
      `${this.apiUrl}check-guest?email=${payloads.email}&member_id=${payloads.member_id}`
    );
  }

  /**
   * API Call for Add guestBook
   * @payloads means data having form values
   */
  submitForm(payloads: any) {
    return this.http.post(`${this.apiUrl}guestbook-create?profile_picture=${payloads.profile_picture}&name=${payloads.name}&contact_number=${payloads.contact_number}
      &address=${payloads.address}&email=${payloads.email}&relation=${payloads.relation}
      &message=${payloads.message}&opt_out=${payloads.optOut}&member_id=${payloads.member_id}`, '');
  }

  /**
   * API Call for Add guestBook message
   * @payloads means data having form values
   */
  submitMessageForm(payloads: any,check:boolean) {
    if(check){
    return this.http.put(`${this.apiUrl}guestbook/update-message?id=${payloads.id}&message=${payloads.message}`, '');
    }else{
      return this.http.post(`${this.apiUrl}guestbook/add-message?guestbook_id=${payloads.guestbook_id}&message=${payloads.message}&member_id=${payloads.member_id}`, '');
    }
  }

  /**
   * API Call to get message
   * @payloads means data having form values
   */
  getMessageList(payloads: any) {
    return this.http.get(
      `${this.apiUrl}guestbook/message-list?member_id=${payloads.member_id}&guestbook_id=${payloads.guestbook_id}&status=${payloads.status}&status=${payloads.status}`
    );
  }

  /**
   * API Call to update member information
   * @payloads means data having form values
   */
  updateMemberInformation(payloads: any) {
  return this.http.delete(`${this.apiUrl}guestbook/delete-media?id=${payloads.id}`);
}

  /**
   * API Call to get message request
   * @payloads means data having form values
   */
  getMessageRequestList(payloads: any) {
    return this.http.get(
      `${this.apiUrl}user/guestbook/message-list?member_id=${payloads.member_id}&status=${payloads.status}`
    );
  }

   /**
  * API Call to get message request
  * @payloads means data having form values
  */
   getMediaRequestList(payloads: any) {
     return this.http.get(
       `${this.apiUrl}user/guestbook/media-list?member_id=${payloads.member_id}&source=${payloads.source}&status=${payloads.status}`
     );
   }

  /**
   * API Call for delete guestBook message
   * @payloads means data having form values
   */
  deleteMessage(payloads: any) {
    return this.http.delete(`${this.apiUrl}guestbook/delete-message?id=${payloads.id}&guestbook_id=${payloads.guestbook_id}`);
  }

  /**
   * API Call for update guestBook request status
   * @payloads means data having form values
   */
  updateGuestbookRequest(payloads: any) {
    return this.http.put(`${this.apiUrl}user/guestbook/message-change-status?id=${payloads.id}&status=${payloads.status}`, '');
  }

  /**
  * API Call for update guestBook media request status
  * @payloads means data having form values
  */
  updateMediaGuestbookRequest(payloads: any) {
   return this.http.put(`${this.apiUrl}user/guestbook/media-change-status?id=${payloads.id}&status=${payloads.status}`, '');
  }

   /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
   addGuestbookMedia(payloads: any) {
     return this.http.post(`${this.apiUrl}guestbook/add-media?member_id=${payloads.member_id}&guestbook_id=${payloads.guestbook_id}
      &path=${payloads.path}&type=${payloads.type}`, '');
   }

   addGuestbookTotalCount(payloads: any) {
    return this.http.get(`${this.apiUrl}user/guestbook/message-media-count?member_id=${payloads.member_id}&type=${payloads.type}`);
  }
    /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
    getMediaList(payloads: any) {
      return this.http.get(`${this.apiUrl}guestbook/media-list?member_id=${payloads.member_id}&guestbook_id=${payloads.guestbook_id}
        &type=${payloads.type}&status=${payloads.status}`);
    }

       /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
    getAllowMediaLimit(payloads?: any,type?) {
      if(type){
        return this.http.get(`${this.apiUrl}guestbook/allowed-media-limit?member_id=${payloads.member_id}&type=${type}&status=${payloads.status}`);
      }else{
        return this.http.get(`${this.apiUrl}guestbook/allowed-media-limit?member_id=${payloads.member_id}&status=${payloads.status}`);
      }
    }

           /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
     getGuestUser(payloads?: any) {
       return this.http.get(`${this.apiUrl}guestbook/guest-list?${payloads}&limit=1000`);
    }
        /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
     deleteMediaPicture(payloads: any) {
      return this.http.delete(`${this.apiUrl}guestbook/delete-media?id=${payloads.id}&guestbook_id=${payloads.guestbook_id}`);
    }

  /**
  * API Call for 
  * @payloads means data get Board Cast
  */
  getBoardCast(payloads?: any) {
     return this.http.get(`${this.apiUrl}broadcast/subscribe-status?${payloads}`);
  }

    /**
  * API Call for 
  * @payloads means data get comment data
  */
  getCommentsByUser(payloads?: any) {
      return this.http.get(`${this.apiUrl}guestbook/like-comment-list?${payloads}&limit=1000`);
  }

  /**
  * API Call for 
  * @payloads means data get comment data
  */
  getCommentsByMember(payloads?: any) {
   return this.http.get(`${this.apiUrl}member/like-comment-list?${payloads}&limit=1000`);
  }

  /**
  * API Call for 
  * @payloads means add comment
  */
  addCommentsByGuestbook(payloads?: any) {
      return this.http.post(`${this.apiUrl}guestbook/add-comment?${payloads}`,'');
   }
  
    /**
  * API Call for 
  * @payloads means add comment
  */
  addCommentsByUser(payloads?: any) {
     return this.http.post(`${this.apiUrl}member/add-comment?${payloads}`,'');
  }

  /**
  * API Call for 
  * @payloads means add like 
  */
  addLikeStatusByGuestbook(payloads?: any) {
    return this.http.post(`${this.apiUrl}guestbook/like-unlike?${payloads}`,'');
  }

    /**
  * API Call for 
  * @payloads means add like 
  */
  addLikeStatusByMember(payloads?: any) {
  return this.http.post(`${this.apiUrl}member/like-unlike?${payloads}`,'');
  }

  deleteComment(payloads: any) {
    return this.http.delete(`${this.apiUrl}member/comment?id=${payloads.id}`);
  }
   /**
  * API Call for 
  * @payloads means add like comment
  */
   addLikeCommentStatusByUser(payloads?: any) {
    return this.http.post(`${this.apiUrl}guestbook/like-unlike-comment?${payloads}`,'');
 }

  /**
  * API Call for 
  * @payloads means add like comment
  */
  addLikeCommentStatusByMember(payloads?: any) {
    return this.http.post(`${this.apiUrl}member/like-unlike-comment?${payloads}`,'');
  }

    /**
  * API Call for 
  * @payloads means data post Board Cast
  */
    postBoardCast(payloads?: any) {
      return this.http.post(`${this.apiUrl}broadcast/update-subscribe-status`,payloads);
   }

   dragAndDropGuest(payloads?: any) {
    return this.http.post(`${this.apiUrl}user/guestbook/update-media-order`,payloads);
 }  
}
