import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {

  constructor(public utilityService: UtilityService,private readonly route: ActivatedRoute, private readonly router: Router) { }

  ngOnInit(): void {
  }

  unsubscribeEmail(token){
    this.utilityService.getUnsubscribe(token).subscribe((res: any) => {
      if (res && !res.errors) {
      }
      });
  }

  resendMail(){
    this.router.navigate(['/landing-page']);
  }

}
