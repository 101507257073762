<div class="signin-modal-content change-password-content">
    <div class="modal-header signin-modal-header">
        <button (click)="modalRef.hide()" type="button" class="btn-close ms-auto" aria-label="Close"></button>
    </div>
    <div class="modal-body signin-body change-password-body having-issues-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="having-issues-title">Having Issues With The QR Memory Code?</div>
            <div class="having-issues-subtitle">Please select what is the issue you are facing with the QR Memory code.</div>
            <div class="signin-container">
                <form [formGroup]="markAsReturnedCodeForm" (ngSubmit)="markAsReturn()" class="login-container change-password-container qr-code-modal-form">
                    <div class="form-floating state-input right-margin-0 mb-2 qr-code-having-issues">
                        <select class="form-select plan-form-select" (change)="selectOption($event);selectedState=true" [(ngModel)]="selectedOption" formControlName="retReasonId" id="floatingSelect">
                            <option *ngFor="let data of returnReasons; let idx = index" [value]="data.id" [id]='data.id'>{{data.reason}}</option>
                          </select>
                        <label for="floatingSelect" class="state-label" [ngClass]="selectedState?'removeMar':''">Select Your Issue</label>
                    </div>
                    <div *ngIf="inputAreaOn" class="textarea-sec">
                        <div class="textarea-message">
                            <div class="form-floating form-floating-add">
                                <textarea onkeypress="return this.value.trim() !== '' || /\S/.test(event.key)" maxlength="100" class="form-control" placeholder="Message" (keyup)="onChangevalue()" formControlName="retReasonComment" id="floatingTextarea"></textarea>
                                <label for="floatingTextarea">Type your issue here</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-action">
                        <button class="btn btn-primary" type="submit" [disabled]="show || (!markAsReturnedCodeForm.value.retReasonComment && markAsReturnedCodeForm.value.retReasonId == 2)">Submit</button>
                    </div>
                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>