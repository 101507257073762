import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor(public utilityService: UtilityService,private readonly route: ActivatedRoute, private readonly router: Router) { }

  ngOnInit(): void {
    const credential = this.route.snapshot.queryParamMap.get('token');
    this.unsubscribeEmail(credential);
  }

  unsubscribeEmail(token){
    this.utilityService.getUnsubscribe(token).subscribe((res: any) => {
      if (res && !res.errors) {
      }
      });
  }

  backToHome(){
    this.router.navigate(['/landing-page']);
  }

}
