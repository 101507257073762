<section>
    <div class="container payment-section-container">
        <div class="row">
            <div class="col-12 col-lg-5 purchase-memory-col-left">
                <div class="purchase-memory-wrapper-left">
                    <span class="purchase-memory-logo"><img src="assets/images/purchase-app-logo.svg"
                            alt="purchase-app-logo" /></span>
                    <h1>Purchase QR Memory Code</h1>
                    <p>We all have loved ones, families, friends or partners who are our entire world. We love them
                        infinitely and have a lifetime</p>
                    <p class="last-para-margin">QR Memory codes allow loved ones to unlock their memorialized webpage
                        and
                        allows others to view and/or contribute. You may also affix the code to a desired memorialized
                        location.
                    </p>
                    <div class="rip-qr-code-section">
                        <span><img src="assets/images/rip-qr-img.png" alt="rip-qr-img.png"
                                class="purchase-qr-img" /></span>
                        <p class="qr-code-para">Anyone can Scan the QR Memory code or Input the QR Memory code from
                            their mobile
                            device to view the history of those who perished. <img
                                src="assets/images/rip-qr-guide-arrow.svg" alt="guide-arrow"
                                class="payment-guide-arrow" /></p>

                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-7 payment-form-col">
                <div class="container res-p-16">
                    <app-header></app-header>
                    <div class="add-member-container">
                        <div class="main-container main-container-purchase">
                            <div class=" purchase-memory-wrapper-right ">
                                <div class="purchase-memory">
                                    <img src="assets/images/header-infinity-purchase.svg" alt="header-infinity "
                                        class="header-img-infinity img-fluid " />
                                </div>
                                <div class="purchase-memory-title" *ngIf="!isPaymentSuccess">Purchase QR Memory Code
                                </div>
                                <div class="add-member-header" *ngIf="!isPaymentSuccess">
                                    <div class="add-detail active">
                                        <span class="add-detail-number">1</span>
                                        <div class="add-detail-text ">Add Your Details</div>
                                    </div>
                                    <div class="add-detail"
                                        [ngClass]="!isDetailShow? 'add-detail active': 'add-detail' ">
                                        <span class="add-detail-number">2</span>
                                        <div class="add-detail-text">Payment</div>
                                    </div>

                                </div>
                                <div *ngIf="isDetailShow && !isPaymentSuccess"
                                    class="about-memorial-section purchase-memory-section">
                                    <form class="add-member-form " [formGroup]="detailsForm"
                                        (ngSubmit)="submitDetails() ">
                                        <div class="row ">
                                            <p class="form-des-text"><span>Step 1/2</span> Add few basic details about
                                                yourself.</p>
                                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 ">
                                                <div class="title-right-sec new-input-file-wrap">
                                                    <div *ngIf="!profilePic"
                                                        class="add-pic add-picture-section  add-pic-left"><img
                                                            src="assets/images/add-picture-light.svg"
                                                            alt="add-pic" />Profile Picture
                                                        <input type="file" title="" id="logoUpload"
                                                            (change)="uploadProfilePic($event)"
                                                            class="custom-input-file" formControlName="profileImage"
                                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                                                    </div>
                                                    <div *ngIf="profilePic"
                                                        class="add-pic add-picture-section border-0 add-pic-left"><img
                                                            [src]="profilePic" alt="add-pic" class="uploaded-img" />
                                                        <input type="hidden" title="" id="logoUpload"
                                                            (change)="uploadProfilePic($event)"
                                                            class="custom-input-file" formControlName="profileImage"
                                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                                                    </div>
                                                    <span class="support-file">(jpeg, png, and jpg only<br />
                                                        upto 10 mb)</span>

                                                </div>

                                                <ng-container
                                                    *ngIf="profileImage?.invalid && (profileImage?.dirty || profileImage?.touched) ">
                                                    <span class="invalid-input-text"
                                                        *ngIf="profileImage?.errors?.required">Profile picture is
                                                        required</span>
                                                </ng-container>

                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)? 'invalid-input': '' ">
                                                    <input type="text" class="form-control add-member-input"
                                                        id="floatingInput" placeholder="Full Name"
                                                        pattern="^[a-zA-Z -]+$" formControlName="fullName"
                                                        maxlength="30 ">
                                                    <label for="floatingInput ">Full Name</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched) ">
                                                    <span class="invalid-input-text"
                                                        *ngIf="fullName?.errors?.required">Full Name is required</span>
                                                </ng-container>
                                                <ng-container *ngIf="fullName?.invalid && fullName?.errors.pattern ">
                                                    <span class="invalid-input-text">Only alphabetical characters are
                                                        allowed</span>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched)? 'invalid-input': '' ">
                                                    <input type="text"
                                                        class="form-control add-member-input contact-num-input"
                                                        formControlName="contactNumber" maxlength="10"
                                                        (focusin)="showCountryCode=true " (focusout)="contactNumber?.errors?.required?showCountryCode=false: contactNumber?.errors?.minlength?showCountryCode=false:
                                            ''" id="floatingPassword" placeholder="Contact Number" appNumbersonly>
                                                    <label for="floatingPassword">Contact
                                                        Number</label>
                                                    <p class="std-code" *ngIf="showCountryCode">+1</p>
                                                </div>
                                                <ng-container
                                                    *ngIf="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="contactNumber?.errors?.required ">Contact Number is
                                                        required</span>
                                                    <span class="invalid-input-text"
                                                        *ngIf="contactNumber?.errors?.minlength">Contact Number should
                                                        not be less than 10 digits
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="row ">
                                            <div class="col-md-12 ">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)? 'invalid-input': '' ">
                                                    <input type="text" class="form-control add-member-input"
                                                        formControlName="emailId" id="floatingInput" maxlength="50"
                                                        placeholder="Email ID">
                                                    <label for="floatingInput">Email ID</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="emailId?.errors?.required ">Email is required</span>
                                                    <span class="invalid-input-text "
                                                        *ngIf="emailId?.errors?.email ">Email is not valid</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="deliverAddress?.invalid && (deliverAddress?.dirty || deliverAddress?.touched)? 'invalid-input': '' ">
                                                    <input type="text"
                                                        class="form-control add-member-input address-input"
                                                        id="floatingInput " formControlName="deliverAddress"
                                                        maxlength="100 " placeholder="Deliver Address ">
                                                    <label for="floatingInput ">Delivery Address</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="deliverAddress?.invalid && (deliverAddress?.dirty || deliverAddress?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="deliverAddress?.errors?.required ">Delivery Address is
                                                        required</span>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)? 'invalid-input': '' ">
                                                    <input type="text " class="form-control add-member-input "
                                                        id="floatingPassword " maxlength="10 " formControlName="zipCode"
                                                        placeholder="Zip Code " appNumbersonly>
                                                    <label for="floatingPassword ">Zip Code</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="zipCode?.errors?.required ">Zip Code is required</span>
                                                    <span class="invalid-input-text "
                                                        *ngIf="zipCode?.errors?.minlength ">Zip Code
                                                        should not be less than 5 digits</span>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="row ">
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">

                                                <div class="signin-state">
                                                    <div class="form-floating state-input add-member-floating "
                                                        [ngClass]="state?.invalid && (state?.dirty || state?.touched)? 'invalid-input': '' ">
                                                        <select
                                                            class="form-select add-member-input add-member-state-input state-city-select "
                                                            id="state " name="state "
                                                            aria-label="Floating label select example " formControlName="state
                                            " placeholder="State " (change)="getCity($event);selectedState=true "
                                                            formControlName="state">
                                                            <option *ngFor="let state of states " [value]="state.id "
                                                                [hidden]="!state?.name ">
                                                                {{state.name}}
                                                            </option>

                                                        </select>
                                                        <label for="floatingSelect " class="signin-state-label"
                                                            [ngClass]="selectedState? 'removeMar': 'state-label' ">State</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="state?.invalid && (state?.dirty || state?.touched) ">
                                                        <span class="invalid-input-text "
                                                            *ngIf="state?.errors?.required ">State is required</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">

                                                <div class="signin-state">
                                                    <div class="form-floating state-input add-member-floating "
                                                        [ngClass]="city?.invalid && (city?.dirty || city?.touched)? 'invalid-input': '' ">
                                                        <select
                                                            class="form-select add-member-input add-member-state-input state-city-select "
                                                            id="city " name="city "
                                                            aria-label="Floating label city example "
                                                            formControlName="city" placeholder="City
                                            " forControlName="city " (change)="selectedCity=true ">
                                                            <option *ngFor="let city of cities " [value]="city.id ">
                                                                {{city.name}}

                                                            </option>
                                                        </select>
                                                        <label for="floatingSelect " class="signin-state-label"
                                                            [ngClass]="selectedCity? 'removeMar': 'state-label' ">City</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="city?.invalid && (city?.dirty || city?.touched) ">
                                                        <span class="invalid-input-text "
                                                            *ngIf="city?.errors?.required ">City is required</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row payment-btn-row ">

                                            <div class="col-xl-6 col-lg-6 col-12 ms-auto continue-btn-col ">
                                                <button type="submit"
                                                    class="btn btn-primary btn-primary-continue ">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div [hidden]="isDetailShow || isPaymentSuccess "
                                    class="about-memorial-section add-details-section ">
                                    <form class="add-member-form " [formGroup]="paymentForm "
                                        (ngSubmit)="submitPayment() ">
                                        <p class="form-des-text "><span>Step 2/2</span> Payment Information</p>
                                        <div class="memory-code-order mb-1 with-price">
                                            <p>Your Order</p>
                                            <div class="memory-code-order-data memory-code-block">
                                                <p class="w-100"><span class="para-color-text ">1 &nbsp; &nbsp; x &nbsp;
                                                        &nbsp;
                                                        Welcome
                                                        Kit</span> <span class="para-memory-code-text">(QR Memory Code +
                                                        QR Memory Code Metal Plate)</span></p>
                                            </div>
                                            <span class="price-text ">195 USD</span>
                                        </div>
                                        <div class="memory-code-text">You will have an immediate deduction of $195 and your annual membership fee will be charged once your page is activated.
                                        </div>
                                        <div class="memory-code-annully-sec">
                                            <div class="code-annully-sec">
                                                <div class="checkbox-input-member">
                                                    <div class="form-check form-check-inline ">
                                                        <input class="form-check-input " type="radio" id="inlineRadio3 "
                                                            name="inlineRadio" [checked]="planType === 1"
                                                            (click)="getValue(1)">
                                                        <label class="form-check-label " for="inlineRadio3 ">$14.99
                                                            Anually</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="code-annully-sec">
                                                <div class="checkbox-input-member">
                                                    <div class="form-check form-check-inline ">
                                                        <input class="form-check-input " type="radio" id="inlineRadio4 "
                                                            name="inlineRadio" [checked]="planType === 2"
                                                            (click)="getValue(2)">
                                                        <label class="form-check-label " for="inlineRadio4 ">$24.99
                                                            Anually</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="para-color-text bottom-margin ">Make Payment</span>
                                        <div class="row ">
                                            <div class="col-md-12 ">
                                                <div class="checkbox-input-member">
                                                    <div
                                                        class="form-check form-check-inline checkbox-input-margin checkbox-input-sec-bt">
                                                        <input class="form-check-input " type="radio"
                                                            formControlName="cardType" id="inlineRadio1 "
                                                            value="Credit">
                                                        <label class="form-check-label " for="inlineRadio1 ">Credit
                                                            Card</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            formControlName="cardType" id="inlineRadio2" value="Debit">
                                                        <label class="form-check-label" for="inlineRadio2">Debit
                                                            Card</label>
                                                    </div>
                                                </div>
                                                <ng-container
                                                    *ngIf="cardType?.invalid && (cardType?.dirty || cardType?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="cardType?.errors?.required ">Card Type is
                                                        required</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-xl-6 col-md-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched)? 'invalid-input': '' ">
                                                    <input type="text " class="form-control add-member-input "
                                                        id="floatingInput " formControlName="cardHolderName"
                                                        maxlength="50">
                                                    <label for="floatingInput ">Name On Card</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="cardHolderName?.errors?.required ">Card Name is
                                                        required</span>
                                                    <span class="invalid-input-text "
                                                        *ngIf="cardHolderName?.errors?.pattern ">Card Name will contain
                                                        characters only</span>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="cardNumberMSG || (submitted && !hideCardNumber)? 'invalid-input': '' ">
                                                    <div id="cardNumber-element" class="form-control add-member-input ">
                                                    </div>
                                                    <label for="floatingPassword" class="stripe-label ">Card
                                                        Number</label>
                                                </div>

                                                <span class="invalid-input-text "
                                                    *ngIf="cardNumberMSG || (submitted && !hideCardNumber) ">
                                                    {{cardNumberMSG ? cardNumberMSG :'Please enter card number'}}</span>
                                            </div>
                                        </div>
                                        <div class="row expires-input margin-bottom-0 ">
                                            <div class="col-xl-6 col-md-6 col-12 payment-card-input ">
                                                <div class="form-floating add-member-floating form-control-birth "
                                                    [ngClass]="expiryMSG || (submitted && !hideCardExpiry )? 'invalid-input': '' ">
                                                    <div id="cardExpiry-element" class="form-control "></div>
                                                    <label for="birthDate " class="stripe-label ">Expiry
                                                        Date</label>
                                                </div>
                                                <span class="invalid-input-text "
                                                    *ngIf="expiryMSG || (submitted && !hideCardExpiry ) "> {{expiryMSG ?
                                                    expiryMSG :'Please enter expiry date'}}</span>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 ">
                                                <div class="form-floating add-member-floating ccv-m-0 "
                                                    [ngClass]="(submitted && !hideCardCvv)|| cvvMSG? 'invalid-input': '' ">
                                                    <div id="cardCvv-element" class="form-control "></div>
                                                    <label for="floatingPassword " class="stripe-label ">CVV</label>
                                                </div>
                                                <span class="invalid-input-text "
                                                    *ngIf="(submitted && !hideCardCvv)|| cvvMSG ">{{cvvMSG ? cvvMSG
                                                    :'Please enter cvv'}}</span>
                                            </div>
                                        </div>
                                        <div class="row pay-btn-row ">
                                            <div class="col-xl-6 col-md-6 col-12 payment-order-btn ">
                                                <button type="button" class="btn btn-outline-primary d-none"
                                                    (click)="goBack()">Back</button>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 payment-order-btn">
                                                <button type="submit" class="btn btn-primary">Pay Now ({{ planAmount }} USD)</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="isPaymentSuccess "
                                    class="about-memorial-section add-details-section pur-success-section ">
                                    <form class="add-member-form purchase-success-form" [formGroup]="paymentForm"
                                        (ngSubmit)="submitPayment() ">
                                        <div class="row ">
                                            <div class="col-12 purchase-success-col ">
                                                <span><img src="assets/images/purchase-success-btn.svg "
                                                        alt="purchase-success-icon " class="pur-success-log " /></span>
                                                <h2 class="success-title ">Payment successful!</h2>
                                                <p>Thank You! We have received your order. You’ll receive a confirmation
                                                    email with your order information</p>
                                                <div class="purchase-success-btn ">
                                                    <button type="button" class="btn btn-primary "
                                                        (click)="inviteCoOwners()">Go
                                                        To
                                                        Home</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div class="footer-img-heart ">
                                    <img src="assets/images/footer-heart-purchase.svg " alt="footer-heart "
                                        class="img-fluid " />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
