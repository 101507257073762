// Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsModalService,BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AppRoutingModule } from './app-routing.module';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { DragDropModule } from '@angular/cdk/drag-drop';
// Servies
import { InterceptorService } from './common/services/interceptor.service';
import { DatePipe } from '@angular/common';
import { DragDropDirective } from './common/directives/drag-drop.directive';
import { NumbersonlyDirective } from './common/directives/numbersonly.directive';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/component/header/header.component';
import { FooterComponent } from './common/component/footer/footer.component';
import { LandingPageComponent } from './public/component/landing-page/landing-page.component';
import { SearchComponent } from './common/modal/search/search.component';
import { SearchResultsComponent } from './common/component/search-results/search-results.component';
import { SigninComponent } from './public/component/signin/signin.component';
import { CreateAccountComponent } from './public/component/create-account/create-account.component';
import { ForgotPasswordComponent } from './public/component/forgot-password/forgot-password.component';
import { MemoryCodeRequiredComponent } from './public/component/memory-code-required/memory-code-required.component';
import { ViewInfoComponent } from './common/component/view-info/view-info.component';
import { LoaderComponent } from './common/component/loader/loader.component';
import { ResetPasswordComponent } from './public/component/reset-password/reset-password.component';
import { HomeComponent } from './private/home/home.component';
import { EditProfileComponent } from './common/modal/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './common/modal/change-password/change-password.component';
import { ProfilePicturesComponent } from './common/component/profile-pictures/profile-pictures.component';
import { BasicDetailsComponent } from './private/basic-details/basic-details.component';
import { CareerHobbiesComponent } from './private/career-hobbies/career-hobbies.component';
import { FamilyMemoriesComponent } from './private/family-memories/family-memories.component';
import { AddPicturesVideosComponent } from './private/add-pictures-videos/add-pictures-videos.component';
import { UpgradeAccountComponent } from './common/modal/upgrade-account/upgrade-account.component';
import { CongratulationsComponent } from './private/congratulations/congratulations.component';
import { PaymentPurchaseMemoryCodeComponent } from './public/component/payment-purchase-memory-code/payment-purchase-memory-code.component';
import { AboutMemorialComponent } from './private/about-memorial/about-memorial.component';
import { MemberListComponent } from './private/member-list/member-list.component';
import { PrivacyPolicyComponent } from './public/component/privacy-policy/privacy-policy.component';
import { TermsUseComponent } from './public/component/terms-use/terms-use.component';
import { ContactUsComponent } from './public/component/contact-us/contact-us.component';
import { AboutUsComponent } from './public/component/about-us/about-us.component';
import { ContentManagementComponent } from './common/component/content-management/content-management.component';
import { ScanQrComponent } from './public/scan-qr/scan-qr.component';
import { PageNotFoundComponent } from './common/component/page-not-found/page-not-found.component';
import { AddGuestBookComponent } from './private/add-guest-book/add-guest-book.component';
import { AddGuestMessageComponent } from './private/add-guest-message/add-guest-message.component';
import { GuestbookRequestComponent } from './private/guestbook-request/guestbook-request.component';
import { GuestbookGalleryComponent } from './private/guestbook-gallery/guestbook-gallery.component';
import { ConfirmationPopupComponent } from './common/component/confirmation-popup/confirmation-popup.component';
import {NgxPrintModule} from 'ngx-print';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SelectGuestbookModalComponent } from './private/guestbook-gallery/select-guestbook-modal/select-guestbook-modal.component';
import { UnsubscribeComponent } from './public/component/unsubscribe/unsubscribe.component';
import { ExportSalesReportComponent } from './common/modal/export-sales-report/export-sales-report.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { UpgradePlansComponent } from './private/upgrade-plans/upgrade-plans.component';
import { SignUpComponent } from './public/component/sign-up/sign-up.component';
import { EmailVerifyComponent } from './public/component/email-verify/email-verify.component';
import { MarkAsReturnedComponent } from './common/modal/mark-as-returned/mark-as-returned.component';
import { CancelSubscriptionComponent } from './common/modal/cancel-subscription/cancel-subscription.component';
import { DowngradePlanComponent } from './common/modal/downgrade-plan/downgrade-plan.component';
import { DowngradeAccountComponent } from './common/modal/downgrade-account/downgrade-account.component';
import { MemoryCodePageComponent } from './private/memory-code-page/memory-code-page.component';
import { UpgradePlanComponent } from './common/modal/upgrade-plan/upgrade-plan.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NoSubscriptionComponent } from './common/modal/no-subscription/no-subscription.component';
import { LikeCommentComponent } from './common/component/like-comment/like-comment.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareObituaryComponent } from './common/modal/share-obituary/share-obituary.component';
import { MetaModule } from '@ngx-meta/core';
import { AddObituaryComponent } from './common/modal/add-obituary/add-obituary.component';
import { EditObituaryComponent } from './common/modal/edit-obituary/edit-obituary.component';
import { NoSubscriptionCancelledComponent } from './common/modal/no-subscription-cancelled/no-subscription-cancelled.component';
import { InviteCoOwnerComponent } from './public/component/invite-co-owner/invite-co-owner.component';
import { AddCoOwnerDetailsComponent } from './public/component/add-co-owner-details/add-co-owner-details.component';
import { ManageCoOwnersComponent } from './common/modal/manage-co-owners/manage-co-owners.component';
import { FaqComponent } from './public/component/faq/faq.component';
import { SendMessageUserComponent } from './private/send-message-user/send-message-user.component';
import { AddPictureVideosMemberComponent } from './common/modal/add-picture-videos-member/add-picture-videos-member.component';
import { EditInvitationComponent } from './common/modal/edit-invitation/edit-invitation.component';
import { CreateItineraryComponent } from './public/component/create-itinerary/create-itinerary.component';
import { ViewItineraryComponent } from './public/component/view-itinerary/view-itinerary.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { AgmCoreModule } from '@agm/core';
import { ConfirmItineraryComponent } from './common/modal/confirm-itinerary/confirm-itinerary.component';
import { SendMessageGuestComponent } from './private/send-message-guest/send-message-guest.component';
import { ManageCardComponent } from './common/modal/manage-card/manage-card.component';
import { DndModule } from 'ngx-drag-drop';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingPageComponent,
    SearchComponent,
    SearchResultsComponent,
    SigninComponent,
    CreateAccountComponent,
    ForgotPasswordComponent,
    MemoryCodeRequiredComponent,
    ViewInfoComponent,
    ResetPasswordComponent,
    LoaderComponent,
    HomeComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ProfilePicturesComponent,
    BasicDetailsComponent,
    CareerHobbiesComponent,
    FamilyMemoriesComponent,
    AddPicturesVideosComponent,
    UpgradeAccountComponent,
    ManageCardComponent,
    CongratulationsComponent,
    PaymentPurchaseMemoryCodeComponent,
    AboutMemorialComponent,
    DragDropDirective,
    NumbersonlyDirective,
    MemberListComponent,
    PrivacyPolicyComponent,
    TermsUseComponent,
    ContactUsComponent,
    AboutUsComponent,
    FaqComponent,
    ContentManagementComponent,
    ScanQrComponent,
    PageNotFoundComponent,
    AddGuestBookComponent,
    AddGuestMessageComponent,
    GuestbookRequestComponent,
    GuestbookGalleryComponent,
    SendMessageUserComponent,
    SendMessageGuestComponent,
    ConfirmationPopupComponent,
    SelectGuestbookModalComponent,
    UnsubscribeComponent,
    ExportSalesReportComponent,
    UpgradePlansComponent,
    SignUpComponent,
    EmailVerifyComponent,
    MarkAsReturnedComponent,
    CancelSubscriptionComponent,
    DowngradePlanComponent,
    DowngradeAccountComponent,
    MemoryCodePageComponent,
    UpgradePlanComponent,
    NoSubscriptionComponent,
    LikeCommentComponent,
    ShareObituaryComponent,
    AddObituaryComponent,
    EditObituaryComponent,
    EditInvitationComponent,
    ConfirmItineraryComponent,
    AddPictureVideosMemberComponent,
    NoSubscriptionCancelledComponent,
    InviteCoOwnerComponent,
    CreateItineraryComponent,
    ViewItineraryComponent,
    AddCoOwnerDetailsComponent,
    ManageCoOwnersComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPrintModule,
    InfiniteScrollModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBVSDBIW0UTRA8AnPn-EsUhXy2mccqdiRs',
    }),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgSelectModule,
    EditorModule,
    MetaModule.forRoot(),
    CarouselModule,
    ModalModule.forRoot(),
    ZXingScannerModule,
    NgQrScannerModule,
    MatDatepickerModule,
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    DragDropModule,
    NgxMaterialTimepickerModule,
    DndModule
  ],
  providers: [BsModalService, DatePipe, BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
  ],
  exports: [MatDatepickerModule,MatNativeDateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [SearchComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
