<div class="owner-modal-header">
    <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="onClose()"></button>
</div>

<div class="modal-body body-top-margin">
    <div class="select-modal-sec share-obt">
        <form [formGroup]="form">
            <div class="modal-titles">Edit Invitation Text</div>
            <div class="share-message mb-0">
                <div class="textarea-message p-0 position-relative">
                    <div class="mb-3"
                        [ngClass]="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched) ? 'invalid-input' : ''">
                        <textarea rows="3" id="exampleFormControlTextarea1" pattern="^\S(.*\S)?\s*$" required maxlength="500" class="form-control"
                            id="floatingTextarea" (input)="onTextareaChange()" formControlName="message"
                            placeholder=" "></textarea>
                    </div>
                    <div
                        *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)">
                        <div class="invalid-input-text-neg">Message is invalid</div>
                    </div>
                </div>
            </div>
            <div class="share-add-action">
                <button (click)="submitForm()" type="button" class="btn btn-primary btn-primary-update">Update</button>
            </div>
        </form>
    </div>
</div>
