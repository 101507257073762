import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public headers;
  public apiUrl;
  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.apiUrl = environment.apiUrl;
  }

  searchMemorial(payload) {
    const urlA = `member-search?type=${payload.type}&name=${payload.name}&dob=${payload.dob}`;
    const urlB = `&dod=${payload.dod}&state_id=${payload.state_id}&memory_code_no=${payload.memory_code_number}&page=${payload.page}`;
    return this.http.post(`${this.apiUrl}${urlA}${urlB}`, '',);
  }

  getViewInfo(id) {
    return this.http.post(`${this.apiUrl}show_member?id=${id}`, '');
  }
}
