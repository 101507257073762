import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public searchData: BehaviorSubject<any>;
  public userData: BehaviorSubject<any>;
  public changeBrandIcon: Subject<any>;
  public isLoggedIn: BehaviorSubject<any>;
  public addMember: BehaviorSubject<any>;
  public aboutMemorial: BehaviorSubject<any>;
  public hobbiesCareer: BehaviorSubject<any>;
  public familyMemories: BehaviorSubject<any>;
  public mediaApiCall: BehaviorSubject<any>;
  public guestRequestSharing: BehaviorSubject<any>;
  public memberGuestEmail: BehaviorSubject<any>;
  public deleteConfirmation: BehaviorSubject<any>;
  public updateMemoryStatus : BehaviorSubject<any>;
  public showCommentInput : BehaviorSubject<any>;






  constructor() {
    this.searchData = new BehaviorSubject('');
    this.changeBrandIcon = new Subject();
    this.isLoggedIn = new BehaviorSubject(false);
    const data: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(data);
    this.userData = new BehaviorSubject(userData);
    this.aboutMemorial = new BehaviorSubject('');
    this.addMember = new BehaviorSubject('');
    this.hobbiesCareer = new BehaviorSubject('');
    this.familyMemories = new BehaviorSubject('');
    this.mediaApiCall = new BehaviorSubject(false);
    this.guestRequestSharing = new BehaviorSubject(null);
    this.memberGuestEmail = new BehaviorSubject(null);
    this.deleteConfirmation = new BehaviorSubject({});
    this.updateMemoryStatus = new BehaviorSubject(null);
    this.showCommentInput = new BehaviorSubject(false);




  }
}
