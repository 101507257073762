import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from '../../services/utility.service';
import { CommonService } from '../../services/common.service';
import { Subject } from 'rxjs';
import { IResponse } from '../../interfaces/response';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { MemberService } from '../../services/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DndDropEvent } from 'ngx-drag-drop';
import { AddGuestbookService } from '../../services/add-guestbook.service';

@Component({
  selector: 'app-add-picture-videos-member',
  templateUrl: './add-picture-videos-member.component.html',
  styleUrls: ['./add-picture-videos-member.component.scss']
})
export class AddPictureVideosMemberComponent implements OnInit,AfterViewInit,OnDestroy {
  public videosArr = [];
  public destroy$ = new Subject();
  public addMember = {};
  public aboutMemorial = {};
  public familyMemories = {};
  public careerHobbies = {};
  public userData;
  public pictureArr = [{path:''}];
  public pictureCount = 0;
  public update = false;
  public prayerCard = '';
  public previewImage: string;
  public dayCount = 0;
  public planName;
  public videoCount = 0;
  type: any;
  showType = 'pdf';
  uploadLimit: any;
  public removeMemory :any;
  public removeMessage :any;
  public removeMemoryVideo : any;
  standardPlan = true;
  planCancelledOrNotActivated = false;
  public className = 'signin-modal';
  memberId: string;

  removePic;
  removeVid;

  constructor( public router: Router, 
    public utilityService: UtilityService, public modalRef: BsModalRef,
    public toastr: ToastrService, public commonService: CommonService, public memberService: MemberService, 
    public loaderService: NgxSpinnerService, public addGuestbookService: AddGuestbookService) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
    }
    if (localStorage.getItem('update')) {
      this.update = true;
    }
    this.aboutMemorialService();
    this.familyMemoriesService();
    this.hobbiesCareerService();
    this.userDataService();
    this.previewImage = '';
    if (localStorage.getItem('addMember')) {
      const addMember = localStorage.getItem('addMember');
      this.planName = JSON.parse(addMember)?.plan;
      this.dayCount = JSON.parse(addMember)?.day_count;
      const pictures = JSON.parse(addMember)?.member_gallery;
      const videos = JSON.parse(addMember)?.member_gallery;
      this.pictureArrMember(pictures);
      this.videosArrMember(videos);
      this.prayerCard = localStorage.getItem('prayerCard') ? localStorage.getItem('prayerCard') : JSON.parse(addMember)?.prayer_card;
      if(this.prayerCard){
        if(this.prayerCard.includes('pdf')){
          this.showType = 'pdf';
        }else{
          this.showType = 'images';
        }
      }
    }

    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
      if(this.userData?.user?.plan_type === 1){
        this.standardPlan = true;
      } else if(this.userData?.user?.plan_type === MagicNumbers.two){
        this.standardPlan = false;
      }
      } else {
      }
    });
  }

  getMemberData(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const pictureVideoData = res.data;
        const pictures = pictureVideoData?.member_gallery;
      const videos = pictureVideoData?.member_gallery;
      this.pictureArrMember(pictures);
      this.videosArrMember(videos);

      this.planName = pictureVideoData?.plan;
      this.dayCount = pictureVideoData?.day_count;

        this.prayerCard = pictureVideoData.prayer_card ? pictureVideoData.prayer_card : '';
        if(this.prayerCard){
          if(this.prayerCard.includes('pdf')){
            this.showType = 'pdf';
          }else{
            this.showType = 'images';
          }
        }
      }
    });
  }

  ngAfterViewInit(){
    this.commonService.mediaApiCall.subscribe((res)=>{
      this.getAllowMediaLimit();
    });
  }

  // To count number of pictures uploaded
  pictureArrMember(pictures: any) {
    if (pictures) {
        // Filter pictures of type 0
        const arr = pictures.filter(res => res.type == 0);

        // Use a Set to keep track of unique paths
        const uniquePaths = new Set<string>();

        // Filter out pictures with duplicate paths
        const uniqueArr = arr.filter(picture => {
            if (uniquePaths.has(picture.path)) {
                return false; // Skip this picture as its path is already added
            } else {
                uniquePaths.add(picture.path);
                return true; // Include this picture
            }
        });

        // Replace the content of pictureArr with unique pictures
        this.pictureArr.splice(0, this.pictureArr.length, ...uniqueArr);

        // Reset and calculate the new picture count
        this.pictureCount = 0;
        this.pictureArr.forEach(element => {
            if (element.path !== '') {
                this.pictureCount = this.pictureCount + 1;
            }
        });
    }
}

  // To count number of videos uploaded
  videosArrMember(videos) {
    if (videos) {
        // Filter videos of type 1
        const arr = videos.filter(res => res.type == 1);

        // Use a Set to keep track of unique paths
        const uniquePaths = new Set<string>();

        // Filter out videos with duplicate paths
        const uniqueArr = arr.filter(video => {
            if (uniquePaths.has(video.path)) {
                return false; // Skip this video as its path is already added
            } else {
                uniquePaths.add(video.path);
                return true; // Include this video
            }
        });

        // Replace the content of videosArr with unique videos
        this.videosArr.splice(0, this.videosArr.length, ...uniqueArr);

        // Reset and calculate the new video count
        this.videoCount = 0;
        this.videosArr.forEach(element => {
            if (element !== '') {
                this.videoCount = this.videoCount + 1;
            }
        });
    }
}

  // To listen the add member data
  addMemberService(){
    this.commonService.addMember.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.addMember = res;
      }
    });
  }

   // To listen the about memorial data
  aboutMemorialService(){
    this.commonService.aboutMemorial.pipe(takeUntil(this.destroy$)).subscribe(res => {
     if (res) {
       this.aboutMemorial = res;
    }
    });
  }
  
  // To listen the hobbies career data
  hobbiesCareerService(){
    this.commonService.hobbiesCareer.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.careerHobbies = res;
      }
    });
  }

  // To listen the family memories data
  familyMemoriesService(){
    this.commonService.familyMemories.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.familyMemories = res;
      }
    });
  }

  // To listen the user data
  userDataService(){
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
       this.getAllowMediaLimit();
      }
    });
  }
 
  fileSelected(event, type?) {
    const fd = new FormData();
    fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
    const uploadType = event.target.files[0].type.split('/')[0];
      if (uploadType !== type) {
        this.toastr.error(`Please upload ${type}`);
      } else if (event.target.files[0].size >= MagicNumbers.MbImageSize && type === 'image') {
        this.toastr.error(`Please upload less than 10 mb ${uploadType}`);
      }else if (event.target.files[0].size >= MagicNumbers.MbSize && type === 'video') {
        this.toastr.error(`Please upload less than 20 mb ${uploadType}`);
      } else {
        this.loaderService.show();
          this.utilityService[uploadType === 'image' ? 'uploadImage' : 'uploadVideo'](fd, uploadType === 'image' ? 'other' : 'video').subscribe((res: IResponse) => {
            if (res && !res.errors) {
              this.toastr.success(`${uploadType === 'image' ? 'Image' : 'Video'} uploaded successfully`);
              if (uploadType === 'image') {
                const index = this.pictureArr.findIndex((e) => e.path === '');
                if (index === -1) {
                  this.pictureArr.push({path : res.data.url});
                } else {
                  this.pictureArr[index] = {path : res.data.url};
                }
                this.pictureCount = 0;
                this.pictureArr.forEach((e) => {
                  if (e.path !== '') {
                    this.pictureCount = this.pictureCount + 1;
                  }
                });
              } else {
                const index = this.videosArr.findIndex((e) => e === '');
                if (index === -1) {
                  this.videosArr.push({path : res.data.url});
                } else {
                  this.videosArr[index] = {path : res.data.url};
                }
                this.videoCount = 0;
                this.videosArr.forEach((e) => {
                  if (e !== '') {
                    this.videoCount = this.videoCount + 1;
                  }
                });
            }
            }
            event.target.value = '';
            this.loaderService.hide();
          }); 
      }
  }

  onNext() {
    const checkAll = this.pictureArr.every((e) => e.path === '');
    const pictures = [];
    const videos = [];
    if (!checkAll) {
      this.pictureArr.forEach((e) => {
        if (e.path !== '') {
          pictures.push(e.path);
        }
      });
      this.videosArr.forEach((e) => {
        if (e !== '') {
          videos.push(e.path);
        }
      });
      const addMember = localStorage.getItem('addMember');
      const mediaData=
      {
        other_picture: JSON.stringify(pictures), 
        video: JSON.stringify(videos),
        prayer_card: this.prayerCard
      };
      if(this.update){
        this.memberService.updateMember5(this.memberId, MagicNumbers.five, mediaData).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success('Updated successfully');      
            this.modalRef.hide();
            window.location.reload();
            this.router.navigate(['/view-information/',res.data.id]);
          }
        });
      }
    } else {
      this.toastr.error('Please upload atleast one picture');
    }
  }

  updatedSuccessfull(update,res){
    if (update) {
      this.toastr.success('Updated successfully');      
      localStorage.removeItem('update');
      localStorage.removeItem('addMember');
      localStorage.removeItem('showType');
      localStorage.removeItem('prayerCard');
      this.router.navigate(['/view-information/',res.data.id]);
    } else {
      this.router.navigate(['/congratulations'], { queryParams: { data: JSON.stringify(res.data) } });
    }
  }
  /**
   * To delete the image
  */
  deleteImage(index: number) {
    if(this.pictureArr.length===1){
      this.pictureArr.splice(index, 1,{path:''});
    }else{
      this.pictureArr.splice(index, 1);
    }
    if (this.userData?.user?.get_plan?.length !== MagicNumbers.two) {
    }
    this.pictureCount = Number(this.pictureCount) - 1;
    const addMember = localStorage.getItem('addMember');
    let addData = JSON.parse(addMember);
    const picture = this.pictureArr.map(res=> res.path);
    addData = { ...addData, ...{ other_picture: JSON.stringify(picture) } };
    localStorage.setItem('addMember', JSON.stringify(addData));
    this.removeMemory =-1;
  }
  /**
   * To delete the video
  */
  deleteVideo(i: number) {
    if(this.videosArr.length===1){
      this.videosArr = [];
    }else{
      this.videosArr.splice(i, 1);
    }
    this.videoCount = Number(this.videoCount) - 1;
    const addMember = localStorage.getItem('addMember');
    let addData = JSON.parse(addMember);
    const videos = this.videosArr.map(res=> res.path);
    addData = { ...addData, ...{ video: JSON.stringify(videos) } };
    localStorage.setItem('addMember', JSON.stringify(addData));
    this.removeMemoryVideo=-1;
  }

          /**
   * Method to call get media list
  */
  getAllowMediaLimit(){
    const payloads={
      member_id: this.memberId,
      type : 'member'
    };
    this.memberService.getAllowMediaLimitMember(payloads).subscribe((res: any) => {
      if (res) {
        this.uploadLimit = res.data;
      }
    });
  }
 /**
   * To open the vide in new tab
   * @param doc is response of list
   */
 showDoc(doc: string) {
  window.open(doc, '_blank');
}

showVideo(video: string) {
  window.open(video, '_blank');
}


  trackByPicture(index: number, fruit) {
    return fruit;
  }

  trackByVideo(index: number, fruit) {
    return fruit;
  }

  onDraggedVideo(index: number, fruit, videosArr) {
    const removeIndex = videosArr.indexOf(fruit);
    videosArr.splice(removeIndex, 1);
  }

  onDraggedPicture(index: number, fruit, pictureArr){
    const removeIndex = pictureArr.indexOf(fruit);
    pictureArr.splice(removeIndex, 1);
  }

  onDropPicture(event: DndDropEvent, pictureArr: any[]) {    
    // Get the dragged item
    const draggedItem = event.data;
  
    // Find and remove the dragged item from its previous position
    // const removeIndex = pictureArr.indexOf(draggedItem);
      pictureArr.splice(this.removePic, 1);
    // Determine the new index to insert the item
    let newIndex = event.index;
    if (typeof newIndex === 'undefined') {
      newIndex = pictureArr.length;
    }
  
    // Insert the item at the new position
    if(event.index > this.removePic){
        pictureArr.splice(newIndex-1, 0, draggedItem);
      } else{
        pictureArr.splice(newIndex, 0, draggedItem);  
      }
    this.updateOrder(pictureArr, 0);

  }

 

  onDragStartPicture(index:number) {
    this.removePic  = index;    
  }

  onDragStartVideo(index:number) {
    this.removeVid  = index;
  }

  onDropVideo(event: DndDropEvent, videosArr: any[]) {    
    const draggedItem = event.data;
  
      videosArr.splice(this.removeVid, 1);
    
    let newIndex = event.index;
    if (typeof newIndex === 'undefined') {
      newIndex = videosArr.length;
    }
    if(event.index > this.removeVid){
      videosArr.splice(newIndex-1, 0, draggedItem);  
      } else{
        videosArr.splice(newIndex, 0, draggedItem);  
      }
    this.updateOrder(videosArr, 1);
  }

  updateOrder(memoriesArr, status) {
    let mergedArray;
    if(status == 1){
      mergedArray = [...memoriesArr, ...this.pictureArr];
      var orderData = memoriesArr.map((item, index) => ({
        id: item.id,
        order: index
      }));
      const seenIds = new Set();
      this.videosArr = this.videosArr.filter(item => {
        if (seenIds.has(item.path)) {
          return false; // If the ID is already seen, remove the item
        }
        seenIds.add(item.path);
        return true; // Keep the item if the ID hasn't been seen yet
      });
    } else if(status == 0) {
      mergedArray = [...memoriesArr, ...this.videosArr];
      var orderData = mergedArray.map((item, index) => ({
        id: item.id,
        order: index
      }));
      const seenIds = new Set();
      this.pictureArr = this.pictureArr.filter(item => {
        if (seenIds.has(item.path)) {
          return false; // If the ID is already seen, remove the item
        }
        seenIds.add(item.path);
        return true; // Keep the item if the ID hasn't been seen yet
      });
      
    }
      
    // Use a Set to track seen IDs and filter out duplicates (keep the first occurrence)
    const seenIds = new Set();
    orderData = orderData.filter(item => {
      if (seenIds.has(item.id)) {
        return false; // If the ID is already seen, remove the item
      }
      seenIds.add(item.id);
      return true; // Keep the item if the ID hasn't been seen yet
    });
    this.loaderService.show();
    this.addGuestbookService.dragAndDropGuest({ order_data: orderData }).subscribe(response => {
      if (response) {
        this.loaderService.hide();
      } else {
        this.loaderService.hide();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
