<div class="signin-modal-content change-password-content upgrade-modal-content upgrade-modal-content2">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body signin-body change-password-body upgrade-plan2-body payment-success-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="signin-container">
                <div class="current-plan"><span>Current Plan: $14.99 annually</span></div>
                <ng-container *ngIf="!billingInfo && !isPaymentSuccess">
                    <div class="row upgrade-plan-row">
                        <div class="col-md-4 col-12 payment-col">
                            <div class="payment-text payment-text-upgrade">
                                <h1>$24.99</h1>
                                <span class="one-time-text">Annually</span>
                            </div>

                        </div>
                        <div class="col-12 col-md-8">
                            <div class="upgrade-text upgrade-text-mob">
                                <h2>Upgrade Your Plan</h2>
                                <p class="upgrade-text-para">Here’s what you’ll get on the upgraded plan for only $10.00
                                    Extra</p>
                                <div class="plan-size plan-size-text">
                                    <h5><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">Unlimited
                                        Picture & Video Memories
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-primary upgrade-btn" (click)="upgrade()">Upgrade</button>
                    <button *ngIf="planCancelledOrNotActivated" type="button"
                        class="btn btn-link upgrade-link-btn cancel-btn-text" (click)="cancelSubs()">Cancel My
                        Subscription</button>

                </ng-container>
                <ng-container *ngIf="billingInfo && !isPaymentSuccess">
                    <div class="row upgrade-plan-row">
                        <div class="col-12">
                            <form class="add-member-form" [formGroup]="paymentForm" (ngSubmit)="submitPayment()">
                                <div class="memory-code-order">
                                    <p>Upgraded Plan</p>
                                    <div class="memory-code-order-data">
                                        <p><span class="para-color-text">$24.99</span> Annually</p>
                                        <span class="price-text">$24.99</span>
                                    </div>
                                </div>
                                <span class="para-color-text upgrade-plan2-heading">Billing Information</span>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="checkbox-input-member">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input " type="radio" id="inlineRadio1"
                                                    value="Credit" formControlName="cardType">
                                                <label class="form-check-label" for="inlineRadio1">Credit
                                                    Card</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input " type="radio" id="inlineRadio2"
                                                    value="Debit" formControlName="cardType">
                                                <label class="form-check-label" for="inlineRadio2">Debit
                                                    Card</label>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="cardType?.invalid && (cardType?.dirty || cardType?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardType?.errors?.required">Card
                                                Type is
                                                required</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating"
                                            [ngClass]="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                placeholder="Name On Card" formControlName="cardHolderName"
                                                maxlength="20">
                                            <label for="floatingInput">Name On Card</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cardHolderName?.invalid && (cardHolderName?.dirty || cardHolderName?.touched)">
                                            <span class="invalid-input-text"
                                                *ngIf="cardHolderName?.errors?.required">Card Name is
                                                required</span>
                                            <span class="invalid-input-text"
                                                *ngIf="cardHolderName?.errors?.pattern">Card Name will contain
                                                characters only</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating"
                                            [ngClass]="cardNumber?.invalid && (cardNumber?.dirty || cardNumber?.touched)?'invalid-input':''">
                                            <input id="credit-card-input" type="text" maxlength="19"
                                                class="form-control" formControlName="cardNumber"
                                                (keydown)="inputOnlyNumber($event)" />
                                            <label for="floatingPassword" class="stripe-label">Card
                                                Number</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cardNumber?.invalid && (cardNumber?.dirty || cardNumber?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardNumber?.errors?.required">Card
                                                Number is
                                                required</span>

                                            <span class="invalid-input-text" *ngIf="cardNumber?.errors?.minlength">Card
                                                Number should not be less than 16 digits
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row expires-input margin-bottom-0">
                                    <div class="col-xl-6 col-md-6 col-12 payment-card-input">
                                        <div class="form-floating add-member-floating form-control-birth"
                                            [ngClass]="cardExpiry?.invalid && (cardExpiry?.dirty || cardExpiry?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                placeholder="Name On Card" formControlName="cardExpiry"
                                                (onShown)="onOpenCalendar($event)" readonly bsDatepicker
                                                (click)="myFunction()" placement="bottom"
                                                [bsConfig]="{showWeekNumbers: false,dateInputFormat: 'MM/YY'}"
                                                [minDate]="today">
                                            <label for="birthDate" class="stripe-label">Expiry
                                                Date</label>
                                        </div>
                                        <ng-container
                                            *ngIf="cardExpiry?.invalid && (cardExpiry?.dirty || cardExpiry?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardExpiry?.errors?.required">
                                                Expiry Date is
                                                required</span>
                                        </ng-container>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-floating add-member-floating ccv-m-0"
                                            [ngClass]="cardCvv?.invalid && (cardCvv?.dirty || cardCvv?.touched)?'invalid-input':''">
                                            <input type="text" class="form-control add-member-input" id="floatingInput"
                                                appNumbersonly maxlength="4" placeholder="Name On Card"
                                                formControlName="cardCvv">
                                            <label for="floatingPassword" class="stripe-label">CVV</label>
                                        </div>
                                        <ng-container *ngIf="cardCvv?.invalid && (cardCvv?.dirty || cardCvv?.touched)">
                                            <span class="invalid-input-text" *ngIf="cardCvv?.errors?.required">Card CVV
                                                is
                                                required</span>
                                            <span class="invalid-input-text" *ngIf="cardCvv?.errors?.minlength">Card CVV
                                                is
                                                Should not be less than 3 digits</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row pay-btn-row">
                                    <div class="col-xl-12 col-md-12 col-12 payment-order-btn">
                                        <button type="submit" class="btn btn-primary">Pay Now (24.99 USD)</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
                <div class="about-memorial-section add-details-section pur-success-section payment-success-section"
                    *ngIf="isPaymentSuccess">
                    <form class="add-member-form purchase-success-form payment-success-form">
                        <div class="row">
                            <div class="col-12 purchase-success-col payment-success-col">
                                <span><img src="assets/images/purchase-success-btn.svg" alt="purchase-success-icon"
                                        class="pur-success-log" /></span>
                                <h2 class="success-title">Payment successful!</h2>
                                <p class="payment-des">Thank You! We have received your order. You’ll receive a
                                    confirmation email with your order information</p>
                                <div class="purchase-success-btn">
                                    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">Continue
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>
