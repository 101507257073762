<section class="co-owner-section">
    <div class="skip-btn-wrap">
        <button (click)="back()" class="btn link-btn btn-border cancel-blue">Cancel</button>
    </div>
    <div class="container payment-section-container">
        <div class="row">
            <div class="col-12 col-lg-12 payment-form-col">
                <div class="container res-p-16">
                    <div class="add-member-container">
                        <div class="main-container main-container-purchase">
                            <div class=" purchase-memory-wrapper-right ">
                                <div class="purchase-memory">
                                    <img src="assets/images/header-infinity.svg" alt="header-infinity "
                                        class="header-img-infinity img-fluid " />
                                </div>
                                <div class="about-memorial-section purchase-memory-section">
                                    <h1 class="co-owner-title">Invite Sub-Owners for <br />this account</h1>
                                    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="add-member-form">
                                        <div formArrayName="users" class="add-member-main">
                                            <div *ngFor="let user of users.controls; let i=index" [formGroupName]="i"
                                                class="row co-owner-row">
                                                <div class="col-xl-4 col-md-4 col-sm-4 col-12 ">
                                                    <div class="form-floating add-member-floating">
                                                        <input type="text" class="form-control add-member-input"
                                                            id="floatingInput" placeholder="Full Name"
                                                            formControlName="name" maxlength="30">
                                                        <label for="floatingInput ">Full Name</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="user.get('name').invalid && (user.get('name').dirty || user.get('name').touched)">
                                                        <span class="invalid-input-text"
                                                            *ngIf="user.get('name').errors.required">Full Name is
                                                            required</span>
                                                        <span class="invalid-input-text"
                                                            *ngIf="user.get('name').errors.pattern">Only alphabetical
                                                            characters are allowed</span>
                                                        <span class="invalid-input-text"
                                                            *ngIf="user.get('name').errors.maxlength">Full Name cannot
                                                            exceed 30 characters</span>
                                                            <span class="invalid-input-text"
                                                            *ngIf="user.get('name').errors.minlength">Full Name required
                                                            minimum 2 characters</span>
                                                    </ng-container>
                                                </div>
                                                <div class="col-xl-4 col-md-4 col-sm-4 col-12 ">
                                                    <div class="form-floating add-member-floating">
                                                        <input type="email" class="form-control add-member-input"
                                                            id="floatingEmail" placeholder="Email ID"
                                                            formControlName="email">
                                                        <label for="floatingInput ">Email ID</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="user.get('email').invalid && (user.get('email').dirty || user.get('email').touched)">
                                                        <span class="invalid-input-text"
                                                            *ngIf="user.get('email').errors.required">Email ID is
                                                            required</span>
                                                        <span class="invalid-input-text"
                                                            *ngIf="user.get('email').errors.email">Enter a valid
                                                            email</span>
                                                            <span class="invalid-input-text" *ngIf="user.get('email').errors?.duplicate">Email ID is already used</span>
                                                    </ng-container>
                                                </div>
                                                <div class="col-xl-4 col-md-4 col-sm-4 col-12 ">
                                                    <div class="signin-state">
                                                        <div class="form-floating state-input add-member-floating ">
                                                            <select
                                                                class="form-select add-member-input add-member-state-input state-city-select "
                                                                id="account_type" formControlName="account_type"
                                                                aria-label="Floating label select example "
                                                                placeholder="Type" (change)="user.patchValue({ selectedState: true })">
                                                                <option value="" disabled>Select type</option>
                                                                <option [value]="2">Co-Owner</option>
                                                                <option [value]="3">Funeral Home</option>
                                                            </select>
                                                            <label for="floatingSelect " class="signin-state-label"
                                                                [ngClass]="user.get('selectedState').value ? 'removeMar': 'state-label' ">Type</label>
                                                        </div>
                                                        <ng-container
                                                            *ngIf="user.get('account_type').invalid && (user.get('account_type').dirty || user.get('account_type').touched)">
                                                            <span class="invalid-input-text"
                                                                *ngIf="user.get('account_type').errors.required">Type is
                                                                required</span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div *ngIf="users.controls.length > 1" class="delete-btn">
                                                    <button (click)="removeUser(i)" class="btn-no-border">
                                                        <img src="assets/images/icon-del.svg" alt="delete logo"
                                                            class="delete-logo" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="add-more-wrap">
                                                <button (click)="addUser()" class="btn-no-border add-more-btn" [disabled]="form.invalid || users.invalid">+ Add
                                                    More</button>
                                            </div>
                                        </div>
                                        <div class="row payment-btn-row ">
                                            <div class="col-xl-4 col-lg-4 col-12 ms-auto continue-btn-col">
                                                <button type="submit" class="btn btn-primary btn-primary-continue ">Send
                                                    Invite</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="purchase-memory">
                                    <img src="assets/images/footer-heart.svg" alt="footer-heart"
                                        class="modal-footer-img img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
