import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MagicNumbers } from '../app-magic-numbers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public year = new Date();
  currentRoute: string;
  constructor(public router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentRoute = this.router.url;
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
    }, MagicNumbers.thousand);   
  }


}
