import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements OnInit {
  @Input() data: any;
  constructor(public modalRef: BsModalRef,public commonService: CommonService,) { }

  ngOnInit(): void {
  }

  delete(){
  this.commonService.deleteConfirmation.next({status : true,value:this.data});
  }
}
