<!-- sign in start here -->
<!-- Button trigger modal -->
<!-- Modal -->
<div class="signin-modal-content">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>

    </div>

    <div class="modal-body signin-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title">Hi, Welcome Back</h1>
            <p class="signin-para">Please Sign In to <strong>EternallyMe</strong> with your original account details</p>
            <div class="signin-container">

                <form class="login-container" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
                    <div class="form-floating" [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                        <input type="email" class="form-control" id="emailId" formControlName="emailId" placeholder="name@example.com" >
                        <label for="emailId">Email ID</label>
                    </div>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</span>
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.email">Email is not valid</span>
                    </ng-container>
                    <div class="form-floating" [ngClass]="password?.invalid && (password?.dirty || password?.touched)?'invalid-input':''">
                        <input [type]="isShow?'text':'password'" class="form-control " id="password" placeholder="Password" formControlName="password" >
                        <label for="password">Password</label>
                        <span class="hide-eye" (click)="isShow=!isShow"><img
                                src="assets/images/{{isShow?'show':'hide'}}-password.svg" alt="hide-password"></span>
                    </div>
                    <ng-container *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                        <span class="invalid-input-text" *ngIf="password?.errors?.required">Password is required</span>
                    </ng-container>

                    <div class="forgot-pw">
                        <a title="Forgot Password?" class="forgot-link ms-auto" (click)="forgotPassword()">Forgot
                            Password?</a>
                    </div>
                    <div class="form-action">
                        <button class="btn btn-primary" type="submit">Sign
                            In</button>
                    </div>
                    <p class="signup-para text-center">Don’t have an account? <a class="signup-link" (click)="openSignUp()">Sign
                            Up</a></p>

                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>