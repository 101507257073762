<section class="view-info-section res-pt-0">
    <div class="container">
        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity.svg" alt="viewinfo-infinity">
            </div>
            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart.svg" alt="viewinfo-heart">
            </div>
            <div class="second-border">
                <div class="row member-title-row">
                    <div class="guestbook-message-title-sec">
                        <h2 class="guestbook-message-title">FAQs</h2>
                        <div *ngIf="faqData?.length != 0">
                            <div *ngFor="let data of faqData" class="faq-text-box">
                                <h4 class="faq-text-head">{{data?.question}}</h4>
                                <p class="faq-text-para">{{data?.answer}}</p>
                            </div>
                        </div>
                        <div class="comment-sec-empty" *ngIf="faqData == undefined">
                            <div class="comment-empty-title faq-empty-title">Nothing at the moment.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
