import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { IResponse } from '../../interfaces/response';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-downgrade-plan',
  templateUrl: './downgrade-plan.component.html',
  styleUrls: ['./downgrade-plan.component.scss']
})
export class DowngradePlanComponent implements OnInit {
  @Input() data: any;
  memberId: any;
  constructor(public modalRef: BsModalRef, public toastr: ToastrService,public commonService: CommonService, public paymentService: PaymentService) { }

  ngOnInit(): void {
    if (this.modalRef.content.memberId) {
      this.memberId = this.modalRef.content.memberId;
      // Now you can use this.memberId in your modal functions
    }
  }

  confirmDowngrade(){
    this.paymentService.downgradePlan(this.memberId).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        localStorage.setItem('planType','1');
        const userData = localStorage.getItem('userData');
        const updatePlanMemberData = JSON.parse(userData);
        updatePlanMemberData.user.plan_type = 1;
        this.commonService.userData.next(updatePlanMemberData);
        localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
        this.modalRef.hide();
        this.toastr.success(res.message);
        window.location.reload();
      }
    });
  }
}
