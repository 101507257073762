<footer>
    <div class="footer-img">
        <div class="container footer-img-container" [ngClass]="router.url.includes('scan-qr')?'qr-footer-container':''">
            <img src="../assets/images/footer-right-img.svg" alt="footer-right-img" class="footer-right-img"
                *ngIf="!router.url.includes('scan-qr')" />
        </div>
    </div>
    <div class="footer-text" [ngClass]="{'footer-bg-remove':router.url.includes('landing-page')
      }">
        <div class="container footer-container footer-container">
            <div class="footer-links">
                <a routerLink="/about-us"
                    [ngClass]="{ 'active-link': currentRoute === '/about-us' }">About Us</a>
                <a routerLink="/faq" [ngClass]="{ 'active-link': currentRoute === '/faq' }">FAQs</a>
                <a routerLink="/contact-us"
                    [ngClass]="{ 'active-link': currentRoute === '/contact-us' }">Contact Us</a>
                <a routerLink="/privacy-policy"
                    [ngClass]="{ 'active-link': currentRoute === '/privacy-policy' }">Privacy Policy</a>
                <a routerLink="/terms-condition"
                    [ngClass]="{ 'active-link': currentRoute === '/terms-condition' }">Terms & Conditions</a>
            </div>
            <p class="ms-auto">©{{year.getFullYear()}} eternallyme.com. All rights reserved</p>
        </div>
    </div>
</footer>
