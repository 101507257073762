<div class="modal-header">
    <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid"  (click)="modalRef.hide()" />
</div>
<div class="modal-body padding-33">
    <div class="modal-title" *ngIf="data==='deleteMessage'">Delete Message?</div>
    <div class="modal-title" *ngIf="data!=='deleteMessage'">Delete {{data===0 ? 'picture' : 'video'}}?</div>
    <div class="modal-subtitle" *ngIf="data==='deleteMessage'">Are you sure you want to delete this message?</div>
    <div class="modal-subtitle" *ngIf="data!=='deleteMessage'">Are you sure you want to delete this {{data===0 ? 'picture' : 'video'}}?</div>
    <button type="submit" (click)="delete()" class="btn btn-primary-btn fill-button">Yes, Delete</button>
    <div>
        <a (click)="modalRef.hide()" class="cancel-modal-btn">Cancel</a>
    </div>
</div>