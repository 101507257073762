<div class="share-obituary-modal">
    <div class="modal-header">
        <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" aria-label="Close"
            (click)="modalRef.hide()" />
    </div>
    <div class="modal-body body-top-margin">
        <div class="select-modal-sec share-obt">
            <form [formGroup]="form">
                <div class="modal-titles">Add Obituary</div>
                <div class="modal-subtitles">Add your loved one's obituary that you can share on social media.</div>
                <div class="share-message mb-0">
                    <div class="textarea-message p-0 position-relative">

                        <div class="form-floating form-floating-add"
                            [ngClass]="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched)?'invalid-input':''">
                            <textarea pattern="^\S(.*\S)?\s*$" required maxlength="500" class="form-control"
                                id="floatingTextarea" #myTextarea (input)="onTextareaChange()" formControlName="message"
                                placeholder=" "
                                onkeypress="return this.value.trim() !== '' || /\S/.test(event.key)"></textarea>
                            <label for="floatingTextarea">Message</label>
                        </div>
                        <div
                            *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)">
                            <div class="invalid-input-text-neg">Message is invalid</div>
                        </div>


                    </div>

                </div>
                <div class="share-add-action">
                    <button (click)="modalRef.hide()" type="button"
                        class="btn btn-outline-primary btn-primary-cancel">cancel</button>
                    <button (click)="submitForm()" type="submit"
                        class="btn btn-primary btn-primary-update">Save</button>
                </div>
            </form>
        </div>
    </div>
</div>
