import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utility.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../services/common.service';
import { Subject } from 'rxjs';
import { ShareButtonsConfig } from 'ngx-sharebuttons';

@Component({
  selector: 'app-edit-obituary',
  templateUrl: './edit-obituary.component.html',
  styleUrls: ['./edit-obituary.component.scss']
})
export class EditObituaryComponent implements OnInit {
  shareConfig: ShareButtonsConfig;
  url1:any;
  descriptions:any;
  image1:any;
  imageMail:any;
  public previewImage: string;
  form: FormGroup;
  textareaValue;
  public destroy$ = new Subject();
  userData: any;
  @Output() onClose = new EventEmitter();
  public data;







  constructor(public modalService: BsModalService,public modalRef: BsModalRef,public loaderService: NgxSpinnerService, public commonService: CommonService,
    private readonly formBuilder: FormBuilder, public toastr: ToastrService, public utilityService: UtilityService) {
    this.form = this.formBuilder.group({
      message: [this.descriptions]
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      message: this.data
    });
    // this.previewImage = '';
    // this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
    //   if (res) {
    //     this.userData = res;
        
    //   }
    // });
  }



  @ViewChild('myTextarea') myTextarea: ElementRef;
  
  onTextareaChange() {
     this.textareaValue = this.myTextarea.nativeElement.value;
    this.descriptions = this.textareaValue;
  }

  submitForm(del) {

    if(!this.form.value.message && del===0){
      this.toastr.error(`Please Enter Message`);
      this.loaderService.hide();
      return;
    }
       
      const data = {
        close: true,
        form: this.form.value.message,
        delete : del
      };
      this.onClose.next(data);
      this.onClosed();
    
  }

  onClosed() {
    this.modalService.hide();
  }

}
