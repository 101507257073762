<div class="modal-header">
    <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid"  (click)="modalRef.hide()" />
</div>
<div class="modal-body padding-33">
    <div class="modal-title">Cancel Subscription?</div>
    <div class="modal-subtitle subtitle-width">Are you sure you want to cancel your subscription?<br />Your loved one's memorial page will no longer be accessible after your subscription ends!</div>
    <button type="submit" (click)="cancelSubscription()" class="btn btn-primary-btn fill-button bg-red-btn">Yes</button>
    <div>
        <a (click)="modalRef.hide()" class="cancel-modal-btn">Cancel</a>
    </div>
</div>