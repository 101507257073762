import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../common/services/common.service';
import { UtilityService } from '../../common/services/utility.service';
import { MagicNumbers } from '../../common/component/app-magic-numbers';

@Component({
  selector: 'app-upgrade-plans',
  templateUrl: './upgrade-plans.component.html',
  styleUrls: ['./upgrade-plans.component.scss']
})
export class UpgradePlansComponent implements OnInit {
  public planType :any;
  public modalRef: BsModalRef;
  public memoryCode :string;
  public codeNow :any  ;
  public codeLater = '';
  public memoryCodeText :any;
  public showStatus: boolean;
  public show = true;
  constructor(
    public modalService: BsModalService,
    public utilityService: UtilityService,
    public toastr: ToastrService,
    public router: Router, public commonStateService: CommonService,
  ) {
   }

  ngOnInit(): void {
    this.getMemoryStatus();
  }

      /**
   * Opening the sign in for guestbook
   */
  openModal(qrCodeDelivery,value) {
    this.show = true;
    this.modalRef = this.modalService.show(qrCodeDelivery,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: 'modal-dialog signin-modal' });
      this.planType = value;
      this.memoryCodeText = '';
      localStorage.setItem('planType',value);
      const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.plan_type = value;
          this.commonStateService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
  }

  submit(){
    const payloads = {
      code : this.codeNow,
      planType : this.planType,
      memoryCode : this.memoryCodeText
    };
    if(this.codeNow === MagicNumbers.three){
      this.utilityService.validatePlan(this.memoryCodeText).subscribe((res:any)=>{
        if(res.data.is_valid){
          localStorage.setItem('memoryStatus', JSON.stringify(payloads));
          this.modalRef.hide();
          this.router.navigate(['/payment']);
        }else{
          this.toastr.error(res.message);
        }
      });
    }else{
      localStorage.setItem('memoryStatus', JSON.stringify(payloads));
      this.modalRef.hide();
      this.router.navigate(['/payment']);
    }
  }

  /**
   * API Call for get memory status 
   */
  getMemoryStatus(){
      this.utilityService.memoryStatus().subscribe((res:any)=>{
        this.showStatus = res.data?.is_exist;
      });
  }

  getValue(value){
    if(value !== MagicNumbers.three){
      this.memoryCodeText = '';
      this.show = false;
    }else{
      this.show = false;
    }
    this.codeNow = value;
  }
}
