<!-- Start Slider Section -->
<section>
    <div class="container">
        <div class="main-container comment-sec-wrapper">
            <div class="back-section">
                <div class="back-img"><img src="assets/images/back-right.svg" alt="Back" /></div>
                <div class="back-text"><a (click)="goBackLocation()">Back</a></div>
            </div>
            <div class="carousel-main-sec">
                <div class="carousel-left-sec">
                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" *ngIf="commentDetails?.type === 0">
                                <img [src]="commentDetails?.path" class="d-block" alt="Image">
                            </div>
                            <div class="carousel-item active" *ngIf="commentDetails?.type === 1">
                                <video [src]="commentDetails?.path +'#t=0.001'" alt="picture-memory-2"
                                    class="comment-video-list">
                                    <track label="English" kind="captions" srclang="en" src="" default>
                                </video>
                                <div class="video-icon">
                                    <img src="./../../../assets/images/video-img.svg" alt="eternallyme video image"
                                        (click)="showDoc(commentDetails?.path)" class="video-icon-img" />
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" [disabled]="commentDetails?.prev_id === null"
                            type="button" (click)="slidePicture('previous')" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" [disabled]="commentDetails?.next_id === null"
                            type="button" (click)="slidePicture('next')" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div class="add-likes-sec">
                        <div class="like-img">
                            <img *ngIf="commentDetails?.my_like !== 0" src="assets/images/heart-fill.svg" alt="Back"
                                (click)="showCommentInput ? likeStatus(commentDetails?.my_like) : openModal(signInGuest)" />
                            <img *ngIf="commentDetails?.my_like === 0" src="assets/images/heart-blank.svg" alt="Back"
                                (click)="showCommentInput ? likeStatus(commentDetails?.my_like) : openModal(signInGuest)" />
                        </div>
                        <div class="like-text">{{commentDetails?.likes_count || 0}} {{(commentDetails?.likes_count > 1 )
                            ? 'likes' : 'like'}}
                        </div>
                    </div>
                </div>
                <div class="carousel-right-sec">
                    <div class="comment-sec-middle">
                        <div class="comment-sec-top" *ngIf="commentDetails?.comments?.data.length !== 0">
                            <div class="comment-heading">
                                {{ (commentDetails?.comments?.total === 0 || commentDetails?.comments?.total === 1) ?
                                'Comment' : 'Comments' }}
                                ({{ commentDetails?.comments?.total !== null ?
                                commentDetails?.comments?.total.toString().padStart(2, '0') : '00' }})
                            </div>
                        </div>
                        <div class="comment-sec-mid-sec"
                            [ngClass]="commentDetails?.comments?.data.length === 0 ? 'comment-d-flex' : ''">
                            <div class="comment-sec-empty" *ngIf="commentDetails?.comments?.data.length === 0">
                                <div class="comment-empty-title">No Comments Yet!</div>
                                <div class="comment-empty-subtitle">Be the first to comment</div>
                            </div>
                            <div class="comment-sec" *ngIf="commentDetails?.comments?.data.length !== 0">
                                <!-- Repeat this one for message -->
                                <div class="comment-text-sec"
                                    *ngFor="let comment of commentDetails?.comments?.data;let i=index">
                                    <div class="comment-head" *ngIf="comment?.guest_book !== null">
                                        <span class="profile-img-wrap loveone-profile-wrap">
                                            <img [src]="comment?.guest_book?.profile_picture || 'assets/images/user-img.svg'"
                                                alt="profile img" class="profile-img" />
                                        </span>
                                        {{comment?.guest_book?.name | titlecase}}
                                    </div>
                                    <div class="comment-head" *ngIf="comment?.guest_book === null">
                                        <span class="profile-img-wrap loveone-profile-wrap">
                                            <img [src]="comment?.user?.profile_picture || 'assets/images/user-img.svg'"
                                                alt="profile img" class="profile-img" />
                                        </span>
                                        {{comment?.user?.name
                                        | titlecase}}
                                    </div>
                                    <div class="comment-text">{{comment?.comment}}</div>
                                    <div class="comment-hand-sec">
                                        <div class="comment-hand-wrap">
                                            <div class="comment-hand-left">
                                                <img [src]=" showCommentInput ? (comment?.likes[0]?.like === 1 ? 'assets/images/left-hang-fill.svg' : 'assets/images/left-hang-blank.svg') : 'assets/images/left-hang-gray.svg'"
                                                    alt="Back" (click)="likeComment(comment)" />
                                                <span class="like-count">{{comment?.likes_count || 0}}</span>
                                            </div>
                                            <div class="comment-hand-right">
                                                <img [src]="showCommentInput ? (comment?.likes[0]?.like === 2 ? 'assets/images/right-hang-fill.svg' : 'assets/images/right-hang-blank.svg') : 'assets/images/right-hang-gray.svg'"
                                                    alt="Back" (click)="disLikeComment(comment)" />
                                                <span class="like-count">{{comment?.dislikes_count || 0}}</span>
                                            </div>
                                            <div class="comment-hand-right delet-position-comment"
                                                *ngIf="!showSignInMember">
                                                <img src="../assets/images/icon-del-col.svg" alt="delete-icon"
                                                    (click)="show=i" class="del-icon-comment" />

                                                <div class="reomove-modal-wrap" *ngIf="show=== i">
                                                    <div class="reomove-modal-sec">
                                                        <div class="reomove-title">Remove Comment?</div>
                                                        <button type="submit"
                                                            (click)="apiHitForDeleteComment(comment,i)"
                                                            class="btn btn-primary-btn fill-button reomove-btn">Yes,
                                                            Remove</button>
                                                        <div><a (click)="show=-1"
                                                                class="cancel-modal-btn">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment-time-wrap">
                                            {{comment?.created_at | date:'medium'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment-sec-bottom">
                        <div class="comment-btn" *ngIf="!showCommentInput">
                            <button type="button" class="btn btn-primary-btn guestbook-btn"
                                (click)="openModal(signInGuest)">Post your Comment<br /><span>Verification
                                    required</span></button>
                        </div>
                        <div class="comment-input-sec search-results-form" *ngIf="showCommentInput">
                            <form class="form-floating" [formGroup]="commentForm">
                                <input type="text" class="form-control" id="floatingInput" (focus)="show = -1"
                                    formControlName="comment" placeholder="Post your comment" maxlength="250" />
                                <label for="floatingInput">Post your comment</label>
                                <div class="invalid-input-text-neg"
                                    *ngIf="commentForm.controls.comment.invalid && (commentForm.controls.comment.dirty || commentForm.controls.comment.touched)">
                                    <div *ngIf="commentForm.controls.comment.errors.pattern">
                                        Only alphabets are allowed.
                                    </div>
                                </div>
                                <button class="btn btn-primary-btn comment-arrow-btn" (click)="submitComment()"
                                    [disabled]="commentForm.invalid"><img src="assets/images/icon-right-color.svg"
                                        alt="Image"></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Slider Section -->
<ng-template #signInGuest>
    <div class="modal-header">
        <img src="assets/images/icons-close.svg" (click)="modalRef.hide()" alt="close-btn" class="img-fluid"
            data-bs-dismiss="modal" aria-label="Close" />
    </div>
    <div class="modal-body">
        <div class="modal-title">Enter Email</div>
        <div class="modal-subtitle">To like the photo and comment, please enter your email.</div>
        <form [formGroup]="form">
            <div>
                <div class="form-floating guest-signin-input form-floating-add"
                    [ngClass]="{ 'has-error': form.get('email').invalid && (form.get('email').dirty || form.get('email').touched) }">
                    <input type="email" class="form-control form-floating-margin"
                        [attr.disabled]="userData?.user?.email" formControlName="email" id="floatingInput"
                        placeholder="mikewilliams@gmail.com" maxlength="50">
                    <label for="floatingInput">Email ID</label>
                    <div class="invalid-input-text-neg"
                        *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
                        <div *ngIf="form.get('email').errors.required">Email is required</div>
                        <div *ngIf="form.get('email').errors.email">Email is invalid</div>
                    </div>
                </div>
            </div>
            <button type="submit" (click)="submitGuest()" [disabled]="form.invalid"
                class="btn btn-primary-btn">Next</button>
        </form>

    </div>
</ng-template>
