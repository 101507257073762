<section>
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="main-container view-info-section custom-css">
        <div class="add-member-wrapper">
          <div class="header-img-infinity">
            <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity" />
          </div>
          <div class="footer-img-heart">
            <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart" />
          </div>
          <div class="add-personal-section">
            <div class="add-personal-title">Add Personal Details <span *ngIf="!messageHide">& Message</span> </div>
            <div class="add-personal-subtitle" *ngIf="!messageHide">
              Please fill in the required fields to Sign Guest Book.
            </div>
            <div class="add-personal-subtitle" *ngIf="messageHide">
              Details are required to post your comment .
            </div>
            <div class="row search-results-form">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12 ">
                <div class="title-right-sec new-input-file-wrap">
                  <div *ngIf="!profilePic" class="add-pic add-picture-section  add-pic-left"><img
                      src="assets/images/add-picture-light.svg" alt="add-pic" />
                    Profile Picture<br /> (Optional)

                    <input type="file" title="" id="logoUpload" (change)="uploadProfilePic($event)"
                      class="custom-input-file" formControlName="profileImage"
                      accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                  </div>
                  <div *ngIf="profilePic" class="add-pic add-picture-section  add-pic-left"><img [src]="profilePic"
                      alt="add-pic" class="uploaded-img" />
                    <input type="file" title="" id="logoUpload" (change)="uploadProfilePic($event)"
                      class="custom-input-file" formControlName="profileImage"
                      accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                  </div>
                  <span class="support-file">(jpeg, png, and jpg only<br />
                    upto 10 mb)</span>

                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control form-floating-margin" formControlName="name"
                    placeholder="Jacksonville, AL, US" maxlength="50"
                    [ngClass]="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched)?'invalid-input':''" />
                  <label for="floatingInput">Full Name</label>
                  <div class="invalid-input-text-msg" *ngIf="form.get('name').invalid && form.get('name').touched">
                    <div *ngIf="form.get('name')?.errors?.required">
                      Full Name is required
                    </div>
                    <div *ngIf="form.get('name')?.errors?.maxlength">Name must be 50 characters or less</div>
                    <div *ngIf="form.get('name')?.errors?.pattern">Full Name is invalid</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" appNumbersonly
                    class="form-control form-floating-margin add-member-input contact-num-input"
                    formControlName="contact_number" minlength="10" maxlength="10" id="floatingInput"
                    placeholder="Jacksonville, AL, US"
                    [ngClass]="form.get('contact_number')?.invalid && (form.get('contact_number')?.dirty || form.get('contact_number')?.touched)?'invalid-input':''"
                    (focusin)="showCountryCode=true " (focusout)="form.get('contact_number')?.errors?.required?showCountryCode=false:
                  '' " (focusout)="form.get('contact_number')?.errors?.minlength?showCountryCode=false:'' " />
                  <label for="floatingInput">Contact Number</label>
                  <p class="std-code" *ngIf="showCountryCode">+1</p>
                  <div class="invalid-input-text-msg" *ngIf="
                    form.get('contact_number').invalid &&
                    form.get('contact_number').touched">
                    <div *ngIf="form.get('contact_number').errors.required">
                      Contact Number is required
                    </div>
                    <div
                      *ngIf="form.get('contact_number').errors.minlength || form.get('contact_number').errors.pattern">
                      Contact number must be 10 digits
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control form-floating-margin" formControlName="email"
                    [attr.disabled]="true" placeholder="mikewilliams@gmail.com" />
                  <label for="floatingInput">Email ID</label>
                  <div class="invalid-input-text-msg" *ngIf="
                      form.get('email').invalid && form.get('email').touched
                    ">
                    <div *ngIf="form.get('email').errors.required">
                      Email is required
                    </div>
                    <div *ngIf="form.get('email').errors.email">
                      Email is not valid
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control form-floating-margin" formControlName="relation"
                    placeholder="Jacksonville, AL, US" id="floatingInput" maxlength="50"
                    [ngClass]="form.get('relation')?.invalid && (form.get('relation')?.dirty || form.get('relation')?.touched)?'invalid-input':''" />
                  <label for="floatingInput">Relation</label>
                  <div class="invalid-input-text-msg" *ngIf="
                      form.get('relation').invalid &&
                      form.get('relation').touched
                    ">
                    <div *ngIf="form.get('relation').errors.required">
                      Relation is required
                    </div>
                    <div *ngIf="form.get('relation').errors.pattern">
                      Relation is invalid
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <input type="text" class="form-control form-floating-margin" formControlName="address"
                    placeholder="Jacksonville, AL, US" maxlength="255"
                    [ngClass]="form.get('address')?.invalid && (form.get('address')?.dirty || form.get('address')?.touched)?'invalid-input':''" />
                  <label>Address</label>
                  <div class="invalid-input-text-msg" *ngIf="
                      form.get('address').invalid && form.get('address').touched
                    ">
                    <div *ngIf="form.get('address').errors.required">
                      Address is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="!messageHide">
                <div class="textarea-message p-0">
                  <div class="form-floating textarea-message p-0">
                    <textarea rows="5" cols="40" type="text"
                      class="form-control form-floating-margin row-height-169 mb-0" formControlName="message"
                      id="floatingInput" placeholder="">
                    </textarea>
                    <label for="floatingInput">Message (Optional)</label>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="!messageHide">
                <div class="add-msg-submit">Your message will be submitted & will be displayed on the memorial page once
                  the owner approves your message.</div>
              </div>
              <div class="col-12">
                <div class="col-12 col-md-12 payment-checkbox-col guest-payment-checkbox margin-bottom">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="0" id="flexCheckChecked"
                      formControlName="optOut" (change)="updateOptOut($event)" />
                    <label class="form-check-label payment-checkbox-label" for="flexCheckChecked">
                      Opt-out of marketing communications.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-btn-personal">
        <button type="submit" [disabled]="form.invalid" class="btn btn-primary-btn btn-add-personal">
          Submit
        </button>
      </div>
    </form>
  </div>
</section>
