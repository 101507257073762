<section class="about-us-section about-us-wrap">
  <div class="container">
    <div class="about-us-sec">
      <div class="about-text-para" [innerHTML]="contactUsData.content"></div>
    </div>
    <div class="image-section">
      <div class="row">
        <div
          class="col-6 col-md-4 about-img-col"
          *ngFor="let image of contactUsData.images"
        >
          <span class="image-wrapper"
            ><img [src]="image" alt="about-us-img"
          /></span>
        </div>
      </div>
    </div>
  </div>
</section>
