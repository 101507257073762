import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IResponse } from '../../interfaces/response';
import { ISearchObj, IStateResponse, IStates } from '../../interfaces/states';
import { CommonService } from '../../services/common.service';
import { SearchService } from '../../services/search.service';
import { UtilityService } from '../../services/utility.service';
import { MagicNumbers } from '../app-magic-numbers';
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  public destroy$ = new Subject();
  public states: IStates[] = [];
  public searchObj: ISearchObj = {};
  public membersList = [];
  public isPersonal = true;
  public searchData;
  public minDate = new Date();
  public maxDate = new Date();
  public today = new Date();
  public selectedState = false;
  public paginationArr = [];
  public currentPage = 1;
  public sortBy = 'atoz';
  public searchArr: ISearchObj[] = [];
  public isLoggedIn = false;
  constructor(public activatedRoute: ActivatedRoute, public commonStateService: CommonService, public searchService: SearchService,
    public datePipe: DatePipe, public toastr: ToastrService, public router: Router, public utilityService: UtilityService) {
    this.paginationArr = ['pre'];
    // To get the search data params 
    this.commonStateService.searchData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.searchObj = res;
        this.setSelectedState();
        this.setBirthAndDeathDates();
        this.setIsPersonal();
        this.searchIfCriteriaExist();
      }
    });
    // To get the user data
    this.commonStateService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnInit(): void {
    this.getStates();
  }
  
    /**
   * set Selected State
  */
  setSelectedState() {
    if (this.searchObj.state) {
      this.selectedState = true;
    }
  }
   /**
   * set Birth And Death Dates
  */
  setBirthAndDeathDates() {
    this.searchObj.deathDate = this.searchObj.deathDate ? new Date(this.searchObj.deathDate) : '';
    this.searchObj.birthDate = this.searchObj.birthDate ? new Date(this.searchObj.birthDate) : '';
  }
  /**
   * set Is Personal
  */
  setIsPersonal() {
    this.isPersonal = this.searchObj.type !== 'memory_code';
  }
    /**
   * search If Criteria Exist
  */
  searchIfCriteriaExist() {
    if (this.hasSearchCriteria()) {
      setTimeout(() => {
        this.searchMemorial();
      }, MagicNumbers.thousand);
    }
  }
  /**
   * has Search Criteria
  */
  hasSearchCriteria() {
    return (
      this.searchObj.name ||
      this.searchObj.state ||
      this.searchObj.birthDate ||
      this.searchObj.deathDate ||
      this.searchObj.memoryCodeNumber
    );
  }
  


  /**
   * API Call for States list
  */
  getStates() {
    this.utilityService.getStates().subscribe((res: IStateResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
        if (this.selectedState === false) {
          setTimeout(() => {
            this.searchObj.state = null;
            this.selectedState = false;
          }, MagicNumbers.oneHundred);
        }
      }
    });
  }

  /**
   * API Call for Searching memorial
  */
  searchMemorial(page?) {
    localStorage.removeItem('memberGuestLike');
    sessionStorage.removeItem('memberGuestLike');
    sessionStorage.removeItem('showCommentInput');

    this.paginationArr = ['pre'];
    this.currentPage = page ? page : 1;
    if (this.searchObj.name || this.searchObj.state || this.searchObj.birthDate || this.searchObj.deathDate || this.searchObj.memoryCodeNumber) {
      this.searchArr = [];
      this.searchArrAdd();
      this.resetSearch();  
      this.membersList = [];
      this.searchData = {};
      this.searchService.searchMemorial(this.payloadSearch(page)).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.membersList = res.data.data;
          this.searchData = res.data;
          this.searchData.pageCount = Math.ceil((this.searchData?.total) / MagicNumbers.ten);
          for (let index = 1; index <= this.searchData.pageCount; index++) {
            this.paginationArr.push(index);
          }
          this.paginationArr.push('next');
        }
      });
    } else if (this.isPersonal) {
      this.toastr.error('Please enter some text to search data');
    } else {
      this.toastr.error('Please enter QR Memory code number');
    }
  }
  
    /**
   * search add array 
   */
    searchArrAdd(){
      if (this.searchObj.birthDate && this.searchObj.deathDate && this.searchObj.deathDate < this.searchObj.birthDate) {
        this.toastr.error("Birth date can't be greater than day of passing");
      }
      if (this.searchObj.name) {
        this.searchArr.push({ name: this.searchObj.name });
      }
      if (this.searchObj.birthDate) {
        this.searchArr.push({ birthDate: this.searchObj.birthDate });
      }
      if (this.searchObj.deathDate) {
        this.searchArr.push({ deathDate: this.searchObj.deathDate });
      }
      if (this.searchObj.state) {
        this.states.forEach((e) => {
          if (Number(e.id) === Number(this.searchObj.state)) {
            this.searchArr.push({ state: e.name });
          }
        });
      }
      if (this.searchObj.memoryCodeNumber) {
        this.searchArr.push({ memoryCodeNumber: this.searchObj.memoryCodeNumber });
      }
    }
    
    /**
   * search reset 
   */

  resetSearch(){
    if (!this.isPersonal) {
      this.searchObj.type = 'memory_code';
      this.searchObj.name = '';
      this.searchObj.birthDate = '';
      this.searchObj.deathDate = '';
      this.searchObj.state = '';
    } else {
      this.searchObj.type = 'personal_detail';
    }
  }
  /**
   * payloads search
   */
  payloadSearch(page){
    return {
      type: this.searchObj.type,
      name: this.searchObj.name ? this.searchObj.name : '',
      state_id: this.searchObj.state ? this.searchObj.state : '',
      dob: this.searchObj.birthDate ? this.datePipe.transform(this.searchObj.birthDate, 'yyyy-MM-dd') : '',
      dod: this.searchObj.deathDate ? this.datePipe.transform(this.searchObj.deathDate, 'yyyy-MM-dd') : '',
      memory_code_number: this.searchObj.memoryCodeNumber ? this.searchObj.memoryCodeNumber : '',
      page: page ? page : ''
    };
  }
  /**
     * On value change of date picker
     * @param e and @param type is required
    */
  onValueChange(e, type) {
    if (type === 'dob') {
      this.maxDate = e;
    } else {
      this.minDate = e;
    }
  }

  /**
     * To view member info details
     * @param data is response of list
    */
  viewMemberInfo(data) {
    localStorage.removeItem('showCommentInput');
    localStorage.setItem('member_id',JSON.stringify(data.id));
    this.router.navigate(['/view-information/',data.id]);
  }

  /**
     * On page changed
     * @param page is required
    */
  pageChanged(page) {
    window.scrollTo(0, 0);
    if (page === 'next' && this.searchData?.pageCount > this.currentPage) {
      this.currentPage = this.currentPage + 1;
      this.searchMemorial(this.currentPage);
    } else if (page === 'pre' && this.searchData?.pageCount <= this.currentPage) {
      this.currentPage = Number(this.currentPage) - 1;
      this.searchMemorial(this.currentPage);
    } else if (page !== 'pre' && page !== 'next') {
      this.currentPage = page;
      this.searchMemorial(this.currentPage);
    }
  }

  /**
   * To Sort the search results data
  */
  sortData() {
    const membersList = [...this.membersList].sort((a, b) => {
      if (this.sortBy === 'ztoa') {
        return a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1;
      } else if (this.sortBy === 'atoz') {
        return a.first_name.toLowerCase() < b.first_name.toLowerCase() ? -1 : 1;
      } else if (this.sortBy === 'new') {
        return a.created_at < b.created_at ? 1 : -1;
      } else {
        return a.created_at < b.created_at ? -1 : 1;
      }
    });
    this.membersList = membersList;
  }


  /**
   * To remove the applied filters
   * @param search is from applied filters
  */
  removeFilter(search) {
    if (search.name) {
      delete this.searchObj.name;
    } else if (search.birthDate) {
      delete this.searchObj.birthDate;
    } else if (search.deathDate) {
      delete this.searchObj.deathDate;
    } else if (search.state) {
      delete this.searchObj.state;
      this.selectedState = false;
    } else if (search.memoryCodeNumber) {
      this.clearFilter();
    }
    if (this.searchArr.length === 1) {
      this.clearFilter();
    } else {
      this.searchMemorial();
    }
  }

  /**
   * To clear the all applied filters
  */
  clearFilter() {
    this.searchArr = [];
    this.searchObj = {};
    this.membersList = [];
    this.searchData = {};
    this.selectedState = false;
  }

  /**
  * To add the class for birth date picker
 */
  addClassDatePicker(className) {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', className);
    }, 0);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
