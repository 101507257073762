import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../common/services/common.service';
import { UtilityService } from '../../../common/services/utility.service';
import { IResponse } from '../../../common/interfaces/response';
import { LandingPageService } from '../../../common/services/landing-page.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html'
})
export class AboutUsComponent implements OnInit {
  public aboutUsData;
    constructor(public cmsService: LandingPageService,public commonService: CommonService,public utilityService: UtilityService) {
  }

  ngOnInit(): void {
    this.getContent();
    if(JSON.parse(localStorage.getItem('userData'))){
      this.checkPendingReq(JSON.parse(localStorage.getItem('userData'))?.user.member?.id);
    }
  }

   /**
   * show Icon red method
  */
  checkPendingReq(memberID){
  this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
    if (res && !res.errors) {
       this.commonService.guestRequestSharing.next(res.data);
    }
    });
  } /**
   * API Call for gettting the about us content 
  */
  getContent() {
    this.cmsService.getContentBasedonPage('about-us').subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.aboutUsData = res.data;
        this.aboutUsData.images = JSON.parse(res.data.images);
      }
    });
  }
}
