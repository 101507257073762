<div class="signin-modal-content change-password-content upgrade-modal-content upgrade-modal-content2">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" aria-label="Close" (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body signin-body change-password-body upgrade-plan2-body payment-success-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/upgrade-header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <div class="signin-container">
                <div class="current-plan"><span>Current Plan: $24.99 annually</span></div>
                <ng-container *ngIf="!billingInfo && !isPaymentSuccess">
                    <div class="row upgrade-plan-row">
                        <div class="col-md-4 col-12 payment-col">
                            <div class="payment-text payment-text-upgrade">
                                <h1>$14.99</h1>
                                <span class="one-time-text">Annually</span>
                            </div>

                        </div>
                        <div class="col-12 col-md-8">
                            <div class="upgrade-text upgrade-text-mob">
                                <h2>Downgrade Your Plan</h2>
                                <p class="upgrade-text-para">Here’s what you’ll get on the standard plan</p>
                                <div class="plan-size plan-size-text">
                                    <h5><img src="assets/images/upgrade-bullet.svg" alt="upgrade-bullet">250 Picture & 10 Video Memories
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary upgrade-btn" (click)="downgrade()">Downgrade</button>
                    <button *ngIf="planCancelledOrNotActivated" type="button" class="btn btn-link upgrade-link-btn cancel-btn-text" (click)="cancelSubs()">Cancel Subscription</button>
                </ng-container>
                <span class="signin-footer"><img src="assets/images/upgrade-footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>
            </div>
        </div>
    </div>
</div>