import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../common/interfaces/response';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { CommonService } from '../../common/services/common.service';
import { UtilityService } from '../../common/services/utility.service';
import { MemberService } from '../../common/services/member.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit {

  public addMemberForm: FormGroup;
  public states;
  public cities;
  public burialCities;
  public minDate = new Date();
  public maxDate = new Date();
  public deathDateMax = new Date();
  public profilePic: string;
  public selectedState = false;
  public selectedCity = false;
  public selectedBurialCity = false;
  public selectedBurialState = false;
  public update = false;
  public frameCount = 1;
  public selectedFrame = 1;
  public url;
  public planName;
  public dayCount;
  memberId;
  updateHighlightMemories = false;
  updateHighlightMemorial = false;
  updateHighlightCareer = false;
  updateHighlightFamily = false;
  action:string;
  constructor(public formBuilder: FormBuilder, public utilityService: UtilityService, public location: Location, 
    public memberService: MemberService, public activatedRoute: ActivatedRoute,
    public router: Router, public toastr: ToastrService, public commonService: CommonService, public datePipe: DatePipe) {
    this.addMemberForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty)]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty)]],
      birthDate: ['', Validators.required],
      birthDatePrivate: [false],
      deathDate: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      gender: ['', Validators.required],
      cemetryName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty)]],
      plotNumber: ['', [Validators.required, Validators.maxLength(MagicNumbers.five)]],
      rowNumber: ['', [Validators.required, Validators.maxLength(MagicNumbers.five)]],
      section: ['', [Validators.required, Validators.maxLength(MagicNumbers.ten)]],
      cemetryAddress: ['', [Validators.required, Validators.maxLength(MagicNumbers.fifty)]],
      zipCode: ['', [Validators.required, Validators.minLength(MagicNumbers.five), Validators.maxLength(MagicNumbers.twelve)]],
      burialState: ['', Validators.required],
      burialCity: ['', Validators.required],
      burialInfoPrivate: [false],
      cemetryWebsite: ['', [Validators.required,
      Validators.pattern(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
      )]],
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getStates();
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
    }

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.update = true;
        this.getMemberData(this.memberId);     
        this.getMemberDataHighlight(this.memberId);  
      }
    });
    if (localStorage.getItem('update')) {
      this.update = true;
      this.disableFields(true);
    }
  }

  get firstName() { return this.addMemberForm.get('firstName'); }
  get middleName() { return this.addMemberForm.get('middleName'); }
  get lastName() { return this.addMemberForm.get('lastName'); }
  get birthDate() { return this.addMemberForm.get('birthDate'); }
  get deathDate() { return this.addMemberForm.get('deathDate'); }
  get state() { return this.addMemberForm.get('state'); }
  get city() { return this.addMemberForm.get('city'); }
  get gender() { return this.addMemberForm.get('gender'); }
  get cemetryName() { return this.addMemberForm.get('cemetryName'); }
  get plotNumber() { return this.addMemberForm.get('plotNumber'); }
  get rowNumber() { return this.addMemberForm.get('rowNumber'); }
  get section() { return this.addMemberForm.get('section'); }
  get cemetryAddress() { return this.addMemberForm.get('cemetryAddress'); }
  get zipCode() { return this.addMemberForm.get('zipCode'); }
  get burialState() { return this.addMemberForm.get('burialState'); }
  get burialCity() { return this.addMemberForm.get('burialCity'); }
  get cemetryWebsite() { return this.addMemberForm.get('cemetryWebsite'); }
  get prayer_card() { return this.addMemberForm.get('prayer_card'); }


  getMemberData(id) {
    this.memberService.getMemberStep(id, 1).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        const basicDetails = res.data;
        this.url = basicDetails.url;
        this.createMemberForm(basicDetails); 
        this.changeState(basicDetails.state_id, '', true);
        this.changeState(basicDetails.burial_state_id, 'burial', true);
      }
    });
  }

  /**
   * Method check Local Add Member 
   */
  checkLocalAdMember(){
    if (localStorage.getItem('addMember')) {  
      const addMember = localStorage.getItem('addMember');
      const basicDetails1 = JSON.parse(addMember);
      const basicDetails = basicDetails1[0];       
      this.url = basicDetails.url;
      this.createMemberForm(basicDetails); 
      this.changeState(basicDetails.state_id, '', true);
      this.changeState(basicDetails.burial_state_id, 'burial', true);
    }
  }
  /**
   * Method to create Member form
   */
  createMemberForm(basicDetails){
    this.addMemberForm.patchValue({
      firstName: basicDetails.first_name,
      middleName: basicDetails.middle_name,
      lastName: basicDetails.last_name,
      birthDate: basicDetails.dob ? new Date(basicDetails.dob) : new Date(),
      deathDate: basicDetails.dob ? new Date(basicDetails.dod) : new Date(),
      state: basicDetails.state_id,
      city: basicDetails.city_id,
      gender: basicDetails.gender,
      cemetryName: basicDetails.cemetery_name,
      plotNumber: basicDetails.plot_number,
      rowNumber: basicDetails.row_number,
      section: basicDetails.section,
      cemetryAddress: basicDetails.cemetery_address,
      zipCode: basicDetails.zip_code,
      burialState: basicDetails.burial_state_id,
      burialCity: basicDetails.burial_city_id,
      cemetryWebsite: basicDetails.cemetery_website,
      burialInfoPrivate: basicDetails.is_burial_private === 1 ? true : false,
      birthDatePrivate: basicDetails.is_dob_private === 1 ? true : false
    });
    this.frameCount = (basicDetails.frame_no === null || basicDetails.frame_no === undefined || basicDetails.frame_no === '') ? 1 : Number(basicDetails.frame_no);
    this.selectedFrame = this.frameCount;
    this.profilePic = basicDetails.profile_picture;
    this.dayCount = basicDetails.day_count;
    this.planName = basicDetails.plan;
    this.createMemberFormCondition(basicDetails);
  }

  /**
   * Method create Member Form Condition
   */
  createMemberFormCondition(basicDetails){
    if (basicDetails.state_id) {
      this.selectedState = true;
    }
    if (basicDetails.city_id) {
      this.selectedCity = true;
    }
    if (basicDetails.burial_state_id) {
      this.selectedBurialState = true;
    }
    if (basicDetails.burial_city_id) {
      this.selectedBurialCity = true;
    }
  }
  /**
   * To disable the form fields in update member
  */
  disableFields(value: boolean, exclude = ['birthDate', 'deathDate', 'burialInfoPrivate', 'birthDatePrivate']) {
    const state = value ? 'disable' : 'enable';
    Object.keys(this.addMemberForm.controls).forEach((controlName) => {
      if (!exclude.includes(controlName)) {
        this.addMemberForm.controls[controlName][state]();
      }
    });
  }
  /**
     * API Call for states list
    */
  getStates() {
    this.utilityService.getStates().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
      }
    });
  }
  /**
   * API Call for getting the cities list based on state id
  */
  changeState(event, type?, update = false) {
    this.utilityService.getCities(event?.target?.value ? event?.target?.value : event).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.changeStateBurial(type,res,update);
      }
    });
  }
  /**
   * 
   */
  changeStateBurial(type?:any,res?:any,update?:any) {
    if (type === 'burial') {
      this.burialCities = res.data;
      if (!update) {
        setTimeout(() => {
          this.addMemberForm.controls.burialCity.setValue(null);
          this.selectedBurialCity = false;
        }, 0);
      }
    } else {
      if (!update) {
        setTimeout(() => {
          this.addMemberForm.controls.city.setValue(null);
          this.selectedCity = false;
        }, 0);
      }
      this.cities = res.data;
    }
  }
  /**
   * On value change of date picker
   * @param e and @param type is required
  */
  onValueChange(e, type) {
    if (e) {
      if (type === 'dob') {
        this.maxDate = e;
      } else {
        this.minDate = e;
      }
    }
  }
  /**
   * To save member data and navigating to next step
  */
  saveMemberData(action:string) {
    if (this.addMemberForm.valid) {
      if (this.profilePic) {
        const addMemberData = {
          first_name: this.addMemberForm.getRawValue().firstName,
          middle_name: this.addMemberForm.getRawValue().middleName,
          last_name: this.addMemberForm.getRawValue().lastName,
          dob: this.datePipe.transform(this.addMemberForm.getRawValue().birthDate, 'yyyy-MM-dd'),
          dod: this.datePipe.transform(this.addMemberForm.getRawValue().deathDate, 'yyyy-MM-dd'),
          state_id: this.addMemberForm.getRawValue().state,
          city_id: this.addMemberForm.getRawValue().city,
          gender: this.addMemberForm.getRawValue().gender,
          cemetery_name: this.addMemberForm.getRawValue().cemetryName,
          plot_number: this.addMemberForm.getRawValue().plotNumber,
          row_number: this.addMemberForm.getRawValue().rowNumber,
          section: this.addMemberForm.getRawValue().section,
          cemetery_address: this.addMemberForm.getRawValue().cemetryAddress,
          zip_code: this.addMemberForm.getRawValue().zipCode,
          burial_state_id: this.addMemberForm.getRawValue().burialState,
          burial_city_id: this.addMemberForm.getRawValue().burialCity,
          cemetery_website: this.addMemberForm.getRawValue().cemetryWebsite,
          profile_picture: this.profilePic,
          is_burial_private: this.addMemberForm.getRawValue().burialInfoPrivate === false ? 0 : 1,
          is_dob_private: this.addMemberForm.getRawValue().birthDatePrivate === false ? 0 : 1,
          frame_no: Number(this.frameCount)
        };

        if(this.update){
          this.memberService.updateMember(this.memberId, MagicNumbers.one, addMemberData).subscribe((res: IResponse) => {
            if (res && !res.errors) {
              this.toastr.success('Details updated successfully.');
              localStorage.setItem('member_id',JSON.stringify(res.data.id));
              this.router.navigate(['/view-information/',res.data.id]);
              if(action == 'saveNext'){
                this.router.navigate(['/about-memorial', res.data.id]);
              } else{
                this.router.navigate(['/view-information/',res.data.id]);
              }
            }
          });
        } else{
          this.memberService.addMember(1, addMemberData).subscribe((res: IResponse) => {
            if (res && !res.errors) {
              localStorage.setItem('member_id',JSON.stringify(res.data.id));
              this.router.navigate(['/about-memorial', res.data.id]);
            }
          });
        }
       

      } else {
        this.toastr.error('Please add profile picture');
      }
    } else {
      this.addMemberForm.markAllAsTouched();
    }
  }

  updatedSuccessfull(update,res){
    if (update) {
      this.toastr.success('Updated successfully');      
      localStorage.removeItem('update');
      localStorage.removeItem('addMember');
      localStorage.removeItem('showType');
      localStorage.removeItem('prayerCard');
      this.router.navigate(['/view-information/',res.data.id]);
    } else {
      this.router.navigate(['/congratulations'], { queryParams: { data: JSON.stringify(res.data) } });
    }
  }

  /**
     * API Call for uploading the profile picture
    */
  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success('Profile image uploaded successfully');
          this.profilePic = res.data.url;
        }
      });
    }
  }
  /**
   * To get the frame count on previous selection
  */
  frameCountPrev() {
    if (this.frameCount === 1) {
      this.frameCount = 4;
    } else {
      this.frameCount = this.frameCount - 1;
    }
  }
  /**
   * To get the frame count on next selection
  */
  frameCountNext() {
    if (this.frameCount === MagicNumbers.four) {
      this.frameCount = 1;
    } else {
      this.frameCount = this.frameCount + 1;
    }
  }
  /**
   * To navigate to previous page 
  */
  getMemberDataHighlight(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five)
      .pipe(
        concatMap((res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightMemories = result.member_gallery.length !== 0;
          }
          // Return the next observable to continue the sequence
          return this.memberService.getMemberStep(id, MagicNumbers.four);
        }),
        concatMap((res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightFamily = result.about_family !== 'undefined';
          }
          // Return the next observable to continue the sequence
          return this.memberService.getMemberStep(id, MagicNumbers.three);
        }),
        concatMap((res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightCareer = result.career_accomplishments !== 'undefined';
          }
          // Return the next observable to continue the sequence
          return this.memberService.getMemberStep(id, MagicNumbers.two);
        })
      )
      .subscribe(
        (res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightMemorial = result.about_memorial !== 'undefined';
          }
        },
        (error) => {
        }
      );
  }
  goBack() {
    if (this.update) {
      window.open(this.url, '_self');
    } else if(!this.update){
      this.router.navigate(['/home']); 
    }else{
      this.router.navigate(['/add-loved-one']);
    }
  }

  /**
  * To add the class for birth date picker
 */
  addClass(className) {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', className);
    }, 0);

  }
}

