import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/interfaces/response';
import { AuthService } from '../../../common/services/auth.service';
import { CommonService } from '../../../common/services/common.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MemoryCodeRequiredComponent } from '../memory-code-required/memory-code-required.component';
import { MagicNumbers } from '../../../common/component/app-magic-numbers';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  public signUpForm: FormGroup;
  public isShow = false;
  public isSubmitted = false;
  public className = 'signin-modal';

  constructor(public modalService: BsModalService, public modalRef: BsModalRef, public formBuilder: FormBuilder,
    public authService: AuthService, public toastr: ToastrService, public commonService: CommonService, public router: Router) {
    this.signUpForm = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.pattern(/^(?!^\d+$)[a-zA-Z0-9\s]*[a-zA-Z][a-zA-Z0-9\s]*$/)]],
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,20}$'),
        Validators.maxLength(MagicNumbers.twenty)
      ]],
      accept: [false, [Validators.required]]
    });

  }


  forgotPassword() {
    this.modalRef.hide();
    this.modalService.show(ForgotPasswordComponent, { class: this.className });
  }
  openSignUp() {
    this.modalRef.hide();
    this.modalService.show(MemoryCodeRequiredComponent, { class: this.className });
  }

  get fullName() { return this.signUpForm.get('fullName'); }
  get emailId() { return this.signUpForm.get('emailId'); }
  get password() { return this.signUpForm.get('password'); }
  get accept() { return this.signUpForm.get('accept'); }


  /**
   * API Call for sign in
  */
  openSignIn() {
    this.modalRef.hide();
    this.modalService.show(SigninComponent, { class: this.className });
  }

  onSignUp() {
    this.isSubmitted = true;
    if (this.signUpForm.valid && this.signUpForm.value.accept === true) {
      const payload = {
        fullName: this.signUpForm.value.fullName,
        email: this.signUpForm.value.emailId,
        password: this.signUpForm.value.password
      };
      this.authService.register(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.modalRef.hide();
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      this.signUpForm.markAllAsTouched();
    }
  }
}
