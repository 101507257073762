
    <div class="share-obituary-modal">
        <div class="modal-header">
            <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" aria-label="Close" (click)="modalRef.hide()" />
        </div>
        <div class="modal-body body-top-margin">
            <div class="select-modal-sec share-obt">
                <form [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="modal-titles">Share Obituary</div>
                <div class="modal-subtitles">Share your loved one's obituary on social media.</div>
                <div class="share-message mb-0">
                    <div (click)="submitForm()" [ngClass]="{ disabled: (imageMail==undefined || descriptions=='' || descriptions==undefined || (form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)))  }" class="share-social-wrap">
                        <share-buttons [config]="shareConfig"
                        class="share-btn-sec"
                        theme="modern-light"
                        [include]="[
                          'twitter'
                        ]"
                        [description]="descriptions"
                        [showIcon]="true"
                        [showText]="true"
                        [url]="url2"
                        class="pt-5"
                        [title]="titles"
                      >
                      </share-buttons>
                      <share-buttons [config]="shareConfig"
                        class="share-btn-sec-2"
                        theme="modern-light"
                        [include]="[
                          'email'
                        ]"
                        [description]="descriptions"
                        [showIcon]="true"
                        [showText]="true"
                        [url]="url1"
                        class="pt-5"
                        [title]="titles"
                      >
                      </share-buttons>
                      <share-buttons [config]="shareConfig"
                        class="share-btn-sec"
                        theme="modern-light"
                        [include]="[
                          'facebook'
                        ]"
                        [description]="descriptions"
                        [showIcon]="true"
                        [showText]="true"
                        [url]="url2"
                        class="pt-5"
                        [title]="titles"
                      >
                      </share-buttons>
                    </div>
                </div>
            </form>
                </div>
            </div>
        </div>
