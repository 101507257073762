import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Output() onFileDropped = new EventEmitter<FileList>();
  @Output() onElementDropped = new EventEmitter<[number, number]>(); // Emit the drag-drop for elements

  @Input() dragIndex: number = 0; // To keep track of the element index being dragged
  @HostBinding('style.background-color') private background = '#f5fcff';
  @HostBinding('style.opacity') private opacity = '1';

  // File drop related styling
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';

    // Handle file drop
    const files = evt.dataTransfer?.files;
    if (files?.length > 0) {
      this.onFileDropped.emit(files);
    }

    // Handle element drop (for UI elements)
    const dragData = evt.dataTransfer?.getData('text');
    if (dragData) {
      const hoverIndex = this.dragIndex;
      const dragIndex = parseInt(dragData, 10);
      if (dragIndex !== hoverIndex) {
        this.onElementDropped.emit([dragIndex, hoverIndex]);
      }
    }
  }

  // Allow capturing of drag start event for UI elements
  @HostListener('dragstart', ['$event']) onDragStart(evt: DragEvent) {
    evt.dataTransfer?.setData('text', this.dragIndex.toString());
    this.opacity = '0.5';
  }

  @HostListener('dragend', ['$event']) onDragEnd(evt: DragEvent) {
    this.opacity = '1';
  }
}
