import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AddGuestbookService } from '../../services/add-guestbook.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { MagicNumbers } from '../app-magic-numbers';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-like-comment',
  templateUrl: './like-comment.component.html',
  styleUrls: ['./like-comment.component.scss']
})
export class LikeCommentComponent implements OnInit {
  public modalRef: BsModalRef;
  public form: FormGroup;
  public userData;
  public member;
  public destroy$ = new Subject();
  public showCommentInput: boolean;
  public viewInfoUrl: any;
  public enableButton: any;
  public commentDetails: any = [];
  public pictureId: any;
  public guestBookID: any;
  public commentForm: FormGroup;
  public showSignInMember: any;
  public likeStatusValue: any;
  public backToLocation: boolean;
  public disLikePayloads: any;
  public disLikePayloadsMember: any;
  public likePayloadsUser: any;
  public likePayloadsMember: any;
  public show = -1;
  hideDeleteIcon: any;
  constructor(
    public modalService: BsModalService,
    public commonService: CommonService,
    public addGuestbookService: AddGuestbookService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    readonly formBuilder: FormBuilder,
    readonly location: Location,
    public loaderService: NgxSpinnerService,
    public toaster : ToastrService
  ) {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.commonService.userData
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.userData = res;
        }
      });
    this.form = this.formBuilder.group({
      email: [this.userData?.user ? this.userData.user.email : null, [Validators.required, Validators.email]]
    });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams?.id && queryParams?.pictureId && queryParams?.showSignInGuest && queryParams?.showSignInMember) {
        this.member = JSON.parse(queryParams?.id);
        this.pictureId = JSON.parse(queryParams?.pictureId);
        this.showCommentInput = JSON.parse(queryParams?.showSignInGuest);
        this.showSignInMember = JSON.parse(queryParams?.showSignInMember);
        if (queryParams?.back) {
          this.backToLocation = JSON.parse(queryParams?.back);
        }
        if (this.showSignInMember) {
          this.getCommentDataUser();
        } else {
          this.getCommentDataMember();
        }
      }
    });
    const showPostCommentBtn = JSON.parse(localStorage.getItem('showCommentInput'));
    if (showPostCommentBtn) {
      this.showCommentInput = showPostCommentBtn;
    }
    const valid = JSON.parse(localStorage.getItem('userData'));
    this.hideDeleteIcon = valid ? true : false;
  }


  /**
* Method used for submit guest and route to signup guestbook
*/
  submitGuest() {
    const payloads = {
      email: this.form.value.email,
      member_id: this.member,
      guestbook_id: null
    };

    this.addGuestbookService.signSubmitForm(payloads).subscribe((res: any) => {
      payloads.guestbook_id = res?.data?.id;
      this.guestBookID = res?.data?.id;
      payloads['name'] = this.member?.name;
      localStorage.setItem('memberGuest', JSON.stringify(payloads));
      localStorage.setItem('memberGuestLike', JSON.stringify(payloads));
      if (!this.userData) {
        sessionStorage.setItem('memberGuest', JSON.stringify(payloads));
        sessionStorage.setItem('memberGuestLike', JSON.stringify(payloads));
      }
      this.commonService.memberGuestEmail.next(this.form.value.email);
      if (res?.data?.id) {
        this.showCommentInput = true;
        localStorage.setItem('showCommentInput', JSON.stringify(true));
        this.getCommentDataUser();
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
        this.router.navigate(['/add-guestBook'], {
          queryParams: {
            message: true,
            pictureId: this.pictureId,
            showSignInGuest: !this.showCommentInput,
            showSignInMember: this.showSignInMember
          },
        });
      }
    });
  }
  /**
 * Opening the sign in for guestbook
 */
  openModal(signInGuest) {
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog modal-dialog-centered guest-signin-modal',
    };
    this.modalRef = this.modalService.show(signInGuest, config);
  }

  /**
  * To go back on view information screen
  */
  goBackLocation() {
    if (this.backToLocation) {
      const viewId = localStorage.getItem('member_id');
      this.router.navigate(['/view-information/',viewId]);
    } else {
      this.location.back();
    }
  }

  /**
 * API Call previous slide
 */
  slidePicture(slide: string) {
    this.pictureId = slide === 'previous' ? this.commentDetails?.prev_id : this.commentDetails?.next_id;
    if (this.showSignInMember) {
      this.getCommentDataUser();
    } else {
      this.getCommentDataMember();
    }
    this.commentForm.reset();
  }

  /**
  * API Call for get comment data
  */
  getCommentDataUser() {
    this.loaderService.show();
    this.commentDetails = [];
    const guestbookid = JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id;
    let payloads = `id=${this.pictureId}`;
    if(guestbookid){
      payloads = payloads + `&guest_book_id=${JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id}`;
    }
    this.commentDetails = [];
    this.addGuestbookService
      .getCommentsByUser(payloads)
      .subscribe((res: any) => {
        if (res && !res.errors) {
          this.commentDetails = res.data;
          this.loaderService.hide();
        }
      }, (error) => {
        this.loaderService.hide();
      });
  }

  getCommentDataMember() {
    this.loaderService.show();
    this.commentDetails = [];
    const payloads = `id=${this.pictureId}`;
    this.commentDetails = [];
    this.addGuestbookService
      .getCommentsByMember(payloads)
      .subscribe((res: any) => {
        if (res && !res.errors) {
          this.commentDetails = res.data;
          this.loaderService.hide();
        }
      }, (error) => {
        this.loaderService.hide();
      });
  }

  /**
  * API Call for submit comment
  */
  submitComment() {
    let payloads = `id=${this.pictureId}&comment=${this.commentForm.value.comment}`;
    if (this.showSignInMember) {
      let guestbookId;
      if (!this.userData) {
        guestbookId = JSON.parse(sessionStorage.getItem('memberGuestLike'))?.guestbook_id;
      } else {
        guestbookId = JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id;
      }
      payloads = payloads + `&guest_book_id=${guestbookId}`;
      this.addGuestbookService
        .addCommentsByGuestbook(payloads)
        .subscribe((res: any) => {
          if (res && !res.errors) {
            this.commentDetails.comments.data.unshift(res.data);
            this.commentDetails.comments.total++;
            this.commentForm.reset();
          }
        });
    } else {
      this.addGuestbookService
        .addCommentsByUser(payloads)
        .subscribe((res: any) => {
          if (res && !res.errors) {
            this.commentDetails.comments.data.unshift(res.data);
            this.commentDetails.comments.total++;
            this.commentForm.reset();
          }
        });
    }
  }

  calculateImageLike(like){
    if (like === MagicNumbers.zero) {
          this.commentDetails.my_like = MagicNumbers.one;
          this.commentDetails.likes_count++;
        } else {
          this.commentDetails.my_like = MagicNumbers.zero;
          this.commentDetails.likes_count--;
        }
    
  }


  /**
  * API Call for update like status
  */
  likeStatus(like) {
    if (this.showCommentInput) {
      if (this.showSignInMember) {
        this.calculateImageLike(like);
        const payloads = `id=${this.pictureId}&guest_book_id=${JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id}
        &like=${like === MagicNumbers.zero ? MagicNumbers.one : MagicNumbers.zero}`;
        this.addGuestbookService
          .addLikeStatusByGuestbook(payloads)
          .subscribe((res: any) => {
            if (res && !res.errors) { /* TODO document why this block is empty */ }
          });
      } else {
        this.calculateImageLike(like);
        const payloads = `id=${this.pictureId}&like=${like === MagicNumbers.zero ? MagicNumbers.one : MagicNumbers.zero}`;
        this.addGuestbookService
          .addLikeStatusByMember(payloads)
          .subscribe((res: any) => {
            if (res && !res.errors) { /* TODO document why this block is empty */ }
          });
      }
    }

  }

  calculateCountsLikes(comment) {
    if (this.likeStatusValue !== MagicNumbers.zero) {
      comment.likes = [];
      comment.likes.push({ like: this.likeStatusValue });
      comment.likes_count++;
    } else {
      comment.likes = [];
      comment.likes_count--;
    }
  }

  calculateLikeNumbersUser(comment) {
    this.likePayloadsUser = `id=${comment?.id}&guest_book_id=${JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id}`;
    if (comment?.likes.length) {
      if (comment?.likes[MagicNumbers.zero]?.like === MagicNumbers.two) {
        this.likePayloadsUser = this.likePayloadsUser + `&like=${MagicNumbers.one}`;
        this.likeStatusValue = MagicNumbers.one;
        comment.dislikes_count--;
      } else {
        this.likePayloadsUser = this.likePayloadsUser + `&like=${MagicNumbers.zero}`;
        this.likeStatusValue = MagicNumbers.zero;
      }
    } else {
      this.likePayloadsUser = this.likePayloadsUser + `&like=${MagicNumbers.one}`;
      this.likeStatusValue = MagicNumbers.one;
    }
  }

  calculateLikeNumbersMember(comment) {
    this.likePayloadsMember = `id=${comment?.id}`;
    if (comment?.likes.length) {
      if (comment?.likes[MagicNumbers.zero]?.like === MagicNumbers.two) {
        this.likePayloadsMember = this.likePayloadsMember + `&like=${MagicNumbers.one}`;
        this.likeStatusValue = MagicNumbers.one;
        comment.dislikes_count--;
      } else {
        this.likePayloadsMember = this.likePayloadsMember + `&like=${MagicNumbers.zero}`;
        this.likeStatusValue = MagicNumbers.zero;
      }
    } else {
      this.likePayloadsMember = this.likePayloadsMember + `&like=${MagicNumbers.one}`;
      this.likeStatusValue = MagicNumbers.one;
    }
  }
  /**
   * funcation to calaculate payloads for like
   */
  likeComment(comment) {
    if (this.showCommentInput) {
      if (this.showSignInMember) {
        this.calculateLikeNumbersUser(comment);
        this.calculateCountsLikes(comment);
        this.apiHitForLikeCommentUser(this.likePayloadsUser);
      } else {
        this.calculateLikeNumbersMember(comment);
        this.calculateCountsLikes(comment);
        this.apiHitForLikeCommentMember(this.likePayloadsMember);
      }
    }
  }

  calculateCountsDislikes(comment: any) {
    if (this.likeStatusValue !== MagicNumbers.zero) {
      comment.likes = [];
      comment.likes.push({ like: this.likeStatusValue });
      comment.dislikes_count++;
    } else {
      comment.likes = [];
      comment.dislikes_count--;
    }
  }

  calculateDislikeNumbersUser(comment: any) {
    this.disLikePayloads = `id=${comment?.id}&guest_book_id=${JSON.parse(localStorage.getItem('memberGuestLike'))?.guestbook_id}`;
    if (comment?.likes.length) {
      if (comment?.likes[MagicNumbers.zero]?.like === MagicNumbers.one) {
        this.disLikePayloads = this.disLikePayloads + `&like=${MagicNumbers.two}`;
        this.likeStatusValue = MagicNumbers.two;
        comment.likes_count--;
      } else {
        this.disLikePayloads = this.disLikePayloads + `&like=${MagicNumbers.zero}`;
        this.likeStatusValue = MagicNumbers.zero;
      }
    } else {
      this.disLikePayloads = this.disLikePayloads + `&like=${MagicNumbers.two}`;
      this.likeStatusValue = MagicNumbers.two;
    }
  }

  calculateDislikeNumbersMember(comment: any) {
    this.disLikePayloadsMember = `id=${comment?.id}`;
    if (comment?.likes.length) {
      if (comment?.likes[MagicNumbers.zero]?.like === MagicNumbers.one) {
        this.disLikePayloadsMember = this.disLikePayloadsMember + `&like=${MagicNumbers.two}`;
        this.likeStatusValue = MagicNumbers.two;
        comment.likes_count--;
      } else {
        this.disLikePayloadsMember = this.disLikePayloadsMember + `&like=${MagicNumbers.zero}`;
        this.likeStatusValue = MagicNumbers.zero;
      }
    } else {
      this.disLikePayloadsMember = this.disLikePayloadsMember + `&like=${MagicNumbers.two}`;
      this.likeStatusValue = MagicNumbers.two;
    }
  }
  /**
  * funcation to calaculate payloads for dislike
  */
  disLikeComment(comment) {
    if (this.showCommentInput) {
      if (this.showSignInMember) {
        this.calculateDislikeNumbersUser(comment);
        this.calculateCountsDislikes(comment);
        this.apiHitForLikeCommentUser(this.disLikePayloads);
      } else {
        this.calculateDislikeNumbersMember(comment);
        this.calculateCountsDislikes(comment);
        this.apiHitForLikeCommentMember(this.disLikePayloadsMember);
      }
    }
  }
  /**
* API Call for update like in comment
*/
  apiHitForLikeCommentUser(payloads) {
    this.addGuestbookService
      .addLikeCommentStatusByUser(payloads)
      .subscribe((res: any) => {
        if (res && !res.errors) { /* TODO document why this block is empty */ }
      });
  }

  apiHitForDeleteComment(payloads,i){
    this.commentDetails?.comments?.data.splice(i,1);
    this.show =-1;
    this.commentDetails.comments.total--;
    if (this.showCommentInput) {
    this.addGuestbookService
      .deleteComment(payloads)
      .subscribe((res: any) => {
        if (res && !res.errors) { 
          this.toaster.success(res.message);
          /* TODO document why this block is empty */ }
      });
    }
  }
  /**
 * API Call for update like in comment
 */
  apiHitForLikeCommentMember(payloads) {
    this.addGuestbookService
      .addLikeCommentStatusByMember(payloads)
      .subscribe((res: any) => {
        if (res && !res.errors) {
           /* TODO document why this block is empty */ }
      });
  }

  /**
 * To open the vide in new tab
 * @param doc is response of list
 */
  showDoc(doc: string) {
    window.open(doc, '_blank');
  }
}
