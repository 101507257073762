<div class="modal-dialog modal-fullscreen advance-search-modal modal-dialog-centered">
    <div class="modal-content advance-modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalRef.hide()"
                aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
            <h1 class="text-center modal-h1">Search your l<img src="../assets/images/heart-fill.svg"
                    alt="heart-fill" />ved one’s memorial</h1>

            <button type="button" class="btn link-btn btn-border"
                (click)="isPersonal=true;searchObj=null;selectedState=false"
                [ngClass]="isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">Personal
                Details</button>
            <button type="button" class="btn link-btn btn-border memory-code" (click)="isPersonal=false;searchObj=null"
                [ngClass]="!isPersonal?'btn link-btn btn-border active':'btn link-btn btn-border'">QR Memory Code
                Number</button>
            <div class="modal-form">
                <div class="container res-p-0">
                    <form class="search-results-form">
                        <ng-container *ngIf="isPersonal">
                            <div class="form-floating form-floating-search person-name-input">
                                <input type="text" class="form-control right-margin-0" id="name" placeholder="Name"
                                    [(ngModel)]="searchObj.name" name="name" maxlength="30" autocomplete="off" />
                                <label for="name">Person’s Name</label>
                            </div>
                            <div class="form-floating form-floating-search birth-input">
                                <input type="text" class="form-control" id="birthDate" placeholder="Birth Date"
                                    autocomplete="off" aria-label="Birth Date" bsDatepicker readonly
                                    (click)="addClass('birthDate bs-datepicker theme-green')"
                                    [(ngModel)]="searchObj.birthDate" name="birthDate"
                                    (bsValueChange)="onValueChange($event,'dob')" [maxDate]="today"
                                    [bsConfig]="{dateInputFormat: 'MM/DD/YYYY', showWeekNumbers:false}" />
                                <label for="birthDate">Birth Date </label>
                            </div>
                            <div class="form-floating form-floating-search death-input">
                                <input type="text" class="form-control right-margin-0" id="deathDate"
                                    placeholder="Day of Passing" autocomplete="off" aria-label="Death Date" bsDatepicker
                                    readonly [(ngModel)]="searchObj.deathDate" name="deathDate"
                                    (click)="addClass('deathDate bs-datepicker theme-green')"
                                    (bsValueChange)="onValueChange($event,'dod')" [minDate]="maxDate" [maxDate]="today"
                                    [bsConfig]="{dateInputFormat: 'MM/DD/YYYY',showWeekNumbers: false}" />
                                <label for="deathDate">Day of Passing</label>
                            </div>
                            <div class="form-floating state-input right-margin-0">
                                <select class="form-select" id="floatingSelect" [(ngModel)]="searchObj.state"
                                    [style.color]="selectedState?'#222222':''" name="state"
                                    aria-label="Floating label select example" (change)="selectedState=true">
                                    <option [value]="state.id" *ngFor="let state of states" [hidden]="!state?.name">
                                        {{state.name}}

                                </select>
                                <label for="floatingSelect" [ngClass]="selectedState?'removeMar':'state-label'"
                                    class="">State</label>
                            </div>
                        </ng-container>

                        <div class="form-floating form-floating-search code-number-floating" *ngIf="!isPersonal">
                            <input type="text" class="form-control code-number-input right-margin-0" id="name"
                                placeholder="Name" [(ngModel)]="searchObj.memoryCodeNumber" maxlength="12"
                                name="memoryCodeNumber" autocomplete="off" />
                            <label for="name">QR Memory Code Number</label>
                        </div>
                        <div class="search-popup-input search-btn-input">
                            <button type="button" class="btn btn-primary btn-padding search-btn"
                                (click)="goToSearch('search')">Search
                                Memorial</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>