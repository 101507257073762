import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AddGuestbookService } from '../../../common/services/add-guestbook.service';
import { CommonService } from '../../../common/services/common.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-select-guestbook-modal',
  templateUrl: './select-guestbook-modal.component.html',
  styleUrls: ['./select-guestbook-modal.component.scss']
})
export class SelectGuestbookModalComponent implements OnInit {
  public userData: any;
  public destroy$: Observable<any>;
  public memberID: string;
  public guestbookUser: any = [];
  public selectAll = false;
  public sortStatusName = 'asc';
  public searchText = '';
  public payload: any;
  public selectedUser: any=[];
  public sortedUserSelected: any;
  public sortStatusRelation = 'asc';
  public totalCount: any=0;
  public page: any = 1;
  public totalAllCount: any;
  @Output() onClose = new EventEmitter();
  guestbookUserNew: any=[];
  guestbookUserDetails: any= [];
  newData: any[];
  allSelected: boolean;
  selectBookUser: any;
  searchTexts = '';
  users: any=  [
  ];
  notSelect: boolean;
  hitAlways: boolean;
  count: any;
  hideCheckbox=false;
  constructor(
    public commonService: CommonService,
    public addGuestbookService: AddGuestbookService,
    public modalRef: BsModalRef
  ) {
    this.memberID = localStorage.getItem('member_id');
  }

  ngOnInit(): void {
    const payload = `&page=${this.page}`;
    this.guestbookUserDetails = [];
    this.getGuestUserData(payload);
  }

  getGuestUserSearch(value?, sort?) {
    if (value) {
      this.payload = `member_id=${this.memberID}` + value;
    } else {
      this.payload = `member_id=${this.memberID}`;
    }
    this.sortedUserSelected = this.guestbookUser;
    this.addGuestbookService.getGuestUser(this.payload).subscribe((res: any) => {
      if (res) {
        this.guestbookUser = res.data.data;
        this.users = res.data.data;
        this.users.forEach(element => element['checked'] = false);
        this.selectBookUser = res.data.total;
        if(this.guestbookUser.length!==0){
          this.guestbookUserDetails?.forEach(element => {
            this.guestbookUser.forEach((ele, index) => {
              if (element.id === ele.id) {
                ele.selected = element.selected;
              }
            });
          });
        }
      }
    });
  }

  getGuestUserData(value?, sort?) {
    if (value) {
      this.payload = `member_id=${this.memberID}` + value;
    } else {
      this.payload = `member_id=${this.memberID}`;
    }
    this.sortedUserSelected = this.guestbookUser;
    this.addGuestbookService.getGuestUser(this.payload).subscribe((res: any) => {
      if (res) {
        this.totalAllCount = res?.data?.total;
        if(!sort){
          this.guestbookUser = res.data.data || [];
          this.users = res.data.data;
          this.users.forEach(element => element['checked'] = false);
          this.guestbookUser.forEach(element => element['checked'] = false);
          const Value = localStorage.getItem('selectedUser');
          this.guestbookUserDetails = Value ? JSON.parse(Value).values : [];
          if(this.guestbookUserDetails.length!==0){
            this.guestbookUserDetails.forEach(element => {            
              this.users.forEach(ele => {
                if(element.id === ele.id){
                  ele.checked = element.checked;
                }
              });
              this.guestbookUser.forEach(ele => {
                if(element.id === ele.id){
                  ele.checked = element.checked;
                }
              });
            });
            const filteredData = this.users.filter((filter) => filter.checked === true);
            this.allSelected = this.users.every((select) => select.checked === true);
            this.totalCount = filteredData.length;
          }
        }else{
          if(!this.searchText){
            this.users.forEach(ele => {
              res.data.data.forEach(element => {
                if(element.id === ele.id){
                  element.checked = ele.checked;
                }
              });
            });
            this.users = [];
            this.users = res.data.data;
            this.guestbookUser = res.data.data;
          }
        }
      }
    });
  }

  selectedUserCall(sort) {
    if (sort) {
      this.selectedUser = this.sortedUserSelected.filter((res) => res.selected === true);
    } else {
      const value = localStorage.getItem('selectedUser');
      this.selectedUser = value ? JSON.parse(value).values : [];
      this.totalCount = this.selectedUser?.length;
    }
    if (this.selectedUser?.length !== 0) {
      this.selectedUser?.forEach(element => {
      this.guestbookUser.forEach((ele, index) => {
        if (ele.selected === false && element.id === ele.id) {
          ele.selected = element.selected;
        }
      });
    });
    const newData = [...this.guestbookUserNew,...this.guestbookUser];
    this.guestbookUser = [];
    this.guestbookUser = newData;
      for (const item of this.guestbookUser) {
        if (!item.selected) {
          this.selectAll = false;
          break;
        } else {
          this.selectAll = true;
        }
      }
    }
  }

  itemChanged(event, i,user) {
    this.guestbookUser[i].selected = event.target.checked;
    if(event.target.checked){
      this.guestbookUserDetails.push(user);
    }else{
      this.guestbookUser[i].selected = event.target.checked;
      this.guestbookUserDetails.forEach((element,index) => {
        if(element.id === user.id){
          this.guestbookUserDetails.splice(index,1);
        }
      });
    }

    if(this.selectBookUser !== 1){
    for (const item of this.guestbookUser) {
      if (!item.selected) {
        this.selectAll = false;
        break;
      } else {
        this.selectAll = true;
      }
    }
    }
    this.totalCount = event.target.checked ? this.totalCount + 1: this.totalCount -1;
  }

  selectAllChanged() {
    for (const item of this.guestbookUser) {
      item.selected = this.selectAll;
    }
    const filteredData = this.guestbookUser.filter((res) => res.selected === true);
    this.totalCount = filteredData.length;
    this.allSelected = true;
  }

  submit() {
    const selectedGuestUser = this.users.filter((res) => res.checked === true);
    const guestUser = this.guestbookUser.filter((res) => res.checked === true);
    const union = [...new Set([...selectedGuestUser, ...guestUser])];
    const data = {
     values: union,
     close:true
    };
    if(union.length !==0){
      localStorage.setItem('selectedUser', JSON.stringify(data));
    }else{
      localStorage.removeItem('selectedUser');
    }
    this.onClose.next(data);
    this.modalRef.hide();
  }

  sort(value, filter) {
    const filterWith = filter;
    if (filter === 'name') {
      this.sortStatusName = value === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortStatusRelation = value === 'desc' ? 'asc' : 'desc';
    }
    const payloads = `&order=${value}&order_by=${filterWith}`;
    this.getGuestUserData(payloads, 'sort');
  }





  onInput(value) {
    if (value.target.value === '') {
       const oldUserData = this.users;
       this.users = this.guestbookUser;
       this.users.forEach((item1) => {
        oldUserData.forEach((item2) => {
          if (item1.id === item2.id) {
            item1.checked = item2.checked;
          }
        });
      });
       this.allSelected = this.users.length !==0 ? this.users.every((res) => res.checked === true) : false;
       this.hideCheckbox = false;
    }
  }


  selectAlls(checked: any) {
    this.users.forEach(item => item.checked = checked.target.checked);
    if(this.searchText && checked.target.checked === true){
      this.totalCount = this.users.length;
      this.allSelected = true;
    }else if(this.searchText && checked.target.checked === false){
      this.totalCount = 0;
      this.allSelected = false;
    } else{
      this.guestbookUser.forEach(item => item.checked = checked.target.checked);
      this.checkAllSelected();
    }
  }
  selectAllValue(event){
    if(this.searchText && event.target.checked === true){
      this.totalCount = this.users.length;
      this.allSelected = true;
    }else if(this.searchText && event.target.checked === false){
      this.totalCount = 0;
      this.allSelected = false;
    }else{
      this.allSelected = this.users.every((res) => res.checked === true);
      this.totalCount = event.target.checked ? this.guestbookUser.length : 0;
    }
  }

  checkAllSelected(user?) {
    this.allSelected = this.users.every(item => item.checked) && this.guestbookUser.every(item => item.checked);
  }
  checkAll(event){
    this.totalCount = event.target.checked ? this.totalCount + 1: this.totalCount -1;
   const condition = this.users.every(ele=> ele.checked);
    if(this.searchText && condition){
      this.allSelected = true;
    }else{
      if(this.totalCount === this.totalAllCount){
        this.allSelected = true;
      }else{
        this.allSelected = false;
      }
    }
  }

 search() {
   this.users =  this.guestbookUser.filter((item) => item.name?.toLowerCase().includes(this.searchText.toLowerCase()) ||
    item.relation?.toLowerCase().includes(this.searchText.toLowerCase()));
    if(this.searchText){
      this.allSelected = this.users.length === 0 ? false : this.users.every((res) => res.checked === true);
    }
    this.hideCheckbox = true;
  }
}
