import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SigninComponent } from '../signin/signin.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../common/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../common/services/auth.service';
import { MagicNumbers } from '../../../common/component/app-magic-numbers';
import { IResponse } from '../../../../app/common/interfaces/response';
import { LandingPageService } from '../../../common/services/landing-page.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };
  public states;
  public searchName;
  public searchObj = { name: undefined, birthDate: undefined, deathDate: undefined, state: null, memoryCodeNumber: undefined, type: undefined };
  public isPersonal = true;
  public hideBigBrand = false;
  public minDate = new Date();
  public maxDate = new Date();
  public today = new Date();
  public selectedState;
  public token;
  public email;
  public scannerEnabled = false;
  @ViewChild('advacedModal', { static: false }) advacedModal;
  adDetails: any;
  topAdImage: any;
  topAdUrl: any;
  bottomAdImage: any;
  bottomAdUrl: any;
  topAdId: any;
  bottomAdId: any;
  public className = 'signin-modal';
  public showNav = false;
  public overlayClass = 'overlay-hidden';

  constructor(
    public router: Router, 
    public commonStateService: CommonService, 
    public landingService: LandingPageService, 
    public toastr: ToastrService, 
    public activatedRoute: ActivatedRoute,
    public modalService: BsModalService, 
    public authService: AuthService
    ) {
    this.commonStateService.changeBrandIcon.next(true);
    this.activatedRoute.queryParams.subscribe(querParams => {
      if (querParams.verify) {
        this.token = querParams.verify;
        this.email = querParams.email;
        this.verifyUser();
      }
      // To open the sign in component
      if (querParams.login) {
        this.modalService.show(SigninComponent, { class: 'signin-modal' });
      }
    });
  }

  ngOnInit(): void {
    this.getAdData();
  }

  openSignUp() {
    this.modalService.show(SignUpComponent, { class: this.className });
  }

  showNavBar(){
    this.showNav =false;
    document.getElementById(this.overlayClass)?.classList.remove('scroll-stop');
  }

  /**
   * API Call for states list
  */
  getStates() {
    this.states = [];
    this.searchObj.state = null;
    this.landingService.getStates().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
        setTimeout(() => {
          this.searchObj.state = null;
          this.selectedState = false;
        }, MagicNumbers.oneHundred);
      }
    });
  }
  /**
   * To navigate search results component
  */
  goToSearch(type?) {
    if (this.searchCondition()) {
      this.searchObjType(); 
      this.searchRoute(type);
    } else if (!type || !this.isPersonal) {
      this.toastr.error(`Please enter ${this.isPersonal ? 'name' : 'QR Memory code number'}`);
    } else {
      this.searchRoute(type);
    }
  }

  /**
   * Route to search result page
   */
  searchRoute(type){
    this.commonStateService.searchData.next(this.searchData(type));
    this.router.navigate(['/search-results']);
  }

  /**
   * Pass value in searchObj on based of ispersonal
   */
   searchObjType(){
     if (this.isPersonal) {
       this.searchObj.type = 'personal_detail';
     } else {
       this.searchObj.type = 'memory_code';
     }
   }
  /**
  * Search Condition
  */
  searchCondition(){
   return (this.searchName || this.searchObj.name || this.searchObj.deathDate || this.searchObj.birthDate || this.searchObj.state || this.searchObj.memoryCodeNumber);
  }

  /**
   * share data on search data
   */

  searchData(type){
   return (type ? this.searchObj : { type: this.searchObj.type, [this.isPersonal ? 'name' : 'memoryCodeNumber']: this.searchName });
  }
  /**
   * To listen the event on window scroll
  */
  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    if (window.pageYOffset > MagicNumbers.fifty) {
      this.hideBigBrand = true;
      this.commonStateService.changeBrandIcon.next(false);
    } else {
      this.hideBigBrand = false;
      this.commonStateService.changeBrandIcon.next(true);
    }

  }
  /**
   * To listen the event on window resize
  */
  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth <= MagicNumbers.innerWidth) {
      this.hideBigBrand = true;
      this.commonStateService.changeBrandIcon.next(false);
    } else {
      this.hideBigBrand = false;
      this.commonStateService.changeBrandIcon.next(true);
    }
  }
  /**
   * On value change of date picker
   * @param e and @param type is required
  */
  onValueChange(e, type) {
    if (type === 'dob') {
      this.maxDate = e;
    } else {
      this.minDate = e;
    }
  }
  /**
   * API Call for verifying the user
  */
  verifyUser() {
    const payload = {
      token: this.token,
      email: this.email
    };
    this.authService.verifyUser(payload).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.toastr.success(res.message);
        this.modalService.show(SigninComponent, { class: 'signin-modal' });
      }
    });
  }
  /**
   * To add the class for birth date picker
  */
  addClass(className) {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', className);
    }, 0);

  }

  getAdData() {
    this.landingService.getAdData().subscribe((res: any) => {
      if (res && !res.errors) {
        this.adDetails = res.data;
        if(this.adDetails.length){
      for (const item of this.adDetails) {
        if (item.placement === MagicNumbers.one) {
          this.topAdId = item.id;
            this.topAdImage = item.image;
            this.topAdUrl = item.url;
        }
        if (item.placement === MagicNumbers.two) {
          this.bottomAdId = item.id;
          this.bottomAdImage = item.image;
          this.bottomAdUrl = item.url;
      }
      }
    }
      }
    });
  }

  adClick(id,url){
    this.landingService.adClick(id).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.goToLink(url);
      }
    });
  }

  goToLink(urlToOpen) {
    let url = '';
    if (!/^http[s]?:\/\//.test(urlToOpen)) {
    url += 'http://'+ urlToOpen;
    } else{
      url = urlToOpen;
    }
    window.open(url, '_blank');
  }
}
