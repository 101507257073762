<section>
    <div class="container add-member-container res-p-0">
        <div class="main-container">
            <div class="add-member-wrapper">
                <div class="header-img-infinity">
                    <img src="assets/images/add-pic-infinity.svg" alt="add-pic-infinity">
                </div>
                <div class="footer-img-heart">
                    <img src="assets/images/add-pic-heart.svg" alt="add-pic-heart">
                </div>

                <div class="about-memorial-section">
                    <div class="congratulation-wrapper pt-3 mb-4">
                        <div class="confirm-img">
                        </div>
                        <h1>QR Memory Code Info.</h1>
                        <p class="congrats-des"><span *ngIf="getMember?.name">{{getMember?.name}}’s</span> memorial have been created successfully.


                        </p>
                        <div class="copy-link">
                            <p class="url-text">{{getMember?.url}}</p>
                            <span class="ms-auto copy-img" (click)="copyUrl(getMember?.url)"><img
                                    src="assets/images/icons-copy.svg" alt="copy-logo" />
                            </span>
                            <button class="btn btn-primary open-btn"
                                (click)="viewInformation(getMember)">Open</button>
                        </div>
                        <span class="url-text-or">Or</span>
                        <div class="qr-code-section mb-3">
                            <div class="qr-code-header">
                                <span class="qr-image"><img
                                        [src]="successData?successData:'./../../../assets/images/qr-image.png'"
                                        alt="qr-image"></span>
                                <span class="">Share or affix the code to a desired memorialized location/tombstone.<p>
                                        {{getMember?.serial_number}} <span class="ms-2 copy-img cursor-pointer" (click)="copyMemoryCode(getMember?.serial_number)"><img
                                            src="assets/images/icons-copy.svg" alt="copy-logo" />
                                    </span></p></span>
                                        
                            </div>
                            <div class="qr-code-footer">
                                <a id="print-button" class="link-text" (click)="printCode()"><img
                                        src="assets/images/print-logo.svg" alt="print-logo" />Print Code</a>
                                <a class="link-text" (click)="downloadCode()"><img
                                        src="assets/images/download-logo.svg" alt="download-logo" />Download Code</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>