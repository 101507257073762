import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/interfaces/response';
import { AuthService } from '../../../common/services/auth.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  public forgotPasswordForm: FormGroup;
  constructor(public formBuiler: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef, public authService: AuthService, public toastr: ToastrService) {
    this.forgotPasswordForm = this.formBuiler.group({
      emailId: ['', [Validators.required, Validators.email]]
    });
  }
  get emailId() { return this.forgotPasswordForm.get('emailId'); }

  /**
   * API Call for sending the reset password link
  */
  onSend() {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.emailId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success(res.message);
          this.modalRef.hide();
        }
      });
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }
  /**
   * API Call for signin modal
  */
  openSignIn() {
    this.modalRef.hide();
    this.modalService.show(SigninComponent, { class: 'signin-modal' });
  }
}
