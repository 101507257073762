<section class="view-info-section chat-message-section res-pt-0">
    <div class="container">
        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity-message.svg" alt="viewinfo-infinity">
            </div>

            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart-message.svg" alt="viewinfo-heart">
            </div>

            <div class="second-border">
                <div class="chat-message-main">
                    <div class="chat-message-header">
                        <div class="user-profile-wrap">
                            <span class="user-profile-img"><img
                                    [src]="guestUser?.guestbook_user_picture || 'assets/images/user-img.svg'"
                                    alt="viewinfo-heart">
                            </span>
                            <div class="user-profiel-info">
                                <h4 class="user-profile-title">{{guestUser?.guestbook_user_name}}</h4>
                            </div>

                        </div>
                    </div>

                    <div class="chat-inner-wrap">
                        <div *ngIf="messagesList?.length != 0" class="chat-message-inner-wrap">
                            <div *ngFor="let data of messagesList" class="private-msg-card">
                                <div class="user-profile-wrap">
                                    <span class="user-profile-img"><img
                                            [src]="data?.send_by == 1 ? data?.guestbook_user?.profile_picture || 'assets/images/user-img.svg' : data?.user?.profile_picture || 'assets/images/user-img.svg'"
                                            alt="viewinfo-heart">
                                    </span>
                                    <div class="user-profiel-info">
                                        <h4 class="user-profile-title">{{data?.send_by == 1 ?
                                            'You' : data?.user?.name}}</h4>
                                    </div>
                                    <span class="chat-message-date">{{data?.created_at | date:'dd MMM yyyy'}}</span>
                                </div>
                                <p class="chat-message-des">{{data?.message}}</p>
                                <div *ngIf="data?.private_inbox_media" class="chat-upload-img-wrap">
                                    <div *ngFor="let media of data?.private_inbox_media">
                                        <img *ngIf="media?.type ==1" [src]="media?.url" alt="picture-memory-2"
                                            class="img-fluid private-msg-img"
                                            (click)="staticModal.show();previewImage=media?.url" />
                                        <video [src]="media?.url" height="100px" width="100px"
                                            (click)="showVideo(media?.url)" *ngIf="media?.url!=='' && media?.type == 2"
                                            class="upload-video view-all-memory-img">
                                            <track label="English" kind="captions" srclang="en" src="" default>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="messagesList?.length == 0" class="chat-empty-wrap">
                            <img src="assets/images/chat-empty-logo.svg" alt="chat empty" class="chat-empty-img">
                            <p class="chat-empty-des">You can send direct messages to owner of the account.</p>
                        </div>
                    </div>
                    <div class="share-message mb-0">
                        <div *ngIf="media?.length > 0" class="chat-image-input-wrap">
                            <div class="image-video-wrap" *ngFor="let item of media; let i = index">
                                <div class="chat-msg-img-vedio">
                                    <img *ngIf="item?.type === 1" [src]="item?.url" alt="photo" class="chat-msg-img">
                                    <video *ngIf="item?.type === 2" [src]="item?.url" class="selected-media-video"
                                        controls></video>
                                    <div class="add-img-del view-right-12">
                                        <img src="assets/images/icon-del-col.svg" alt="" (click)="removeMedia(i)" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <form [formGroup]="form">
                            <div class="textarea-message p-0 position-relative">
                                <div class="form-floating-add">
                                    <textarea maxlength="1000" class="form-control" id="floatingTextarea"
                                        formControlName="message" placeholder="Send Message"
                                        (input)="autoResizeTextarea($event)"></textarea>

                                </div>
                                <div
                                    *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)">
                                    <div class="invalid-input-text-neg">Message is invalid</div>
                                </div>
                            </div>
                            <div class="send-btn-wrap">
                                <img (click)="fileInput.click()" src="assets/images/attach-logo.svg" alt="attach-logo"
                                    class="attach-img">
                                <input type="file" #fileInput (change)="fileSelected($event)" accept="image/*,video/*"
                                    style="display: none;" multiple>
                                <img (click)="sendMessage()" type="submit" src="assets/images/chat-send-logo.svg"
                                    alt="send-logo" class="send-img">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-sm image-preview-modal-dialog">
        <div class="modal-content image-preview-modal-content">
            <div class="modal-body">
                <div class="image-preview-wrapper">
                    <img [src]=" previewImage" alt="picture" class="image-preview img-fluid" />
                </div>
                <button type="button" class="btn btn-link image-preview-close-btn" (click)="staticModal.hide()"><img
                        src="assets/images/image-preview-close.svg" alt="close-btn" class="img-fluid" /></button>
            </div>
        </div>
    </div>
</div>
