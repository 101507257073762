import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(public http: HttpClient) { }

  createStripeCustomer(data,status,serialNumber) {
    const payloadsA = `create-stripe-customer?full_name=${data.full_name}&email=${data.email}&profile_picture=${data.profile_picture}
      &contact_number=${data.contact_number}&address=${data.address}&card_token=${data.card_token}`;
    const payloadsB = `&zip_code=${data.zip_code}&state_id=${data.state_id}&city_id=${data.city_id}&transaction_key=${data.transaction_key}
      &card_type=${data.card_type}`;
    const payloadsC =`&status=${data.status}&tran_created_at=${data.tran_created_at}&amount=${data.amount}
      &plan_type=${data.plan_type}&deliver_code_type=${data.deliver_code_type}`;
    if(status){
      return this.http.post(`${environment.apiUrl}${payloadsA}${payloadsB}${payloadsC}${serialNumber}`, '');
    }else{
      return this.http.post(`${environment.apiUrl}${payloadsA}${payloadsB}${payloadsC}`, '');
    }
  }
  upgradePlan(data) {
    const payloadA = `upgraded?card_number=${data.card_number}&exp_month=${data.exp_month}&`;
    const payloadB = `exp_year=${data.exp_year}&cvc=${data.cvc}&card_type=${data.card_type}`;
    return this.http.post(`${environment.apiUrl}${payloadA}${payloadB}`, '');
  }

  createCardFromToken(data) {
    return this.http.post(`${environment.apiUrl}save-card?card_number=${data.cardNumber}&exp_year=${data?.expYear}
      &exp_month=${data.expMonth}&cvc=${data.cardCvv}&member_id=${data.memberId}`, '');
  }

  cancelSubPlan(id) {
    return this.http.post(`${environment.apiUrl}cancel-subscription?member_id=${id}`, '');
  }

  upgradPlan(id) {
    return this.http.post(`${environment.apiUrl}upgrade-downgrade-subscription?plan_type=2&member_id=${id}`, '');
  }
  continueCurrentPlan(value, id) {
    return this.http.post(`${environment.apiUrl}upgrade-downgrade-subscription?plan_type=${value}&member_id=${id}`, '');
  }

  downgradePlan(id) {
    return this.http.post(`${environment.apiUrl}upgrade-downgrade-subscription?plan_type=1&member_id=${id}`, '');
  }
}
