<div class="signin-modal-content change-password-content">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body signin-body change-password-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title edit-title">Edit Profile</h1>
            <div class="signin-container">
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 ">
                    <div class="title-right-sec new-input-file-wrap">
                        <div *ngIf="!profilePic" class="add-pic add-picture-section  add-pic-left"><img
                                src="assets/images/add-picture-light.svg" alt="add-pic" />Profile Picture
                            <input type="file" title="" id="logoUpload" (change)="uploadProfilePic($event)"
                                class="custom-input-file" formControlName="profileImage"
                                accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                        </div>
                        <div *ngIf="profilePic" class="add-pic add-picture-section  add-pic-left"><img
                                [src]="profilePic" alt="add-pic" class="uploaded-img" />
                            <input type="file" title="" id="logoUpload" (change)="uploadProfilePic($event)"
                                class="custom-input-file" formControlName="profileImage"
                                accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                        </div>
                        <span class="support-file">(jpeg, png, and jpg only<br />
                            upto 10 mb)</span>

                    </div>
                </div>
                <form class="login-container change-password-container" [formGroup]="editProfile">
                    <div class="form-floating "
                        [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)?'invalid-input':''">
                        <input type="text" class="form-control" id="fullName" formControlName="fullName"
                            placeholder="name@example.com" maxlength="50">
                        <label for="fullName">Full Name</label>
                        <button type="button" class="btn btn-link edit-btn"
                            (click)="isFullNameEdit=!isFullNameEdit;enableFields('fullName',isFullNameEdit)">Edit</button>
                    </div>
                    <ng-container *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched)">
                        <span class="invalid-input-text" *ngIf="fullName?.errors?.required">Name is required</span>
                    </ng-container>
                    <div class="modal-update-btn" *ngIf="isFullNameEdit">
                        <button type="button" class="btn btn-outline-primary btn-primary-cancel"
                            (click)="isFullNameEdit=false;enableFields('fullName',isFullNameEdit)">cancel</button>
                        <button type="submit" (click)="updateProfile('fullName')"
                            class="btn btn-primary btn-primary-update">update</button>
                    </div>

                    <div class="form-floating form-floating-emialid"
                        [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                        <input type="email" class="form-control" id="emailId" formControlName="emailId"
                            placeholder="name@example.com">
                        <label for="emailId">Email ID</label>
                        <button type="button" class="btn btn-link edit-btn"
                            (click)="isEmailEdit=!isEmailEdit;enableFields('emailId',isEmailEdit)">Edit</button>
                    </div>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</span>
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.pattern">Email is not valid</span>
                    </ng-container>
                    <p class="verify-btn-des" *ngIf="isEmailEdit">A link will be sent to your new email address for
                        verification.</p>
                    <div class="modal-update-btn" *ngIf="isEmailEdit">
                        <button type="button" class="btn btn-outline-primary btn-primary-cancel"
                            (click)="isEmailEdit=false;enableFields('emailId',isEmailEdit)">cancel</button>
                        <button type="submit" (click)="updateProfile('email')"
                            class="btn btn-primary btn-primary-update">verify</button>
                    </div>


                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>
