<!-- forgot password start here -->
<!-- Button trigger modal -->
<!-- Modal -->
<div class="signin-modal-content">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body signin-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
				alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title">Forgot Password?</h1>
            <p class="signin-para">Enter your registered email address and we’ll mail you the Reset password instructions
            </p>
            <div class="signin-container">
                <form class="login-container" [formGroup]="forgotPasswordForm" (ngSubmit)="onSend()">
                    <div class="form-floating mb-0" [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)?'invalid-input':''">
                        <input type="text" class="form-control" id="emailId" placeholder="name@example.com" formControlName="emailId">
                        <label for="emailId">Email ID</label>
                    </div>
                    <ng-container *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched)">
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.required">Email is required</span>
                        <span class="invalid-input-text" *ngIf="emailId?.errors?.email">Email is not valid</span>
                    </ng-container>
                    <div class="form-action">
                        <button class="btn btn-primary" type="submit">Send Reset Password Link</button>
                    </div>
                    <p class="signup-para text-center">Back to <a class="signup-link" (click)="openSignIn()">Sign In</a></p>

                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart" class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>