<div class="signin-modal-content change-password-content">
    <div class="modal-header  add-sales-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body export-body change-password-body">
        <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
            alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="modal-export-title">Export Guest List</h1>
            <h1 class="modal-export-subtitle">Export all data till date or select a date range.</h1>
        <form  class="modal-form" [formGroup]="exportSales" (ngSubmit)="submitProfile()">
            <div class="form-floating form-floating-search  birth-input date-picker-sec">
                <mat-form-field appearance="fill">
                    <mat-label>Select Date Range</mat-label>
                    <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="tomorrow"  [disabled]="true">
                      <input matStartDate placeholder="MM/DD/YY - MM/DD/YY" formControlName="start_date">
                      <input matEndDate formControlName="end_date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [disabled]="false" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker  [disabled]="false" #picker></mat-date-range-picker>
                  </mat-form-field>
                  <mat-error class="invalid-input-text-date" *ngIf="exportSales.controls.start_date.invalid && exportSales.controls.start_date.touched">
                    Start date is required.
                </mat-error>
                <mat-error class="invalid-input-text-date" *ngIf="!exportSales.controls.start_date.invalid && exportSales.controls.start_date.touched && exportSales.controls.end_date.invalid && exportSales.controls.end_date.touched">
                    End date is required.
                </mat-error>
            </div>
            
            <button type="submit" [disabled]="exportSales.invalid" class="btn btn-primary export-button">Export</button>
        </form>
        <span class="memory-code-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
            class="modal-footer-img" /></span>
    </div>
</div>