import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IResponse } from '../../../../app/common/interfaces/response';
import { LandingPageService } from '../../../common/services/landing-page.service';
import { CreateAccountComponent } from '../create-account/create-account.component';

@Component({
  selector: 'app-memory-code-required',
  templateUrl: './memory-code-required.component.html',
  styleUrls: ['./memory-code-required.component.scss']
})
export class MemoryCodeRequiredComponent {
  public memoryCodeForm: FormGroup;
  @Output() onClose = new EventEmitter();
  constructor(public modalService: BsModalService, public landingPageService: LandingPageService, public modalRef: BsModalRef,
    public formBuiler: FormBuilder) {
    this.memoryCodeForm = this.formBuiler.group({
      memoryCode: ['', [Validators.required,Validators.pattern(/[a-zA-Z0-9\s-]+$/)]]
    });
  }


  get memoryCode() { return this.memoryCodeForm.get('memoryCode'); }

  /**
   * API Call for checking the qr memory code
  */
  onContinue() {
    this.memoryCodeForm.value.memoryCode = this.memoryCode.value.replace(/\s+/g, '');
    if (this.memoryCodeForm.valid) {
      const data = {
        close: true,
        memoryCodeForm: this.memoryCodeForm.value.memoryCode
      };
      this.onClose.next(data);
      this.modalRef.hide();
    } else {
      this.memoryCodeForm.markAllAsTouched();
    }
  }
}
