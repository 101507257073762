import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../common/interfaces/response';
import { MagicNumbers } from '../../../common/component/app-magic-numbers';
import { AuthService } from '../../../common/services/auth.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {
  public memoryCode;
  public createAccountForm: FormGroup;
  public isSubmitted = false;
  public isShow = false;
  constructor(public modalService: BsModalService, public modalRef: BsModalRef, public formBuilder: FormBuilder, public authService: AuthService, public toaster: ToastrService) {
    this.createAccountForm = this.formBuilder.group({
      fullName: ['', [Validators.required,Validators.maxLength(MagicNumbers.fifty),Validators.pattern(/^(?![\d ]*$)[a-zA-Z ]+$/)]],
      emailId: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{8,20}$'),
        Validators.maxLength(MagicNumbers.twenty)
      ]],
    });
  }


  get fullName() { return this.createAccountForm.get('fullName'); }
  get emailId() { return this.createAccountForm.get('emailId'); }
  get password() { return this.createAccountForm.get('password'); }
  get accept() { return this.createAccountForm.get('accept'); }
  /**
     * To open the signin modal
    */
  openSignIn() {
    this.modalRef.hide();
    this.modalService.show(SigninComponent, { class: 'signin-modal' });
  }
  /**
   * API Call for signup 
  */
  onSignUp() {
    this.isSubmitted = true;
    if (this.createAccountForm.valid && this.createAccountForm.value.accept === true) {
      const payload = {
        name: this.createAccountForm.value.fullName,
        email: this.createAccountForm.value.emailId,
        password: this.createAccountForm.value.password,
        serial_number: this.memoryCode
      };
      this.authService.register(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toaster.success(res.message);
          this.modalRef.hide();
        } else {
          this.toaster.error(res.message);
        }
      });
    } else {
      this.createAccountForm.markAllAsTouched();
    }
  }
}
