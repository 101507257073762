import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from '../../services/member.service';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-mark-as-returned',
  templateUrl: './mark-as-returned.component.html',
  styleUrls: ['./mark-as-returned.component.scss']
})
export class MarkAsReturnedComponent implements OnInit {
  public showPass = false;
  public data;
  public soldEvent = new EventEmitter();
  showCountryCode = true;
  returnReasons: any;
  @Output() onClose = new EventEmitter();
  selectedOption: any;
  show = true;
  textAreaValue ='';
  inputAreaOn = false;
  paramsReturn: string;
  selectedState = false;
  
  public markAsReturnedCodeForm: FormGroup;
  id: any;
  memberId: any;
  serialNumber: any;

  constructor(public formBuilder: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef, 
    public memberService: MemberService, public toaster: ToastrService, public activatedRoute: ActivatedRoute) {
    this.markAsReturnedCodeForm = this.formBuilder.group({
      retReasonId: [null, [Validators.required]],
      retReasonComment: ['']
    });
  }

  ngOnInit(): void {
    this.getReturnReason();
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
      }
    });

    if (this.modalRef.content.memberId) {
      this.memberId = this.modalRef.content.memberId;
    }
  }

  getReturnReason() {
    this.memberService.getReturn().subscribe((res) => {
      this.returnReasons = res['data'];      
    });
  }

  selectOption(event) {
    this.markAsReturnedCodeForm.patchValue({retReasonId:event.target.value});
    if(event.target.value == MagicNumbers.two){
      this.inputAreaOn = true;
      this.markAsReturnedCodeForm.patchValue({retReasonComment:''});
    } else{
      this.inputAreaOn = false;
      this.show = false; 
    }
  }
  onChangevalue(){
    if(!this.textAreaValue){
      this.show = false;
    }else{
      this.show = true;
    }
  }

  markAsReturn() {

    if(!this.markAsReturnedCodeForm.value.retReasonId){
      this.toaster.error('','Please Select Issue');
      return;
    }

    if(this.inputAreaOn === true && !this.markAsReturnedCodeForm.value.retReasonComment){
      this.toaster.error('','Please Enter Your Issue');
      return;
    }

    if (this.markAsReturnedCodeForm.valid) {
      const data = {
        close: true,
        markAsReturnedCodeForm: this.markAsReturnedCodeForm.value
      };

      if (data.close) {
        if(data.markAsReturnedCodeForm.retReasonComment === ''){
          this.paramsReturn = this.serialNumber + '?&return_reason=' + data.markAsReturnedCodeForm.retReasonId;
        } else{
          this.paramsReturn = this.serialNumber + '?&return_reason=' + data.markAsReturnedCodeForm.retReasonId + 
          '&return_reason_other=' + data.markAsReturnedCodeForm.retReasonComment;
        }
        this.memberService.markAsReturn(this.paramsReturn).subscribe((res: any) => {
          this.paramsReturn = null;
          this.toaster.success(res.message);
          this.onClosed();
        });
      }
    } else {
      this.markAsReturnedCodeForm.markAllAsTouched();
    }
  }

  onClosed() {
    this.modalService.hide();
  }
}

