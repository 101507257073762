<!-- for guestbook request -->
<section>
    <div class="container res-p-16">
        <div class="main-container">
            <div class="guestbook-request-wrapper">
                <div class="guestbook-request-title">Guest Book Requests</div>
                <div class="guestbook-request-btn">
                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showAll?'active':''"
                            (click)="showSection('All',true); getMediaRequestList('', 0); getMessageRequestList(0); calculateTotal();">All
                        </button>
                    </div>
                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showPicture?'':'active'"
                            (click)="showSection('message',true); getMessageRequestList(0); calculateTotal();">Messages
                        </button>
                    </div>
                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showMessage?'':'active'"
                            (click)="showSection('picture',true); getMediaRequestList('', 0); calculateTotal();">Pictures
                        </button>
                    </div>
                </div>

                <div class="guestbook-request-btn inner-tab">
                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showPending?'':'active'"
                            (click)="showSection('pending',false); getMediaRequestList('', 0); getMessageRequestList(0);">Pending({{totalPending}})
                        </button>
                    </div>
                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showAccepted?'':'active'"
                            (click)="showSection('accepted',false); getMediaRequestList('', 1); getMessageRequestList(1);">Accepted({{totalAccepted}})
                        </button>
                    </div>

                    <div class="position-relative">
                        <button class="btn-primary-msg cursor-unset" [ngClass]="showRejected?'':'active'" #rejectedBtn
                            (click)="showSection('rejected',false); getMediaRequestList('', 2); getMessageRequestList(2);">Rejected({{totalRejected}})
                        </button>
                    </div>
                </div>
                <div class="upgrade-plan-sec">
                    <div class="upgrade-plan-text">
                        <div class="upgrade-text" *ngIf="uploadLimit?.image_upload_allowed != 'unlimited'">
                            Want to add more Picture Memories and Upload Videos about your loved ones? <a
                                (click)="openUpgradePlan()">Upgrade Your Plan</a> to view further
                            details.
                        </div>
                    </div>
                    <div class="guestbook-message-right" *ngIf="uploadLimit && showPicture">
                        <div class="text-limit"><span>Image Limit :
                                {{uploadLimit?.Approved_images}}/{{uploadLimit?.image_upload_allowed}}</span> <span
                                class="margin-left-30">Video Limit :
                                {{uploadLimit?.Approved_video}}/{{uploadLimit?.video_upload_allowed}}</span></div>
                    </div>
                </div>
                <div *ngIf="showMessage">
                    <div class="msg-request-wrapper" *ngFor="let key of messageRequestList">
                        <div class="message-profile-wrap">
                            <span class="profile-img-wrap loveone-profile-wrap">
                                <img [src]="key?.guest_book?.profile_picture || 'assets/images/user-img.svg'"
                                    alt="profile img" class="profile-img" />
                            </span>
                            <div class="message-profile-text">
                                <div class="msg-title-sec multiline-tooltip">
                                    <div class="tooltip">
                                        <div class="msg-title">
                                            {{key?.guest_book?.name | titlecase}}</div>
                                        <div class="tooltiptext">
                                            <div class="tooltip-inside">
                                                <span class="profile-img-wrap loveone-profile-wrap">
                                                    <img [src]="key?.guest_book?.profile_picture || 'assets/images/user-img.svg'"
                                                        alt="profile img" class="profile-img" />
                                                </span>

                                                <div class="text-tooltip-title">{{key?.guest_book?.name | titlecase}}
                                                </div>
                                                <div class="text-tooltip">Email: <span><a
                                                            href="mailto:wesley@email.com">{{key?.guest_book?.email}}</a></span>
                                                </div>
                                                <div class="text-tooltip">Contact Number:
                                                    <span>{{key?.guest_book?.contact_number}}</span>
                                                </div>
                                                <div class="text-tooltip">Relationship:
                                                    <span>{{key?.guest_book?.relation ||
                                                        'N/A'}}</span>
                                                </div>
                                                <div class="text-tooltip">Address:
                                                    <span>{{key?.guest_book?.address}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="msg-date">{{key?.updated_at | date}}</div>
                                </div>
                                <div class=""><span class="msg-subtitle">Posted for
                                        {{key?.member?.name}}</span></div>
                            </div>
                        </div>
                        <div class="msg-title-desc">{{key.message}}</div>
                        <div class="title-btn-sec">
                            <button class="btn btn-primary-btn" *ngIf="key.status === 0 || key.status === 2"
                                (click)="statusUpdated(1,key.id)">Accept</button>
                            <button class="btn btn-outline-primary" *ngIf="key.status === 0 || key.status === 1"
                                (click)="statusUpdated(2,key.id)">Reject</button>
                        </div>
                    </div>
                    <!-- no result  -->
                    <div class="no-result-section" *ngIf="!messageRequestList?.length && !showPicture">
                        <div class="no-result-img">
                            <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                        </div>
                        <h2>No Results Found</h2>
                    </div>
                </div>
                <div *ngIf="showPicture">
                    <div class="msg-request-wrapper" *ngFor="let key of mediaRequestList">
                        <div class="video-count-sec">
                            <div class="msg-title-sec multiline-tooltip">
                                <span class="profile-img-wrap loveone-profile-wrap">
                                    <img [src]="key?.profile_picture || 'assets/images/user-img.svg'" alt="profile img"
                                        class="profile-img" />
                                </span>

                                <div class="tooltip">
                                    <div class="msg-title">{{key?.name | titlecase}}</div>
                                    <div class="tooltiptext">
                                        <div class="tooltip-inside">
                                            <span class="profile-img-wrap loveone-profile-wrap">
                                                <img [src]="key?.profile_picture || 'assets/images/user-img.svg'"
                                                    alt="profile img" class="profile-img" />
                                            </span>
                                            <div class="text-tooltip-title">{{key?.name | titlecase}}</div>
                                            <div class="text-tooltip">Email: <span><a
                                                        href="mailto:wesley@email.com">{{key?.email}}</a></span></div>
                                            <div class="text-tooltip">Contact Number:
                                                <span>{{key?.contact_number}}</span>
                                            </div>
                                            <div class="text-tooltip">Relationship: <span>{{key?.relation ||
                                                    'N/A'}}</span></div>
                                            <div class="text-tooltip">Address: <span>{{key?.address}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="msg-date">{{key?.updated_at | date}}</div>
                            </div>
                        </div>
                        <div class="msg-subtitle">Posted for {{key?.member?.name}}</div>
                        <div class="add-video-sec-wraper">
                            <div class="add-video-wrap" *ngFor="let gallery of key.guest_book_gallery">
                                <div class="add-video-section"> <!-- Repete this for picture -->
                                    <div class="add-video-img">
                                        <img *ngIf="gallery.type===0"
                                            (click)="staticModal.show();previewImage=gallery.path" [src]="gallery.path"
                                            alt="picture-memory-1" class="full-img" />
                                        <img *ngIf="gallery.type===0 && gallery.path === ''"
                                            src="assets/images/view-all-img-1.png" alt="Img" />
                                        <ng-container *ngIf="gallery.type===1">
                                            <video *ngIf="gallery.type===1" (click)="showVideo(gallery.path)"
                                                [src]="gallery.path +'#t=0.001'" height="100%" width="100%" class="">
                                                <track label="English" kind="captions" srclang="en" src="" default>
                                            </video>
                                            <div class="video-icon">
                                                <img src="./../../../assets/images/video-img.svg"
                                                    (click)="showVideo(gallery.path)" alt="eternallyme video image"
                                                    class="video-icon-img" />
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="add-video-btn-sec">
                                        <button class="btn btn-primary-btn btn-margin-right"
                                            *ngIf="gallery.status === 0 || gallery.status === 2"
                                            (click)="mediaStatusUpdated(1,gallery.id)">Accept</button>
                                        <button class="btn btn-outline-primary"
                                            *ngIf="gallery.status === 0 || gallery.status === 1"
                                            (click)="mediaStatusUpdated(2,gallery.id)">Reject</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- no result  -->
                    <div class="no-result-section" *ngIf="!mediaRequestList?.length && !showMessage">
                        <div class="no-result-img">
                            <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                        </div>
                        <h2>No Results Found</h2>
                    </div>
                </div>
                <!-- no result  -->
                <div class="no-result-section"
                    *ngIf="!messageRequestList?.length && !mediaRequestList?.length && showPicture && showMessage">
                    <div class="no-result-img">
                        <img src="./../../../../assets/images/no-result-img.png" alt="no-result-img">
                    </div>
                    <h2>No Results Found</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-sm image-preview-modal-dialog">
        <div class="modal-content image-preview-modal-content">
            <div class="modal-body">
                <div class="image-preview-wrapper">
                    <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                </div>
                <button type="button" class="btn btn-link image-preview-close-btn" (click)="staticModal.hide()"><img
                        src="assets/images/image-preview-close.svg" alt="close-btn" class="img-fluid" /></button>
            </div>
        </div>
    </div>
</div>
