<section>
    <div class="container payment-section-container">
        <div class="row">
            <div class="col-12 col-lg-5 purchase-memory-col-left">
                <div class="purchase-memory-wrapper-left">
                    <span class="purchase-memory-logo"><img src="assets/images/purchase-app-logo.svg"
                            alt="purchase-app-logo" /></span>
                    <h1>Purchase QR Memory Code</h1>
                    <p>We all have loved ones, families, friends or partners who are our entire world. We love them
                        infinitely and have a lifetime</p>
                    <p class="last-para-margin">QR Memory codes allow loved ones to unlock their memorialized webpage
                        and
                        allows others to view and/or contribute. You may also affix the code to a desired memorialized
                        location.
                    </p>
                    <div class="rip-qr-code-section">
                        <span><img src="assets/images/rip-qr-img.png" alt="rip-qr-img.png"
                                class="purchase-qr-img" /></span>
                        <p class="qr-code-para">Anyone can Scan the QR Memory code or Input the QR Memory code from
                            their mobile
                            device to view the history of those who perished. <img
                                src="assets/images/rip-qr-guide-arrow.svg" alt="guide-arrow"
                                class="payment-guide-arrow" /></p>

                    </div>

                </div>
            </div>
            <div class="col-12 col-lg-7 payment-form-col">
                <div class="container res-p-16">
                    <app-header></app-header>
                    <div class="add-member-container">
                        <div class="main-container main-container-purchase">
                            <div class=" purchase-memory-wrapper-right ">
                                <div class="purchase-memory">
                                    <img src="assets/images/header-infinity.svg" alt="header-infinity "
                                        class="header-img-infinity img-fluid " />
                                </div>
                                <div class="purchase-memory-title">Purchase QR Memory Code</div>
                                <h4 class="section-title">Add Your Details</h4>
                                <p class="section-subtitle">Tell us a few basic details about yourself.</p>
                                <div class="about-memorial-section purchase-memory-section">
                                    <form class="add-member-form " [formGroup]="detailsForm"
                                        (ngSubmit)="submitDetails() ">
                                        <div class="row ">
                                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 ">
                                                <div class="title-right-sec new-input-file-wrap">
                                                    <div *ngIf="!profilePic"
                                                        class="add-pic add-picture-section add-pic-left"><img
                                                            src="assets/images/add-picture-light.svg"
                                                            alt="add-pic" />Profile Picture

                                                        <input type="file" title="" id="logoUpload"
                                                            (change)="uploadProfilePic($event)"
                                                            class="custom-input-file" formControlName="profileImage"
                                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                                                    </div>

                                                    <div *ngIf="profilePic"
                                                        class="add-pic add-picture-section border-remove add-pic-left">
                                                        <img [src]="profilePic" alt="add-pic" class="uploaded-img" />
                                                        <input type="file" title="" id="logoUpload"
                                                            (change)="uploadProfilePic($event)"
                                                            class="custom-input-file" formControlName="profileImage"
                                                            accept="image/png, image/jpeg /image/jpg image/PNG, image/JPEG, /image/JPG" />
                                                    </div>
                                                    <span class="support-file">(jpeg, png, and jpg only<br />
                                                        upto 10 mb)</span>

                                                </div>

                                                <ng-container
                                                    *ngIf="profileImage?.invalid && (profileImage?.dirty || profileImage?.touched) ">
                                                    <span class="invalid-input-text"
                                                        *ngIf="profileImage?.errors?.required">Profile picture is
                                                        required</span>
                                                </ng-container>

                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="fullName?.invalid && (fullName?.dirty || fullName?.touched)? 'invalid-input': '' ">
                                                    <input type="text" class="form-control add-member-input"
                                                        id="floatingInput" placeholder="Full Name"
                                                        pattern="^[a-zA-Z -]+$" formControlName="fullName"
                                                        maxlength="30 ">
                                                    <label for="floatingInput ">Full Name</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="fullName?.invalid && (fullName?.dirty || fullName?.touched) ">
                                                    <span class="invalid-input-text"
                                                        *ngIf="fullName?.errors?.required">Full Name is required</span>
                                                </ng-container>
                                                <ng-container *ngIf="fullName?.invalid && fullName?.errors.pattern ">
                                                    <span class="invalid-input-text">Only alphabetical characters are
                                                        allowed</span>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched)? 'invalid-input': '' ">
                                                    <input type="text"
                                                        class="form-control add-member-input contact-num-input"
                                                        formControlName="contactNumber" maxlength="10"
                                                        (focusin)="showCountryCode=true " (focusout)="contactNumber?.errors?.required?showCountryCode=false: contactNumber?.errors?.minlength?showCountryCode=false:
                                        ''" id="floatingPassword" placeholder="Contact Number" appNumbersonly>
                                                    <label for="floatingPassword">Contact
                                                        Number</label>
                                                    <p class="std-code" *ngIf="showCountryCode">+1</p>
                                                </div>
                                                <ng-container
                                                    *ngIf="contactNumber?.invalid && (contactNumber?.dirty || contactNumber?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="contactNumber?.errors?.required ">Contact Number is
                                                        required</span>
                                                    <span class="invalid-input-text"
                                                        *ngIf="contactNumber?.errors?.minlength">Contact Number should
                                                        not be less than 10 digits
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="row ">
                                            <div class="col-md-12 ">
                                                <div class="form-floating add-member-floating"
                                                    [ngClass]="emailId?.invalid && (emailId?.dirty || emailId?.touched)? 'invalid-input': '' ">
                                                    <input type="text" class="form-control add-member-input"
                                                        formControlName="emailId" id="floatingInput" maxlength="50"
                                                        placeholder="Email ID">
                                                    <label for="floatingInput">Email ID</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="emailId?.invalid && (emailId?.dirty || emailId?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="emailId?.errors?.required ">Email is required</span>
                                                    <span class="invalid-input-text "
                                                        *ngIf="emailId?.errors?.email ">Email is not valid</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="deliverAddress?.invalid && (deliverAddress?.dirty || deliverAddress?.touched)? 'invalid-input': '' ">
                                                    <input type="text"
                                                        class="form-control add-member-input address-input"
                                                        id="floatingInput " formControlName="deliverAddress"
                                                        maxlength="100 " placeholder="Deliver Address ">
                                                    <label for="floatingInput ">Delivery Address</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="deliverAddress?.invalid && (deliverAddress?.dirty || deliverAddress?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="deliverAddress?.errors?.required ">Delivery Address is
                                                        required</span>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">
                                                <div class="form-floating add-member-floating "
                                                    [ngClass]="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)? 'invalid-input': '' ">
                                                    <input type="text " class="form-control add-member-input "
                                                        id="floatingPassword " maxlength="10 " formControlName="zipCode"
                                                        placeholder="Zip Code " appNumbersonly>
                                                    <label for="floatingPassword ">Zip Code</label>
                                                </div>
                                                <ng-container
                                                    *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched) ">
                                                    <span class="invalid-input-text "
                                                        *ngIf="zipCode?.errors?.required ">Zip Code is required</span>
                                                    <span class="invalid-input-text "
                                                        *ngIf="zipCode?.errors?.minlength ">Zip Code
                                                        should not be less than 5 digits</span>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="row ">
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">

                                                <div class="signin-state">
                                                    <div class="form-floating state-input add-member-floating "
                                                        [ngClass]="state?.invalid && (state?.dirty || state?.touched)? 'invalid-input': '' ">
                                                        <select
                                                            class="form-select add-member-input add-member-state-input state-city-select "
                                                            id="state " name="state "
                                                            aria-label="Floating label select example " formControlName="state
                                            " placeholder="State " (change)="getCity($event);selectedState=true "
                                                            formControlName="state">
                                                            <option *ngFor="let state of states " [value]="state.id "
                                                                [hidden]="!state?.name ">
                                                                {{state.name}}
                                                            </option>

                                                        </select>
                                                        <label for="floatingSelect " class="signin-state-label"
                                                            [ngClass]="selectedState? 'removeMar': 'state-label' ">State</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="state?.invalid && (state?.dirty || state?.touched) ">
                                                        <span class="invalid-input-text "
                                                            *ngIf="state?.errors?.required ">State is required</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-sm-6 col-12 ">

                                                <div class="signin-state">
                                                    <div class="form-floating state-input add-member-floating "
                                                        [ngClass]="city?.invalid && (city?.dirty || city?.touched)? 'invalid-input': '' ">
                                                        <select
                                                            class="form-select add-member-input add-member-state-input state-city-select "
                                                            id="city " name="city "
                                                            aria-label="Floating label city example "
                                                            formControlName="city" placeholder="City
                                            " forControlName="city " (change)="selectedCity=true ">
                                                            <option *ngFor="let city of cities " [value]="city.id ">
                                                                {{city.name}}

                                                            </option>
                                                        </select>
                                                        <label for="floatingSelect " class="signin-state-label"
                                                            [ngClass]="selectedCity? 'removeMar': 'state-label' ">City</label>
                                                    </div>
                                                    <ng-container
                                                        *ngIf="city?.invalid && (city?.dirty || city?.touched) ">
                                                        <span class="invalid-input-text "
                                                            *ngIf="city?.errors?.required ">City is required</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row payment-btn-row ">

                                            <div class="col-xl-6 col-lg-6 col-12 ms-auto continue-btn-col ">
                                                <button type="submit"
                                                    class="btn btn-primary btn-primary-continue ">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="footer-img-heart ">
                                    <img src="assets/images/footer-heart.svg " alt="footer-heart " class="img-fluid " />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
