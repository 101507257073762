<div class="signin-modal-content change-password-content">
    <div class="modal-header signin-modal-header">
        <button type="button" class="btn-close ms-auto" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body signin-body change-password-body">
        <div class="signin-section">
            <span class="modal-title" id="exampleModalLabel"><img src="assets/images/header-infinity.svg"
                    alt="header-infinity" class="modal-header-img" /></span>
            <h1 class="signin-title change-pw-title">Change Password</h1>
            <div class="signin-container">
                <form class="login-container change-password-container" [formGroup]="changePasswordForm"
                    (ngSubmit)="changePassword()">
                    <div class="form-floating pw-input"
                        [ngClass]="oldPassword?.invalid && (oldPassword?.dirty || oldPassword?.touched)?'invalid-input':''">
                        <input (cut)="onCut($event)" (copy)="onCopy($event)" (paste)="onPaste($event)" [type]="showOld?'text':'password'" class="form-control " id="oldPassword"
                            placeholder="Old Password" formControlName="oldPassword">
                        <label for="Old Password">Old Password</label>
                        <span class="hide-eye" (click)="showOld=!showOld"><img
                                src="assets/images/{{showOld?'show':'hide'}}-password.svg" alt="hide-password"></span>
                    </div>
                    <ng-container *ngIf="oldPassword?.invalid && (oldPassword?.dirty || oldPassword?.touched)">
                        <span class="invalid-input-text" *ngIf="oldPassword?.errors?.required">Old Password is
                            required</span>
                    </ng-container>

                    <div class="form-floating pw-input"
                        [ngClass]="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)?'invalid-input':''">
                        <input (cut)="onCut($event)" (copy)="onCopy($event)" (paste)="onPaste($event)" [type]="showNew?'text':'password'" class="form-control " id="New Password"
                            placeholder="NewPassword" formControlName="newPassword">
                        <label for="New Password">New Password</label>
                        <span class="hide-eye" (click)="showNew=!showNew"><img
                                src="assets/images/{{showNew?'show':'hide'}}-password.svg" alt="hide-password"></span>
                    </div>
                    <ng-container *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)">
                        <span class="invalid-input-text" *ngIf="newPassword?.errors?.required">New Password is
                            required</span>
                        <span class="invalid-input-text" *ngIf="newPassword?.errors?.pattern">New Password is
                            required at least 8 characters and one lowercase and one uppercase and one special
                            characters </span>
                    </ng-container>

                    <div class="form-floating pw-input"
                        [ngClass]="confirmNewPassword?.invalid && (confirmNewPassword?.dirty || confirmNewPassword?.touched)  || ((confirmNewPassword?.valid || newPassword?.valid) && confirmNewPassword?.value !== newPassword?.value)?'invalid-input':''">
                        <input (cut)="onCut($event)" (copy)="onCopy($event)" (paste)="onPaste($event)" [type]="showConfirm?'text':'password'" class="form-control " id="confirmNewPassword"
                            placeholder="confirmNewPassword" formControlName="confirmNewPassword">
                        <label for="confirmNewPassword">Confirm New Password</label>
                        <span class="hide-eye" (click)="showConfirm=!showConfirm"><img
                                src="assets/images/{{showConfirm?'show':'hide'}}-password.svg"
                                alt="hide-password"></span>
                    </div>

                    <ng-container
                        *ngIf="confirmNewPassword?.invalid && (confirmNewPassword?.dirty || confirmNewPassword?.touched)">
                        <span class="invalid-input-text" *ngIf="confirmNewPassword?.errors?.required">Confirm Password
                            is required</span>
                        <span class="invalid-input-text" *ngIf="confirmNewPassword?.errors?.pattern">Confirm Password
                            Password is
                            required at least 8 characters and one lowercase and one uppercase and one special
                            characters </span>
                    </ng-container>
                    <span class="invalid-input-text"
                        *ngIf="(confirmNewPassword?.valid || newPassword?.valid) && confirmNewPassword?.value !== newPassword?.value">New
                        Password and
                        Confirm Password must match
                    </span>

                    <div class="form-action">
                        <button class="btn btn-primary" type="submit">Change Password</button>
                    </div>

                </form>
                <span class="signin-footer"><img src="assets/images/footer-heart.svg" alt="footer-heart"
                        class="modal-footer-img" /></span>

            </div>
        </div>
    </div>
</div>