<div class="owner-modal-header">
    <button type="button" class="btn-close ms-auto" (click)="modelRef.hide()" aria-label="Close"></button>
</div>
<div class="modal-body owner-modal-body">
    <div class="modal-title">Manage Sub-Owners ({{totalCoOwners ? totalCoOwners?.total-1 : 0}})</div>
    <div class="owner-list-wrap">
        <div class="table-container co-owner-table">
            <div class="table-head">
                <div class="table-row">
                    <div class="th with-sort">
                        Name
                        <span class="sort-icon-wrap">
                            <img class="sort-icon-down" (click)="getCoOwners('asc')"
                                src="./assets/images/sort-icon-down.svg" alt="icon-sort" />
                            <img class="sort-icon-up" (click)="getCoOwners('desc')"
                                src="./assets/images/sort-icon-up.svg" alt="icon-sort" />

                        </span>

                    </div>
                    <div class="th">Email</div>
                    <div class="th">Type</div>
                    <div class="th">Action</div>
                </div>
            </div>
            <!-- table-head end here -->
            <div class="table-body">
                <!-- repeat this div -->
                <div class="table-row" *ngFor="let data of coOwnersData; let i=index">
                    <div class="td">
                        <span class="profile-img-wrap">
                            <img [src]="data?.profile_picture || 'assets/images/user-img.svg'" alt="profile img"
                                class="profile-img" />
                        </span>
                        <span class="ellipsis-text">
                            {{data?.name | titlecase}}

                        </span>
                    </div>
                    <div class="td"><span class="ellipsis-text">{{data?.email}}</span> </div>
                    <div class="td" *ngIf="data?.account_type === 3">Funeral Home</div>
                    <div class="td" *ngIf="data?.account_type === 2">Co-Owner</div>
                    <div class="td" *ngIf="data?.account_type === 1">Owner</div>

                    <div *ngIf="data?.account_type === 1" class="td remove inactive">Remove</div>
                    <div *ngIf="data?.account_type !== 1" (click)="deleteUser=i" class="td">
                        <div class="dropdown dropdown-manage">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Remove
                            </button>
                            <div class="dropdown-menu">
                                <h3 class="dropdown-title">Remove Sub-Owner?</h3>
                                <div class="remove-btn-wrap">
                                    <a class="cancel-modal-btn" (click)="deleteUser=-1">Cancel</a>
                                    <button class="btn btn-primary-btn reomove-btn"
                                        (click)="deleteCoOwners(data?.id)">Yes, Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="co-owner-footer">
        <button type="submit" routerLink="/invite-co-owner" (click)="modelRef.hide()"
            class="btn btn-primary-btn fill-button">+Add More</button>
    </div>

</div>
