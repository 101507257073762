import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { IResponse } from '../../interfaces/response';
import { ToastrService } from 'ngx-toastr';
import { LandingPageService } from '../../services/landing-page.service';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-manage-co-owners',
  templateUrl: './manage-co-owners.component.html',
  styleUrls: ['./manage-co-owners.component.scss']
})
export class ManageCoOwnersComponent implements OnInit {
  @Input() data: any;
  coOwnersData = [];
  totalCoOwners;
  deleteUser:any;

  constructor(public commonService: CommonService, public toastr: ToastrService,public router: Router, 
    public landingPageService: LandingPageService, public utilityService: UtilityService,
    public modelRef: BsModalRef, public paymentService: PaymentService) {
  }


  ngOnInit(): void {
    this.getCoOwners();
  }

  getCoOwners(order?) {
    let params;
    if(order){
      params = `order=${order}&order_by=name&limit=100`;
    } else{
      params = `&limit=100`;
    }
    this.landingPageService.getCoOwnersList(params).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.totalCoOwners = res.data;
        this.coOwnersData = res.data.data;
      }
    });
  }

  deleteCoOwners(id){
    this.deleteUser = -1;
    const data ={ user_id: id };
    this.utilityService.deleteOwnerFuneralAccount(data).subscribe((res: any) => {
      this.toastr.success(res.message);
      this.getCoOwners();
    });
  };
}
