import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { IResponse } from '../../interfaces/response';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public changePasswordForm: FormGroup;
  public showOld = false;
  public showNew = false;
  public showConfirm = false;
  constructor(public modalRef: BsModalRef, public formBuilder: FormBuilder, public commonService: CommonService,
    public router: Router, public toastr: ToastrService, public authService: AuthService) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      Validators.maxLength(MagicNumbers.fifteen)]],
      confirmNewPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      Validators.maxLength(MagicNumbers.fifteen)]],
    });
  }



  get oldPassword() { return this.changePasswordForm.get('oldPassword'); }
  get newPassword() { return this.changePasswordForm.get('newPassword'); }
  get confirmNewPassword() { return this.changePasswordForm.get('confirmNewPassword'); }

  /**
   * API Call for change password
  */
  changePassword() {
    if (this.changePasswordForm.valid && this.checkPasswordEqual()) {
      if (this.changePasswordForm.value.oldPassword !== this.changePasswordForm.value.newPassword) {
        const payload = {
          current_password: this.changePasswordForm.value.oldPassword,
          new_password: this.changePasswordForm.value.newPassword,
          confirm_password: this.changePasswordForm.value.confirmNewPassword
        };
        this.authService.changePassword(payload).subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.toastr.success(res.message);
            this.modalRef.hide();
            this.logout();
          }
        });
      } else {
        this.toastr.error('Old password and new password cannot be same');
      }
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  onCut(event: ClipboardEvent): void {
    event.preventDefault();
  }
  
  onCopy(event: ClipboardEvent): void {
    event.preventDefault();
  }
  
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }
/**
   * Equality check for new password and confirm password
  */
  checkPasswordEqual(){
   return (this.changePasswordForm.value.newPassword === this.changePasswordForm.value.confirmNewPassword);
  }
 /**
   * API Call for Logout
  */
  logout(){
    this.authService.logout().subscribe((res1: IResponse) => {
      if (res1 && !res1.errors) {
        this.commonService.userData.next(null);
        localStorage.clear();
        this.router.navigate(['/landing-page']);
      }
    });
  }
}
