import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './common/services/common.service';
import { UtilityService } from './common/services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent  implements OnInit{
  title = 'EternallyMe';

  constructor(public router: Router,
    public commonService: CommonService, 
    public utilityService: UtilityService) {

  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if(JSON.parse(localStorage.getItem('userData'))){
    }
  }
      /**
   * show Icon red method
  */
checkPendingReq(memberID){
  this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
    if (res && !res.errors) {
       this.commonService.guestRequestSharing.next(res.data);
    }
    });
}
}