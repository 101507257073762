<section class="view-info-section view-info-padding-mob">
    <h1 class="light-title">{{member?.first_name}}</h1>
    <h1 class="light-title"></h1>
    <div class="container">
        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity.svg" alt="viewinfo-infinity">
            </div>

            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart.svg" alt="viewinfo-heart">
            </div>

            <div class="second-border" *ngIf="member?.first_name">
                <div class="row member-title-row">
                    <div class="member-photo">
                        <div class="member-photo-section">
                            <div class="member-img-section" [style.background]="frameImage"
                                [ngClass]="frameImage?'frameBack':''">
                                <div class="title-right-sec new-input-file-wrap img-fluid member-img">
                                    <div class="add-picture-section">
                                        <img [src]="member.profile_picture" alt="member-title-image"
                                            class="view-info-img" />
    
                                        <input *ngIf="isLoggedIn" type="file" title="" id="logoUpload"
                                            (change)="uploadProfilePic($event)" class="custom-input-file"
                                            formControlName="profileImage"
                                            accept="image/png, image/jpeg, image/jpg, image/PNG, image/JPEG, image/JPG"
                                            style="display: none;" #fileInput />
                                    </div>
    
                                </div>
                                <img *ngIf="isLoggedIn" (click)="triggerFileInput()"
                                    src="assets/images/frame-edit-button.png" alt="edit" class="frame-edit-btn" />
    
                            </div>
                        </div>
                        <div class="member-info-col member-info-content">
                            <div class="member-info-table">
                                <h1>{{member?.first_name}}
                                    <ng-container *ngIf="member.middle_name!==null">
                                        {{member?.middle_name}}
                                    </ng-container>
                                    {{member?.last_name}}
                                </h1>
                                <p class="member-year">
                                    {{member.dob &&
                                    member.is_dob_private!==1?(member.dob|date:'yyyy'):'Unknown'}}-{{member?.dod|date:'yyyy'}}
                                </p>
                                <div class="prayer-card-section">
                                    <div *ngIf="isLoggedIn" class="share-obituary"><a>
                                            <span *ngIf="showMessage" (click)="openObituary(member)"
                                                class="prayer-card-text">Share Obituary</span>
                                            <span *ngIf="!showMessage" (click)="openAddObituary()"
                                                class="prayer-card-text">Add & Share Obituary</span>
                                        </a></div>
                                    <div class="share-obituary"><a>
                                            <span *ngIf="isLoggedIn && !member?.itinerary_card"
                                                (click)="createItenerary(member.id)" class="prayer-card-text">+Create
                                                Itinerary</span>
                                            <span *ngIf="member?.itinerary_card" (click)="viewItenerary(member.id)"
                                                class="prayer-card-text">View Itinerary</span>
                                        </a></div>
                                    <div class="dropdown profile-dropdown prayer-icon" *ngIf="member?.prayer_card">
                                        <a class="btn dropdown-toggle" role="button" id="dropdownMenuPrayerLink"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="prayer-card-text">Prayer Card</span>
                                            <img src="assets/images/icons-drop-arrow-color.svg" alt="add-pic" />
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuPrayerLink">
                                            <li><a class="dropdown-item" (click)="showDoc(member.prayer_card)">View</a></li>
                                            <li><a class="dropdown-item"
                                                    (click)="downloadCode(member.prayer_card)">Download</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="member-about">
                                    <div class="table-row">
                                        <div class="td first-col">Birth Date</div>
                                        <div class="td second-col">{{member.dob &&
                                            member.is_dob_private!==1?(member.dob|date:'MM/dd/yyyy'):'Unknown'}}
                                        </div>
                                    </div>
                                    <div class="table-row">
                                        <div class="td first-col">Day of Passing</div>
                                        <div class="td second-col">{{member?.dod|date:'MM/dd/yyyy'}}</div>
                                    </div>
                                    <div class="table-row">
                                        <div class="td first-col">Hometown</div>
                                        <div class="td second-col">{{member?.city?.name}}, {{member?.state?.code}}, US</div>
                                    </div>
                                    <div class="table-row table-flex-start mb-0" *ngIf="member?.is_burial_private!==1">
                                        <div class="td first-col">Cemetery</div>
                                        <div class="td second-col table-flex-col-start">{{member?.burial_city?.name}},
                                            {{member?.burial_state?.code}}, US
                                            <span class="view-website-wrapper">
                                                <a [href]="member?.cemetery_website" class="link-text" target="_blank">View
                                                    Website</a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="table-row mb-0 display-center-sign-guestbook">
                                        <button *ngIf="!isLoggedIn" type="button" (click)="openModal(signInGuest)"
                                            class="btn btn-primary-btn guestbook-btn">Guest Book<br /><span>Share your
                                                memories</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <!-- member about start here -->
                    <section *ngIf="showMessage" class="about-section">
                        <div class="share-obituary-sec">
                            <div class="share-obituary-head">
                                <div class="share-obituary-left">Obituary:</div>
                                <div *ngIf="isLoggedIn" class="share-obituary-right"><a
                                        (click)="openEditObituary()">Edit Obituary</a></div>
                            </div>
                            <div class="share-obituary-text">
                                {{messageObituary}}
                            </div>
                        </div>
                    </section>
                    <section class="about-section">
                        <div class="row">
                            <div class="col-12 about-text-col">
                                <div class="about-header">
                                    <div class="about-header-sec">
                                        <h2 class="edit-about-head">About {{member?.first_name}}</h2>
                                        <div class="edit-btn-wrapper" *ngIf="userData?.user">
                                            <div class="container text-right">
                                                <button *ngIf="isLoggedIn" class="btn btn-primary"
                                                    (click)="updateDetails(member.id)">
                                                    <img src="assets/images/edit-white-icon.svg" alt="edit-icon"><span
                                                        class="">Edit Details</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [innerHTML]="member.about_memorial" class="normal-para"></div>
                            </div>
                        </div>
                    </section>
                    <section class="picture-memories-section" *ngIf="member.member_gallery?.length!==0">
                        <div class="memories-section-title memories-sec">
                            <h2>Album</h2>
                            <div class="memories-btn-wrap">
                                <div class="link-text-sec"><a (click)="viewAllMemories()" class="link-text">View All
                                        <span class="mob-sec-hide">Memories</span></a></div>
                                <div class="link-text-sec"><a *ngIf="isLoggedIn" class="btn btn-primary"
                                        (click)="openAddPicturesPopup()" class="link-text edit-memory-btn">
                                        <img src="assets/images/edit-icon.svg" alt="edit-icon" class="edit-memory-img">
                                        Edit Memories</a></div>
                            </div>

                        </div>
                        <div class="row picture-memories-row">
                            <div class="col-12 col-sm-12 col-md-12 memory-pic-second-col">
                                <div class="memory-pic-right">
                                    <div *ngFor="let picture of member.member_gallery;let i=index" [hidden]="i>=6"
                                        class="half-img">
                                        <div class="picture-del-sec" *ngIf="picture?.type===0">
                                            <img [src]="picture.path" alt="picture-memory-2" class="img-fluid"
                                                (click)="goLikeComment(picture)" />
                                            <div class="add-img-del view-right-6"
                                                *ngIf="!showSignInGuest && getPicture.length > 1">
                                                <img class="border-none" src="assets/images/icon-del-col.svg" alt=""
                                                    (click)="deleteMember=i;removeMemory = -1" />
                                            </div>
                                        </div>
                                        <div class="reomove-modal-wrap" *ngIf="deleteMember=== i">
                                            <div class="reomove-modal-sec">
                                                <div class="reomove-title">Remove Memory?</div>
                                                <button type="submit"
                                                    class="btn btn-primary-btn fill-button reomove-btn"
                                                    (click)="UpdateMember(picture,i)">Yes, Remove</button>
                                                <div><a class="cancel-modal-btn" (click)="deleteMember=-1">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="picture?.type===1">
                                            <div class="picture-del-sec">
                                                <video [src]="picture?.path +'#t=0.001'" alt="picture-memory-2"
                                                    class="img-fluid half-img-video" (click)="goLikeComment(picture)">
                                                    <track label="English" kind="captions" srclang="en" src="" default>
                                                </video>
                                                <div class="add-img-del view-right-6" *ngIf="!showSignInGuest">
                                                    <img src="assets/images/icon-del-col.svg" alt=""
                                                        (click)="deleteMembers=i" />
                                                </div>
                                                <div class="reomove-modal-wrap" *ngIf="deleteMembers=== i">
                                                    <div class="reomove-modal-sec">
                                                        <div class="reomove-title">Remove Memory?</div>
                                                        <button type="submit"
                                                            class="btn btn-primary-btn fill-button reomove-btn"
                                                            (click)="UpdateMember(picture,i)">Yes, Remove</button>
                                                        <div><a class="cancel-modal-btn"
                                                                (click)="deleteMembers=-1">Cancel</a></div>
                                                    </div>
                                                </div>
                                                <div class="video-icon">
                                                    <img src="./../../../assets/images/video-img.svg"
                                                        (click)="goLikeComment(picture)" alt="eternallyme video image"
                                                        class="video-icon-img" />
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="picture-memories-section" *ngIf="mediaLists?.length!=0">
                        <div class="memories-section-title memories-sec">
                            <h2>Guest Book Memories</h2>
                            <div class="link-text-sec"><a class="link-text" (click)="seeMoreList('gallery')">View All
                                    <span class="mob-sec-hide">Memories</span></a></div>
                        </div>
                        <div class="pictures-from-guestbook-wrap">
                            <div class="row">
                                <div *ngFor="let picture of mediaLists;let i=index"
                                    class="col-6 col-sm-6 col-md-4 image-repeat-sec">
                                    <div class="pictures-from-guestbook-sec">
                                        <div class="pictures-from-guestbook-logo">
                                            <img *ngIf="picture.type===0" [src]="picture.path" alt="picture-memory-1"
                                                class="full-img" (click)="goLikeComment(picture)" />
                                            <div>
                                                <div *ngIf="!showSignInGuest" class="add-img-del view-info-del">
                                                    <img src="assets/images/icon-del-col.svg" alt=""
                                                        (click)="removeMemory = i;deleteMember = -1" />
                                                </div>
                                                <div class="reomove-modal-wrap" *ngIf="removeMemory===i">
                                                    <div class="reomove-modal-sec">
                                                        <div class="reomove-title">Remove Memory?</div>
                                                        <button type="submit"
                                                            class="btn btn-primary-btn fill-button reomove-btn"
                                                            (click)="deleteMedia(picture)">Yes, Remove</button>
                                                        <div><a class="cancel-modal-btn"
                                                                (click)="removeMemory=-1">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="picture.type===1">
                                                <video *ngIf="picture.type===1" (click)="goLikeComment(picture);"
                                                    height="100px" width="100px">
                                                    <source [src]="picture.path" type="video/mp4" />
                                                    <track label="English" kind="captions" srclang="en" src="" default>
                                                </video>
                                                <div class="video-icon">
                                                    <img src="./../../../assets/images/video-img.svg"
                                                        (click)="goLikeComment(picture)" alt="eternallyme video image"
                                                        class="video-icon-img" />
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="guestbook-picture-overlay">
                                            <div class="guestbook-picture-title">
                                                <div class="title">{{toTitleCase(picture?.guest_book?.name)}}</div>
                                                <div class="date">{{picture?.updated_at | date}}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="messages-guestbook-section" *ngIf="guestbookMessageList?.length!==0">
                        <div class="row">
                            <div class="col-12 about-text-col hobbies-col position-relative">
                                <h2 class="heading-top mt-0 mb-0">Messages from Guest Book</h2>
                                <div class="message-guestbook-wrapper"
                                    *ngFor="let key of guestbookMessageList;let i=index">
                                    <div class="msg-title-sec">
                                        <div class="message-guestbook-left">
                                            <img src="assets/images/icon-rose.svg" alt="edit" />
                                        </div>
                                        <div class="message-guestbook-right">
                                            <div class="message-guestbook-sec">
                                                <div class="date-sec multiline-tooltip">
                                                    <div class="tooltip">
                                                        <div class="message-title">{{key.guest_book.name | titlecase}}
                                                        </div>
                                                        <div *ngIf="!showSignInGuest" class="tooltiptext">
                                                            <div class="tooltip-inside">
                                                                <div class="text-tooltip-title">{{key.guest_book.name |
                                                                    titlecase}}</div>
                                                                <div class="text-tooltip">Email:
                                                                    <span><a>{{key.guest_book.email}}</a></span>
                                                                </div>
                                                                <div class="text-tooltip">Contact Number:
                                                                    <span>{{key.guest_book.contact_number}}</span>
                                                                </div>
                                                                <div class="text-tooltip">Relationship:
                                                                    <span>{{key.guest_book.relation || 'NA'}}</span>
                                                                </div>
                                                                <div class="text-tooltip">Address:
                                                                    <span>{{key.guest_book.address}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="message-date">{{key.updated_at | date}}</div>
                                                </div>
                                                <div class="reomove-modal-wrap remove-text-wrap"
                                                    *ngIf="removeMessage===i">
                                                    <div class="reomove-modal-sec">
                                                        <div class="reomove-title">Remove Message?</div>
                                                        <button type="submit"
                                                            class="btn btn-primary-btn fill-button reomove-btn"
                                                            (click)="statusUpdated(2,key.id)">Yes, Remove</button>
                                                        <div><a class="cancel-modal-btn"
                                                                (click)="removeMessage=-1">Cancel</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="remove-sec-msg">
                                                <div class="message-title-desc">{{key.message}}</div>
                                                <a *ngIf="!showSignInGuest" (click)="removeMessage = i"
                                                    class="message-remove">Remove
                                                    Message</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a *ngIf="guestbookMessageList?.length!==1" (click)="seeMoreList('message')"
                                    class="message-see-more">View All</a>
                            </div>
                        </div>
                    </section>


                    <section class="hobbies-section hobbies-sec-top">
                        <div class="row">
                            <div class="col-12 about-text-col hobbies-col">
                                <h2>{{member?.first_name}}’s Hobbies</h2>
                                <p [innerHTML]="member?.hobbies" class="normal-para"></p>
                            </div>
                        </div>
                    </section>
                    <section class="career-section">
                        <div class="row">
                            <div class="col-12 about-text-col">
                                <h2>{{member?.first_name}}’s Career & Accomplishments</h2>
                                <p [innerHTML]="member?.career_accomplishments" class="normal-para"></p>
                            </div>
                        </div>
                    </section>
                    <section class="family-section">
                        <div class="row">
                            <div class="col-12 about-text-col">
                                <h2>{{member?.first_name}}’s Family</h2>
                                <p [innerHTML]="member?.about_family" class="normal-para"></p>
                            </div>
                        </div>
                    </section>
                    <section class="favorite-memories-section">
                        <div class="row">
                            <div class="col-12 about-text-col">
                                <h2>Favorite Memories About {{member?.first_name}}</h2>
                                <p [innerHTML]="member?.favorite_memories" class="normal-para"></p>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>

        <div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
            tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
            <div class="modal-dialog modal-sm image-preview-modal-dialog">
                <div class="modal-content image-preview-modal-content">
                    <div class="modal-body">
                        <div class="image-preview-wrapper">
                            <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                        </div>
                        <button type="button" class="btn btn-link image-preview-close-btn"
                            (click)="staticModal.hide()"><img src="assets/images/image-preview-close.svg"
                                alt="close-btn" class="img-fluid" /></button>
                    </div>
                </div>
            </div>
        </div>




        <ng-template #signInGuest>
            <div class="modal-header">
                <img src="assets/images/icons-close.svg" (click)="modalRef.hide()" alt="close-btn" class="img-fluid"
                    data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div class="modal-body">
                <div class="modal-title">Sign Guest Book</div>
                <div class="modal-subtitle">Enter your email address to proceed.</div>
                <form [formGroup]="form">
                    <div>
                        <div class="form-floating guest-signin-input form-floating-add"
                            [ngClass]="{ 'has-error': form.get('email').invalid && (form.get('email').dirty || form.get('email').touched) }">
                            <input type="email" class="form-control form-floating-margin"
                                [attr.disabled]="userData?.user?.email" formControlName="email" id="floatingInput"
                                placeholder="mikewilliams@gmail.com" maxlength="50">
                            <label for="floatingInput">Email ID</label>
                            <div class="invalid-input-text-msg"
                                *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
                                <div *ngIf="form.get('email').errors.required">Email is required</div>
                                <div *ngIf="form.get('email').errors.email">Email is invalid</div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" (click)="submitGuest()" [disabled]="form.invalid"
                        class="btn btn-primary-btn">Next</button>
                </form>

            </div>
        </ng-template>

    </div>

    <!-- End Share Obituar -->
