
<div class="modal-content share-obituary-modal">
    <div class="modal-header">
        <img src="assets/images/icons-close.svg" alt="close-btn" class="img-fluid" aria-label="Close" (click)="modalRef.hide()" />
    </div>
    <div class="modal-body body-top-margin">
        <div class="select-modal-sec share-obt">
            <form [formGroup]="form">
            <div class="modal-titles">Edit Obituary</div> 
            <div class="modal-subtitles">Add your loved one's obituary that you can share on social media.</div>
            
            <div class="share-message mb-0">
                <div class="textarea-message p-0 position-relative">
                    <div class="form-floating form-floating-add" [ngClass]="form.get('message')?.invalid && (form.get('message')?.dirty || form.get('message')?.touched)?'invalid-input':''">
                        <textarea pattern="^\S(.*\S)?\s*$" onkeypress="return this.value.trim() !== '' || /\S/.test(event.key)" required maxlength="500" class="form-control" id="floatingTextarea"  #myTextarea (input)="onTextareaChange()" formControlName="message" placeholder=" "></textarea>
                        <label for="floatingTextarea">Message</label>
                        </div>
                        <div *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)">
                          <div class="invalid-input-text-neg">Message is invalid</div>
                        </div>


                </div>
                
            </div>
            <div class="share-add-action">
                <button (click)="submitForm(1)" type="button" class="btn btn-outline-primary btn-primary-delete">Delete</button>
                <button (click)="submitForm(0)" type="submit" class="btn btn-primary btn-primary-update">Update</button>
            </div>
        </form>
            </div>
        </div>
    </div>
