import { Component, Input, OnInit } from '@angular/core';
import { IResponse } from '../../interfaces/response';
import { LandingPageService } from '../../services/landing-page.service';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html'
})
export class ContentManagementComponent implements OnInit {
  @Input() cmsName?: string;
  @Input() pageName: string;
  public cmsData: string | undefined;
  constructor(public cmsService: LandingPageService) {
    this.pageName = '';
    this.cmsData = '';
  }

  ngOnInit(): void {
    this.getContentByPage();
  }
  /**
   * API Call for Content Management based on page
  */
  getContentByPage() {
    this.cmsService.getContentBasedonPage(this.pageName).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.cmsData = res?.data?.content;
      }
    });
  }
}
