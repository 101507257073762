import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResponse } from '../../common/interfaces/response';
import { MemberService } from '../../common/services/member.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../common/services/utility.service';
import { CommonService } from '../../common/services/common.service';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-message-user',
  templateUrl: './send-message-user.component.html',
  styleUrls: ['./send-message-user.component.scss']
})
export class SendMessageUserComponent implements OnInit {
  public mediaLists:any=[];
  public memberID: any;
  public guestbookMessageList: any = [];
  public showMessage: boolean;
  public showGallery: boolean;
  public showBroadcast: boolean;
  public previewImage: string;
  public MessageScroll: any=[];
  public page: any=1;
  public totalAllCount;
  public uploadLimit: any;
  public paginationArr = [];
  public paginationMessageArr=[];
  public paginationBoardArr=[];
  public currentPage = 1;
  public pageData =1;
  public currentMessagePage=1;
  public currentBoardCastPage=1;
  public searchData;
  public searchMessageData;
  public userData: any;
  public removeMemory :any;
  public modalRef: BsModalRef;
  public removeMessage :any;
  public isNextPageLoading = false;
  public nextPage$ = new BehaviorSubject<void>(undefined);
  public showDivider = false;
  public items$: any;
  public form: FormGroup;
  public picture:any;
  public imageMail:any;
  public showBroadButton:any;
  public prevUserData: any;
  public prevUsers: any = [];
  public firstUserName: any;
  public goToLocation: any;
  public selectedGuestsName: string;
  public guestbookUser: any;
  showSignInGuest: any;
  showSignInMember: any;
  guestId: any;
  messagesListData: any = [];
  public media: Array<{ url: string, type: number }> = [];

  public imageCount = 0;
  public videoCount = 0;
  private readonly maxPhotos = 5;
  private readonly maxVideos = 2;
  private readonly maxPhotoSizeMB = 10;
  private readonly maxVideoSizeMB = 20;

  constructor(
    public memberService: MemberService,
    public router : Router,
    public modalService: BsModalService,
    public loaderService: NgxSpinnerService,
    public toastr: ToastrService,
    public utilityService: UtilityService,
    public commonService: CommonService,
    private readonly formBuilder: FormBuilder,
    public activateRoute: ActivatedRoute
    ) {


      this.form = this.formBuilder.group({
        message: ['', Validators.maxLength(MagicNumbers.thousand)]
      });
      this.activateRoute.queryParams.subscribe(queryParams => {
        if (queryParams?.showSignInGuest && queryParams?.showSignInMember) {
          this.showSignInGuest = JSON.parse(queryParams?.showSignInGuest);
          this.showSignInMember = JSON.parse(queryParams.showSignInMember);
        }
       const userData = JSON.parse(localStorage.getItem('userData'));
       if(userData){
         this.checkPendingReq(userData?.user.member?.id);
       }
      });

    }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      if (params.id) {
        this.guestId = params.id;
      } else{
      }
    });
    this.memberID = JSON.parse(localStorage.getItem('member_id'));
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if(this.userData){
      this.checkPendingReq(this.userData?.members[0]?.id);
    }
    this.fetchMessagesListUser();
  }

  scrollToBottomSmoothly(): void {
    window.scrollTo({
      top: document.body.scrollHeight || document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }
  done() {
    // Navigate to 'app-guestbook-gallery' and pass showPrivateMessage as true
    this.router.navigate(['/guestbook-gallery'], {
      queryParams: { showPrivateMessage: true }
    });
  }

  fetchMessagesListUser() {
    this.loaderService.show();
    this.memberService.fetchChatList(this.memberID, this.userData?.user?.id, this.guestId )
      .subscribe((response: any) => {
        this.messagesListData = response.data;  // Adjust based on your actual response structure
        this.loaderService.hide();
        if(this.messagesListData.length != 0){
          setTimeout(() => {
            this.scrollToBottomSmoothly();
          }, MagicNumbers.fifteenHundred);
        }
      }, (error) => {
        this.loaderService.hide();
      });
  }

  removeMedia(index: number) {
    const removedMedia = this.media[index];

    if (removedMedia.type === 1) {
      this.imageCount--;
    } else if (removedMedia.type === MagicNumbers.two) {
      this.videoCount--;
    }

    this.media.splice(index, 1);
  }

  autoResizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '61px';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  sendMessage() {
    // Check if form is valid or media is attached
    if (this.form.invalid && this.media.length === 0) {
      this.toastr.error('Please enter a message or attach media');
      return;
    }

    const message = this.form.get('message').value || '';

    // Validate mandatory fields
    if (!this.memberID) {
      this.toastr.error('Member ID is required');
      return;
    }
    if (!message && this.media.length === 0) {
      this.toastr.error('Either a message or media is required');
      return;
    }
    if (!this.guestId) {
      this.toastr.error('Guestbook ID is required');
      return;
    }

    if(!this.userData?.user?.id){
      this.toastr.error('Owner ID is required');
      return;
    }

    this.loaderService.show();

    // Construct the data object
    const data = {
      member_id: this.memberID,
      guestbook_id: this.guestId || null,
      user_id: this.userData?.user?.id || null,
      message: message,
      media: this.media,
      send_by: MagicNumbers.two
    };

    // Call the API to send the message
    this.memberService.sendMessage(data).subscribe(response => {
      this.loaderService.hide();
      this.form.reset();
      this.media = [];
      this.imageCount = 0;
    this.videoCount = 0;
    const textarea = document.getElementById('floatingTextarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = '61px';
    }
      this.fetchMessagesListUser();
    }, error => {
      this.loaderService.hide();
    });
  }
  fileSelected(event: any) {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSizeMB = file.size / (MagicNumbers.oneThousandTwentyFour * MagicNumbers.oneThousandTwentyFour); // Convert bytes to MB
        const uploadType = file.type.split('/')[0]; // Determine if it's an image or video

        // Validate the file type and size
        if (uploadType === 'image') {
            if (fileSizeMB > this.maxPhotoSizeMB) {
                this.toastr.error(`Please upload images less than ${this.maxPhotoSizeMB} MB`);
                continue;
            }
            if (this.imageCount >= this.maxPhotos) {
                this.toastr.error('You can only upload up to 5 photos');
                break;
            }
        } else if (uploadType === 'video') {
            if (fileSizeMB > this.maxVideoSizeMB) {
                this.toastr.error(`Please upload videos less than ${this.maxVideoSizeMB} MB`);
                continue;
            }
            if (this.videoCount >= this.maxVideos) {
                this.toastr.error('You can only upload up to 2 videos');
                break;
            }
        } else {
            this.toastr.error('Unsupported file type');
            continue;
        }

        const fd = new FormData();
        fd.append(uploadType, file);

        // Upload the file based on its type (image or video)
        this.utilityService[uploadType === 'image' ? 'uploadImage' : 'uploadVideo'](fd, uploadType === 'image' ? 'other' : 'video')
            .subscribe((res: IResponse) => {
                if (res && !res.errors) {
                    this.media.push({
                        url: res.data.url,
                        type: uploadType === 'image' ? 1 : MagicNumbers.two
                    });

                    if (uploadType === 'image') {
                        this.imageCount++;
                    } else if (uploadType === 'video') {
                        this.videoCount++;
                    }
                } else {
                    this.toastr.error('File upload failed');
                }
            }, error => {
                this.toastr.error('File upload failed');
            });
    }

    event.target.value = ''; // Clear the file input
}

    checkPendingReq(memberID){
      this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
        if (res && !res.errors) {
           this.commonService.guestRequestSharing.next(res.data);
        }
        });
    }

        /**
     * To open the vide in new tab
     * @param video is response of list
     */
    showVideo(video: string) {
      window.open(video, '_blank');
    }

}
