import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IResponse } from '../../../../app/common/interfaces/response';
import { MagicNumbers } from '../../../../app/common/component/app-magic-numbers';
import { UtilityService } from '../../../../app/common/services/utility.service';
import { MemoryCodeRequiredComponent } from '../memory-code-required/memory-code-required.component';
import { SigninComponent } from '../signin/signin.component';
import { CommonService } from '../../../common/services/common.service';
import { Stripe, StripeElements } from '@stripe/stripe-js';

@Component({
  selector: 'app-add-co-owner-details',
  templateUrl: './add-co-owner-details.component.html',
  styleUrls: ['./add-co-owner-details.component.scss']
})
export class AddCoOwnerDetailsComponent implements OnInit,OnDestroy{

  stripe: Stripe;
  stripeElements: StripeElements;
  public detailsForm: FormGroup;
  public paymentForm: FormGroup;
  public today = new Date();
  public isDetailShow = true;
  public selectedCity = false;
  public selectedState = false;
  public states;
  public cities;
  public basicDetails;
  public paymentCreatedDetails;
  public bodyElement;
  public submitted = false;
  public disablePaymentBtn = false;
  public cardNumber;
  public cardExpiry;
  public cardCvv;
  public hideCardNumber;
  public cardNumberMSG;
  public expiryMSG;
  public hideCardExpiry;
  public cvvMSG;
  public hideCardCvv;
  public showCountryCode = false;
  public showNav = false;
  public loader: NgxSpinnerService;
  public datePipe: DatePipe;
  public toastr: ToastrService;
  public payAmount;
  public profilePic: string;
  elementStyles: {
    base: {
      '::placeholder': {
        color: '#cfd7df',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },

    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };planType: any;
  tokenResponse: any;
;
  elementClasses: {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  };

  constructor(public ref: ChangeDetectorRef, public modalService: BsModalService, public commonStateService: CommonService,
    public router: Router, public formBuilder: FormBuilder, public utilityService: UtilityService, public inj: Injector) {
    this.loader = inj.get<NgxSpinnerService>(NgxSpinnerService);
    this.datePipe = inj.get<DatePipe>(DatePipe);
    this.toastr = inj.get<ToastrService>(ToastrService);

    this.detailsForm = this.formBuilder.group({
      profileImage: ['', [Validators.required]],
      fullName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty)]],
      contactNumber: ['', [Validators.required, Validators.minLength(MagicNumbers.ten), Validators.maxLength(MagicNumbers.ten)]],
      emailId: ['', [Validators.required,Validators.email,Validators.maxLength(MagicNumbers.fifty)]],
      deliverAddress: ['', [Validators.required, Validators.maxLength(MagicNumbers.oneHundred)]],
      zipCode: ['', [Validators.required, Validators.maxLength(MagicNumbers.ten), Validators.minLength(MagicNumbers.five)]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      plan_type : [''],
      deliver_code_type : [''],
      serial_number : ['']
    });

    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, MagicNumbers.oneHundred);
  }

  ngOnInit(): void {
    this.getStates();
    // Add class on body
    this.bodyElement = document.body;
    this.bodyElement.classList.add('remove-padding');

    const userData = localStorage.getItem('userData');
    const updatePlanMemberData = JSON.parse(userData);
    this.payAmount = updatePlanMemberData.basic_plan_amount;
  }

  get fullName() { return this.detailsForm.get('fullName');}
  get profileImage() { return this.detailsForm.get('profileImage'); }
  get contactNumber() { return this.detailsForm.get('contactNumber'); }
  get emailId() { return this.detailsForm.get('emailId'); }
  get deliverAddress() { return this.detailsForm.get('deliverAddress'); }
  get zipCode() { return this.detailsForm.get('zipCode'); }
  get state() { return this.detailsForm.get('state'); }
  get city() { return this.detailsForm.get('city'); }


  /**
   * API Call for states List
  */
  getStates() {
    this.utilityService.getStates().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
        setTimeout(() => {
          this.detailsForm.controls.state.setValue(null);
          this.selectedState = false;
        }, MagicNumbers.oneHundred);
      }
    });
  }
  /**
   * To open the signin modal
  */
  openSignIn() {
    this.modalService.show(SigninComponent, { class: 'signin-modal' });
  }
  /**
   * To open the qr memory code modal
  */
  openMemoryCode() {
    this.modalService.show(MemoryCodeRequiredComponent, { class: 'signin-modal' });
  }
  /**
     * API Call for cities list based on state id
     * @param event is required
    */
  getCity(event) {
    this.cities = [];
    this.utilityService.getCities(event?.target?.value).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        setTimeout(() => {
          this.detailsForm.controls.city.setValue(null);
          this.selectedCity = false;
        }, MagicNumbers.oneHundred);
        this.cities = res.data;
      }
    });
  }

  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success('Profile picture uploaded successfully.');
          this.profilePic = res.data.url;
          this.basicDetails.profileImage.value = this.profilePic;
        }
      });
    }
  }

  validationCheck(){
    return (this.paymentForm.valid && this.cardNumber._complete && this.cardExpiry._complete && this.cardCvv._complete);
   }

   goToAboutUs() {
    this.showNav = false;
    this.router.navigate(['/about-us']);
  }

  submitDetails() {
    if (this.detailsForm.valid) {
      this.isDetailShow = false;
      this.basicDetails = this.detailsForm.value;
      this.callAPIForCoOwner();
    } else {
      this.detailsForm.markAllAsTouched();
    }
  }

  callAPIForCoOwner() {
    if(this.profilePic){
      this.detailsForm.value.profileImage = this.profilePic;
    }
    const data = {
      profile_picture: this.detailsForm.value.profileImage,
      full_name: this.basicDetails.fullName,
      email: this.basicDetails.emailId,
      contact_number: this.basicDetails.contactNumber,
      address: this.basicDetails.deliverAddress,
      zip_code: this.basicDetails.zipCode,
      state_id: this.basicDetails.state,
      city_id: this.basicDetails.city
    };
    this.utilityService.addCoOwner(data).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.isDetailShow = true;
        const userData = localStorage.getItem('userData');
          const updatePicture = JSON.parse(userData);
          updatePicture.user.profile_picture = this.profilePic;
          this.commonStateService.userData.next(updatePicture);
          localStorage.setItem('userData', JSON.stringify(updatePicture));
          if(updatePicture.members){
            this.router.navigate(['/members-list']);
          } else{
            this.router.navigate(['/home']);
          }
      }
    });
  }

  ngOnDestroy() {
    this.bodyElement.classList.remove('remove-padding');
  }
}
